.AuthPage {
	display: flex;
	justify-content: center;
	padding-top: 30vh;
	--content-width: 400px;
}

.AuthPage__input {
	display: block;
	margin-bottom: 1em;
}

@media (max-width: 500px) {
	.AuthPage {
		--content-width: auto;
	}
}

.AuthPage__message {
	margin-top: 1em;
	min-height: 2em;
	font-size: 20px;
	text-align: center;
}

.AuthPage__info {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	row-gap: 15px;
}
.AuthPage__info p {
	text-align: center;
	font-size: 16px;
}

.AuthPage__error {
	margin-top: 1em;
}

.AuthPage__legalNotice {
	margin-top: 1em;
	margin-bottom: 1em;
	min-height: 2em;
	font-size: 14px;
}

.AuthPage__actions {
	display: flex;
	align-items: center;
	column-gap: 15px;
}
.AuthPage__swapMessage {
	font-size: 15px;
}

.AuthPage__forgotpassword {
	margin-bottom: 15px;
}
