import { useEffect, useRef, useState } from 'react';
import { EventEmitter } from 'fbemitter';

export const makeUpdatable = (target) => {
	if (target.onUpdate) {
		throw new Error(`Target already updatable (class ${target.constructor.name})`);
	}
	let _emitter = new EventEmitter();
	// Used in useUpdater hooks to receive state updates
	target.onUpdate = (handler) => {
		const subscriber = _emitter.addListener('update', handler);
		return () => {
			subscriber.remove();
		};
	};
	target._triggerChange = () => {
		_emitter.emit('update');
	};
};

export const useUpdater = (input) => {
	// The input value should be a stable value,
	// like the singleton instance of a class,
	// and we subscribe to internal changes to it
	// and trigger re-renders when these happen.
	const [, setRandom] = useState(Math.random());
	// const ref = useRef(isPromise ? null : input);
	const ref = useRef(input);
	useEffect(() => {
		// TODO: HACK: This is needed to trigger a state update
		// as the session becomes ready after the initial render
		// but before we have time to bind onUpdate.
		setRandom(Math.random());
		// Return unbinder
		return ref.current.onUpdate(() => {
			setRandom(Math.random());
		});
	}, [input]);
	return ref.current;
};
