export function getSlotRowStartIndex(slot) {
	if (slot.spanAll) {
		return 0;
	}
	return slot.rowIndex;
}
export function getSlotRowEndIndex(slot, session) {
	if (slot.spanAll) {
		return session.rowCount;
	}
	return slot.rowIndex;
}
export function getSlotColorIndex(slot) {
	if (slot.spanAll) {
		return 'all';
	}
	return slot.rowIndex;
}
