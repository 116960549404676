.SessionDetails {
	font-size: 14px;
	height: 90vh;
	overflow: hidden;
}

.SessionDetails__header__cta {
	display: flex;
	flex-direction: row;
}

.SessionDetails__general {
	height: 100%;
	display: flex;
	flex-direction: column;
	position: relative;
}
.SessionDetails__general__content {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	height: 72px;
	max-height: 72px;
	border-bottom: 1px solid #f2f2f2;
}

.SessionDetails__general__content_title {
	width: 25%;
}
.SessionDetails__general__content_title span {
	margin-inline: 11px;
	font-size: 14px;
	color: #808080;
	font-weight: 500;
}
.SessionDetails__general__content_desc {
	width: 75%;
	display: flex;
	flex-direction: row;
	color: #4d4d4d;
	font-size: 14px;
	font-weight: 600;
}
.SessionDetails__general__content_title,
.SessionDetails__general__content_desc {
	min-height: 65px;
	display: flex;
	align-items: center;
}
.SessionDetails__general__footer {
	margin-top: 2px;
	width: 100%;
}

.SessionDetails__agenda {
	margin-top: 15px;
	box-sizing: border-box;
	border: 1px solid #f2f2f2;
	flex-grow: 1;
	border-radius: 10px;
	overflow: auto;
}
.SessionDetails__agenda__container {
	padding-block: 24px;
}
.SessionDetails__agenda__container h2 {
	text-align: start;
	padding-inline: 24px;
	font-weight: 600;
	font-size: 16px;
	color: #4d4d4d;
}
.SessionDetails__agenda__container p {
	margin-bottom: 8px;
	font-weight: 500;
	font-size: 12px;
	color: #808080;
}
.SessionDetais__agenda__content {
}
.SessionDetails__agenda__content__section {
	padding: 24px;
	position: relative;
}
.SessionDetails__agenda__content__section__border {
	border-bottom: 1px solid #f2f2f2;
	width: 90%;
	position: absolute;
	bottom: 0;
}
.SessionDetails__agenda__content__section__time_header {
	display: flex;
	flex-direction: row;
}
.SessionDetails__agenda__content__section__time_header div {
	display: inline-flex;
	align-items: center;
}
.SessionDetails__agenda__content__section__time_header__duration {
	background-color: #f8f8f8;
	width: 60px;
	height: 32px;
	justify-content: center;
	padding: 0px 5px;
	margin-right: 8px;
	font-weight: 600;
	font-size: 12px;
	color: #616161;
}
.SessionDetails__agenda__content__section__time_header__guests div {
	box-sizing: border-box;
	height: 28px;
	width: 28px;
	border-radius: 50% !important;
	border: 2px solid white;
	background-color: #f8f8f8;
	font-weight: 500;
	font-size: 12px;
	color: #616161;
	justify-content: center;
}
.SessionDetails__agenda__content__section__subject {
}
.SessionDetails__agenda__content__section__subject h3 {
	font-weight: 600;
	font-size: 14px;
	line-height: 17px;
	color: #4d4d4d;
	margin-bottom: 4px;
}
.SessionDetails__agenda__content__section__subject p {
	font-weight: 500;
	font-size: 12px;
	color: #808080;
}

.SessionDetails__avatar {
	cursor: pointer;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	justify-content: center !important;
	align-items: center;
	padding: 8px;
	width: 32px !important;
	height: 32px !important;
	border-radius: 100px;
	color: white;
}

.SessionDetails__meetingHost {
	margin-left: -14px;
	background-size: cover;
	width: 138px;
	height: 24px;
	background-position: center center;
	background-repeat: no-repeat;
}

.google {
	background-image: url('~/src/assets/icons/google-meeting.svg');
	background-size: contain;
}

.zoom {
	background-image: url('~/src/assets/icons/zoom-meeting.svg');
	background-size: contain;
}
.btn {
	display: flex;
	justify-content: center;
	align-items: center;
	background: #000000;
	color: #fff;
}

.btn:hover {
	background-color: #000;
	color: white;
}

.SessionDetails__next_btn {
	margin-inline: auto;
	margin-top: 34px;
	margin-bottom: 23px;
	width: 200px;
	height: 52px;
	border-radius: 2px;
}
.SessionDetails__join_btn {
	border-radius: 4px;
	box-sizing: border-box;
	margin-left: 10%;
	width: 85px !important;
	max-height: 40px !important;
}
.SessionDetails__join_btn span {
	font-size: 14px;
}
.SessionDetails__join_btn img {
	transform: translate(-5px, 3px);
}
.SessionDetails__join_btn span:hover {
	background-color: #000 !important;
}

.SessionDetails__agenda__goal_objective {
	margin-bottom: 25px !important;
}
.SessionDetails__agenda__objective_title {
	margin-bottom: 10px !important;
}
.SessionDetails__agenda__objective_desc {
	padding-inline: 24px;
}

@media (max-width: 500px) {
	.SessionDetails__join_btn {
		margin-left: 26%;
	}
}
@media (max-width: 400px) {
	.SessionDetails__join_btn {
		margin-left: 40%;
	}
}

@media (max-height: 500px), (max-width: 600px) {
	.SessionDetails__next_btn {
		display: none;
	}
}
