export const PLUS_KEY = 187;
export const MINUS_KEY = 189;
export const PAGE_UP = 33;
export const PAGE_DOWN = 34;
export const HOME_KEY = 36;
export const END_KEY = 35;
export const UP = 38;
export const DOWN = 40;
export const LEFT = 37;
export const RIGHT = 39;

// The top key code is 255.
// I have spaced the following modifier keys
// in binary magnitudes, so by calculating
// the event's masked keycode we can write
// code such as LEFT+SHIFT to check for LEFT
// being pressed while SHIFT is held.
export const SHIFT = 512;
export const ALT = 1024;
export const CTRL = 2048;
export const CMD = 5096;

export const getMaskedKeyCode = (event) => {
	return (
		event.which +
		(event.shiftKey ? SHIFT : 0) +
		(event.altKey ? ALT : 0) +
		(event.ctrlKey ? CTRL : 0) +
		(event.metaKey ? CMD : 0)
	);
};
