.TimelineHoverSlot {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}

.TimelineHoverSlot__slot {
	border-radius: var(--slot-radius);
	border: var(--slot-border-width) solid transparent;
	box-sizing: border-box;
	background-image: url('~/src/assets/icons/slot-add.svg');
	background-size: 26px auto;
	background-position: center center;
	background-repeat: no-repeat;
}
