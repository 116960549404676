import React from 'react';
import { motion } from 'framer-motion';

import { DEFAULT_SECTION_DURATION } from '~/src/data/constants';
import { useSessionPrivileges } from '~/src/data/sessions';
import { useLivePlayState } from '~/src/data/playState';
import { useLayout } from '~/src/utils/useLayout';

import { SectionHeader } from './SectionHeader';
import { SectionGrid } from './SectionGrid';
import { SessionSlot } from './SessionSlot';
import { TimelinePlayhead } from '~/src/lib/Session/TimelinePlayhead';
import { TimelineHoverSlot } from '~/src/lib/Session/TimelineHoverSlot';

import * as css from './SessionSection.css';

export const SessionSection = ({ session, section, selectedSlotId }) => {
	const layout = useLayout();
	// TODO: Use slower play state updates?
	const playState = useLivePlayState(session);
	const privileges = useSessionPrivileges(session);
	const selfIsActive = playState.isActiveSection(section);
	const handleHoverSlotClick = (time, rowIndex, duration) => {
		session.createSlot(section.id, {
			rowIndex: rowIndex,
			// Ensure we don't create any slots outside of the Section
			start: Math.min(Math.max(0, time), section.duration - duration),
			duration,
		});
		playState.setSession(session);
	};

	return (
		<motion.article
			className={css.Section}
			animate={layout.getSectionStyle(section.duration)}
			transition={{ bounce: 0 }}
			initial={false}
		>
			<SectionHeader section={section} session={session} />
			<div className={css.Section__main} style={layout.main}>
				<SectionGrid section={section} />
				{privileges.canEdit && (
					<TimelineHoverSlot
						session={session}
						section={section}
						onClick={handleHoverSlotClick}
					/>
				)}
				<div className={css.Section__slots}>
					{section.slots.map((slot) => (
						<SessionSlot
							key={slot.id}
							slot={slot}
							section={section}
							session={session}
							sectionIsActive={selfIsActive}
							isSelected={selectedSlotId === slot.id}
							canEdit={privileges.canEdit}
						/>
					))}
				</div>
				{selfIsActive && <TimelinePlayhead session={session} />}
			</div>
		</motion.article>
	);
};

export const BlankSection = ({ onClick }) => {
	const layout = useLayout();
	return (
		<motion.article
			className={css.BlankSection}
			animate={layout.getSectionStyle(DEFAULT_SECTION_DURATION)}
			transition={{ bounce: 0 }}
			initial={false}
		>
			<div style={layout.header}></div>
			<div className={css.BlankSection__main} style={layout.main} onClick={onClick}>
				<div className={css.BlankSection__addIcon}></div>
				<h2 className={css.BlankSection__prompt}>Click to add section</h2>
			</div>
		</motion.article>
	);
};
