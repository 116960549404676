import React, { Fragment, useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import classnames from 'classnames';

import { reportError } from '~/src/utils/errors';
import { ErrorBox } from '~/src/lib/ErrorBox';
import { track } from '~/src/utils/analytics';

import * as css from './Buttons.css';

/*
To track the click of a button, pass in an array, the first entry being
the event name, and the section optional entry an object with parameters:
track={['Event without properties']}
track={['Event with properties', { prop: value }]}
*/
export const BaseButton = ({
	children,
	onClick,
	to,
	target,
	className,
	track: trackData,
	type = 'button',
	...props
}) => {
	const [isLoading, setIsLoading] = useState(false);
	const [message, setMessage] = useState(null);
	const [error, setError] = useState(null);
	const ButtonComponent = to ? RouterLink : 'button';
	const handleClick = async (event) => {
		event.stopPropagation();
		if (trackData) {
			track(...(trackData instanceof Array ? trackData : [trackData]));
		}
		if (!onClick) {
			return;
		}
		try {
			setIsLoading(true);
			const result = await onClick(event);
			if (typeof result?.message === 'string') {
				setMessage(result.message);
			}
		} catch (error) {
			reportError(error);
			setError(error);
		} finally {
			setIsLoading(false);
		}
	};
	// Error timeout
	useEffect(() => {
		if (!error) {
			return;
		}
		const timeout = setTimeout(() => {
			setError(null);
		}, 1500);
		return () => clearTimeout(timeout);
	}, [error]);
	// Message timeout
	useEffect(() => {
		if (!message) {
			return;
		}
		const timeout = setTimeout(() => {
			setMessage(null);
		}, 1500);
		return () => clearTimeout(timeout);
	}, [message]);
	return (
		<Fragment>
			<ButtonComponent
				className={classnames(
					css.BaseButton,
					isLoading && css['BaseButton--isLoading'],
					className,
				)}
				onClick={handleClick}
				to={to}
				target={target}
				type={type}
				{...props}
			>
				<span className={css.BaseButton__children}>{children}</span>
				{message && <span className={css.BaseButton__message}>{message}</span>}
			</ButtonComponent>
			{error && (
				<div className={css.BaseButtonError}>
					<ErrorBox error={error} />
				</div>
			)}
		</Fragment>
	);
};

export const Button = ({
	className,
	variant = 'default',
	shape = 'round',
	icon = null,
	...props
}) => (
	<BaseButton
		className={classnames(
			css.Button,
			css[`Button--${variant}`],
			css[`Button--${shape}`],
			icon && css['Button--has-icon'],
			icon && css[`icon--${icon}`],
			className,
		)}
		{...props}
	/>
);

export const Link = ({ className, variant = 'default', ...props }) => (
	<BaseButton
		className={classnames(css.Link, css[`Link--${variant}`], className)}
		{...props}
	/>
);

export const IconButton = ({ onClick, icon, className, children, ...props }) => (
	<BaseButton
		className={classnames(
			className,
			css.IconButton,
			css[`icon--${icon}`],
			css[`icon-bg--${icon}`],
		)}
		onClick={onClick}
		{...props}
	>
		{children}
	</BaseButton>
);

export const RoundButton = ({
	icon = null,
	size = 'auto',
	className,
	children,
	...props
}) => (
	<BaseButton
		className={classnames(
			className,
			css.RoundButton,
			css[`RoundButton--size-${size}`],
			css[`icon-bg--${icon}`],
			children && css['RoundButton--has-children'],
		)}
		{...props}
	>
		{children}
	</BaseButton>
);

export const ActionLink = ({ icon, className, children, ...props }) => (
	<BaseButton
		className={classnames(
			className,
			css.ActionLink,
			icon && css['ActionLink--has-icon'],
			css[`icon--${icon}`],
		)}
		{...props}
	>
		{children}
	</BaseButton>
);
