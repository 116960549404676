import { useRef, createContext, useContext, useState, useEffect } from 'react';
import debounce from 'lodash/debounce';
import defaultTo from 'lodash/defaultTo';

import { useZoom } from '~/src/utils/useZoom';
import { useSession } from '~/src/data/sessions';
import { limit } from '~/src/utils/limit';
import { getSlotRowStartIndex, getSlotRowEndIndex } from '~/src/utils/slot';

export const LayoutSizeContext = createContext();

export const useLayoutSizeProvider = () => {
	const ref = useRef();
	const [size, setSize] = useState({ height: 0, width: 0 });
	useEffect(() => {
		const updateSize = () => {
			if (ref.current) {
				setSize({
					height: ref.current.offsetHeight,
					width: ref.current.offsetWidth,
				});
			}
		};
		updateSize();
		const handleResize = debounce(updateSize, 100, { leading: false });
		window.addEventListener('resize', handleResize, { passive: true });
		return () => {
			window.removeEventListener('resize', handleResize, { passive: true });
		};
	}, [ref]);
	return [ref, size];
};

export const useLayoutSize = () => useContext(LayoutSizeContext);

export const useLayout = () => {
	const timelineSize = useLayoutSize();
	const session = useSession();
	const zoom = useZoom();
	const isShort = timelineSize.height < 500;
	const rowInfo = {
		gap: isShort ? 0 : 20,
		count: Math.max(1, session.rowCount),
	};
	const gridLegendInfo = {
		height: 15,
	};
	const mainInfo = {
		spaceTop: 20 + gridLegendInfo.height,
		spaceBottom: 30,
	};
	const headerStyle = {
		height: isShort ? 75 : 160,
	};
	const mainStyle = {
		height: timelineSize.height - headerStyle.height,
	};
	const availableHeight = mainStyle.height - mainInfo.spaceTop - mainInfo.spaceBottom;
	const rowStyle = {
		height: (availableHeight - rowInfo.count * rowInfo.gap) / rowInfo.count,
	};
	return {
		header: headerStyle,
		main: mainStyle,
		getX(time) {
			return time * zoom.x;
		},
		getRowY(rowIndex, spaceTop = mainInfo.spaceTop) {
			return rowIndex * rowStyle.height + rowInfo.gap * rowIndex + spaceTop;
		},
		getRowHeight() {
			return rowStyle.height;
		},
		getSlotHeight(rowStart, rowEnd) {
			const rowSpan = Math.max(1, rowEnd - rowStart);
			return rowSpan * rowStyle.height + rowInfo.gap * (rowSpan - 1);
		},
		getSectionStyle(duration) {
			return { width: this.getX(duration) };
		},
		getSectionSeparatorStyle() {
			return {
				width: 140,
				height: mainStyle.height,
				marginTop: headerStyle.height,
				marginLeft: 30,
				marginRight: 30,
			};
		},
		getRowStyle(rowIndex, spaceTop) {
			return {
				top: this.getRowY(rowIndex, spaceTop),
				height: this.getRowHeight(),
			};
		},
		getBottomRowStyle() {
			return {
				bottom: 10,
				height: mainInfo.spaceBottom,
			};
		},
		getSlotStyle(slot, liveSlot) {
			const isEditing = liveSlot && liveSlot !== slot;
			return {
				top: this.getRowY(
					defaultTo(liveSlot && liveSlot.rowStartIndex, getSlotRowStartIndex(slot)),
				),
				height: this.getSlotHeight(
					defaultTo(liveSlot && liveSlot.rowStartIndex, getSlotRowStartIndex(slot)),
					defaultTo(liveSlot && liveSlot.rowEndIndex, getSlotRowEndIndex(slot, session)),
				),
				left: this.getX(defaultTo(liveSlot && liveSlot.start, slot.start)),
				width: this.getX(defaultTo(liveSlot && liveSlot.duration, slot.duration)),
				// If editing, put on top, otherwise, put "everyone speaking" blocks at bottom
				zIndex: isEditing ? 2 : slot.spanAll ? 0 : 1,
			};
		},
		getSlotHandleStyle(handleType, slot) {
			const edgeHandleWidth = limit(this.getX(slot.duration) / 3, 20, 50);
			const edgeHandleOffset = 20;
			if (handleType === 'start') {
				return {
					left: 0,
					width: edgeHandleWidth,
					marginLeft: -edgeHandleOffset,
				};
			} else if (handleType === 'end') {
				return {
					right: 0,
					width: edgeHandleWidth,
					marginRight: -edgeHandleOffset,
				};
			} else if (handleType === 'middle') {
				return {
					left: edgeHandleWidth - edgeHandleOffset,
					width: this.getX(slot.duration) - (edgeHandleWidth - edgeHandleOffset) * 2,
				};
			} else {
				throw new Error(`Invalid handle type "${handleType}"`);
			}
		},
		getGridLegendStyle(legendIndex, legendResolution) {
			return {
				left: this.getX(legendIndex * legendResolution),
				height: gridLegendInfo.height,
			};
		},
		getPlayheadStyle(time) {
			return {
				transform: `translateX(${this.getX(time)}px)`,
				zIndex: 3,
			};
		},
		getPlayheadStaticStyle() {
			return {
				height: mainStyle.height - gridLegendInfo.height,
				top: gridLegendInfo.height,
			};
		},
		getRowIndexFromY(inputY) {
			if (inputY < mainInfo.spaceTop) {
				return null;
			}
			const rowIndexIsh = (inputY - mainInfo.spaceTop) / (rowStyle.height + rowInfo.gap);
			return Math.floor(rowIndexIsh);
		},
		getIndexDeltaFromOffset(offsetY) {
			return Math.ceil((offsetY - rowStyle.height / 2) / rowStyle.height);
		},
		getTimeFromX(xPos) {
			return xPos / zoom.x;
		},
	};
};
