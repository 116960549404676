import { motion } from 'framer-motion';
import React, { Fragment, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { useUser, useUserForAuth } from '~/src/data/user';
import { Button } from '~/src/lib/Buttons';
import { ErrorBox } from '~/src/lib/ErrorBox';
import { Label } from '~/src/lib/Form/Label';
import { TextInput } from '~/src/lib/Form/TextInput';
import { Content } from '~/src/lib/Layout';

import * as css from './BetaSignupPage.css';

const HIDE_SHOW = { hide: { opacity: 0 }, show: { opacity: 1 } };

export const BetaSignupPage = () => {
	const { user } = useUser();

	if (user) {
		return <Redirect to="/home" />;
	}
	return <LandingPageContent />;
};

const LandingPageContent = () => {
	const history = useHistory();
 	const { message, submitSignup, error } = useUserForAuth();
 	const [email, setEmail] = useState('');
 	const [name, setName] = useState('');
	const [company, setCompany] = useState('');
	const [companySize, setCompanySize] = useState('');
	const [phone, setPhone] = useState('');
	const [isLoading, setIsLoading] = useState(false);

	const handleSignupSubmit = async (event) => {
		event.preventDefault();
		setIsLoading(true);
		const saved = await submitSignup({ 
			email, 
			name, 
			phone,
			company, 
			companySize,
			isBeta: true,
			type: 'BETA'  
		});
		setIsLoading(false);
		if (saved) {
			history.push('/b-signup-success');
		}
	};

	return (
		<Fragment>
			<div className={css.LandingPage}>
				<div className={css.LandingPage__container}>
					<motion.div
						className={css.LandingPage__header}
						variants={HIDE_SHOW}
						initial="hide"
						animate="show"
						transition={{
							staggerChildren: 0.1,
						}}
					>
						<motion.h2 className={css.LandingPage__logo}>this&that</motion.h2>
					</motion.div>

					<motion.div
						variants={HIDE_SHOW}
						initial="hide"
						animate="show"
						transition={{
							staggerChildren: 0.1,
						}}
						className={css.LandingPage__section}
					>
						<div className={css.LandingPage__left_content}>
							<h1> Our Beta is coming real soon...</h1>
							<div className={css.LandingPage__description}>
								<h2>
									Sign up below to get first access and we'll notify you when our beta is live.
									<br />
									We'd love to have you.
								</h2>
							</div>
						</div>
						<div className={css.LandingPage__right_content}>
							{' '}
							<Content>
								{isLoading ? (
									'Processing...'
								) : message ? (
									<div className={css.AuthPage__message}>{message}</div>
								) : (
									<form onSubmit={handleSignupSubmit}>
										<label className={css.AuthPage__input}>
											<Label>Name*</Label>
											<TextInput
												type="text"
												name="name"
												placeholder="Your Name"
												onChange={(event) => setName(event.target.value)}
											/>{' '}
										</label>
										<br/>
										<label className={css.AuthPage__input}>
											<Label>Email*</Label>
											<TextInput
												type="text"
												name="email"
												placeholder="your@email.com"
												onChange={(event) => setEmail(event.target.value)}
											/>
										</label>
										<br />
										<label className={css.AuthPage__input}>
											<Label>Phone (optional)</Label>
											<TextInput
												type="text"
												name="phone"
												placeholder="Your Phone"
												onChange={(event) => setPhone(event.target.value)}
											/>
										</label>
										<br/>
										<label className={css.AuthPage__input}>
											<Label>Name of company*</Label>
											<TextInput
												type="text"
												name="name"
												placeholder="Your Company"
												onChange={(event) => setCompany(event.target.value)}
											/>
										</label>
										<br/>
										<label className={css.AuthPage__input}>
											<Label>Number of employees</Label>
											<TextInput
												type="number"
												name="name"
												placeholder="300"
												onChange={(event) => setCompanySize(event.target.value)}
											/>
										</label>
										<br />
										{error && (
											<div className={css.AuthPage__error}>
												<ErrorBox error={error} />
											</div>
										)}
										<br />
										<div className={css.AuthPage__actions}>
											<Button type="submit" track={['Submit Signup', { where: 'Signup page' }]}>
												Sign up
											</Button>
										</div>
										<br />
									</form>
								)}
							</Content>
						</div>
					</motion.div>
				</div>
			</div>
		</Fragment>
	);
};
