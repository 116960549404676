import flatten from 'lodash/flatten';
import sum from 'lodash/sum';
import pull from 'lodash/pull';

import { generateId } from '~/src/utils/generateId';
import { makeUpdatable } from '~/src/utils/useUpdater';
import { addToUndoStack, goBack, goForward } from '~/src/utils/undoStack';

import { put } from '~/src/data/api';
import { getPublicSessionLink } from '../data/sessions';

let lastUpdatedSlot = null;

// TODO: Keep a version number or hash
// which changes when the data changes,
// and which can be used as the random
// identifier in the useSession hook.
export class Session {
	constructor(id, initialData = {}) {
		if (!id) {
			throw new Error(`Cannot create item without ID (${id})`);
		}
		this._id = id;
		this._data = initialData;
		makeUpdatable(this);
		this._handleApiSuccess = this._handleApiSuccess.bind(this);
		this._handleApiError = this._handleApiError.bind(this);
		this._isSavingPaused = false;
		this._isSaving = false;
	}
	set id(id) {
		this._id = id;
	}
	get id() {
		return this._id;
	}
	get isSession() {
		return true;
	}
	get title() {
		return this._data.title;
	}
	get description() {
		return this._data.description;
	}
	get duration() {
		return sum(this.sections.map((section) => section.duration));
	}
	get isSaving() {
		return this._isSaving;
	}
	get isPublic() {
		return this._data.isPublic;
	}
	get archivedAt() {
		return this._data.archivedAt;
	}
	get rows() {
		return this._data.rows;
	}
	get sections() {
		return this._data.sections;
	}
	get slots() {
		return flatten(this.sections.map((section) => section.slots));
	}
	get url() {
		return `/sessions/${this.id}`;
	}
	get rowCount() {
		return this.rows.length;
	}
	get sectionCount() {
		return this.sections.length;
	}
	get participantCount() {
		return this._data.participantCount;
	}
	get gapBetweenSections() {
		return this._data.gapBetweenSections || 10;
	}
	copyInviteLink = async () => {
		await navigator.clipboard.writeText(getPublicSessionLink(this.id));
		return {
			message: 'Copied!',
		};
	};
	update(newData, shouldSave = true) {
		Object.assign(this._data, newData);
		if (shouldSave) {
			this.save();
		}
		return this;
	}
	getRow(rowId) {
		return this.rows.find((row) => row.id === rowId);
	}
	createRow(initialData = {}) {
		const row = Object.assign({}, initialData, getDefaultRowData(this.rowCount));
		this.rows.push(row);
		this.save();
		return row;
	}
	updateRow(rowId, newData) {
		// Find the row with id = rowId
		const row = this.getRow(rowId);
		if (!row) {
			throw new Error(`Cannot update missing row (ID: ${rowId})`);
		}
		// Amend its data
		Object.assign(row, newData);
		this.save();
		return row;
	}
	removeRow(rowToBeRemoved) {
		this.save(() => {
			// Remove slots from row
			const slotsToBeRemoved = this.slots.filter((slot) => {
				return slot.rowIndex === rowToBeRemoved.index;
			});
			// Decrease index of slots on higher rows
			const slotsToBeShiftedDown = this.slots.filter(
				(slot) => slot.rowIndex > rowToBeRemoved.index,
			);
			// Decrease index of rows with index higher than removed row
			const rowsToBeShiftedDown = this.rows.filter(
				(row) => row.index > rowToBeRemoved.index,
			);
			for (let slot of slotsToBeRemoved) {
				this.removeSlot(slot);
			}
			for (let slot of slotsToBeShiftedDown) {
				this.updateSlot(slot.id, { rowIndex: slot.rowIndex - 1 });
			}
			for (let row of rowsToBeShiftedDown) {
				Object.assign(row, { index: row.index - 1 });
			}
			pull(this.rows, rowToBeRemoved);
		});
	}
	getSection(id) {
		return this.sections.find((section) => section.id === id);
	}
	createSection(initialData) {
		const section = Object.assign({}, getDefaultSectionData(), initialData);
		this.sections.push(section);
		this.save();
		return section;
	}
	handleSectionSlotsTimeLength(newData, sectionData) {
		const percentage = (newData.duration * 100) / sectionData.duration;
		let section = this.getSection(sectionData.id);
		let slotsToBeDeleted = [];

		section.slots.map((slot) => {
			slot.duration = Math.ceil((slot.duration * percentage) / 100);
			if (slot.start + slot.duration > newData.duration) {
				slot.duration = slot.duration - (slot.start + slot.duration - newData.duration);
			}
			if (slot.start >= newData.duration) {
				slotsToBeDeleted.push(slot.id);
			}
		});
		section.slots = section.slots.filter((slot) => !slotsToBeDeleted.includes(slot.id));
	}
	updateSection(sectionId, newData) {
		// Find the section with id = sectionId
		const section = this.getSection(sectionId);
		if (!section) {
			throw new Error(`Cannot update missing section (ID: ${sectionId})`);
		}
		// Amend its data
		if (newData.duration < section.duration) {
			this.handleSectionSlotsTimeLength(newData, section);
		}
		Object.assign(section, newData);
		this.save();
		return section;
	}
	duplicateSection(section) {
		const newSessionData = {
			...this.sections[this.sections.indexOf(section)],
			id: generateId(),
		};
		newSessionData.slots = newSessionData.slots.map((slot) =>
			Object.assign({}, slot, { id: generateId() }),
		);

		const newSection = Object.assign({}, getDefaultSectionData(), newSessionData);
		this.sections.forEach((mapSection, index) => {
			if (mapSection.id === section.id) {
				this.sections.splice(index + 1, 0, newSection);
			}
		});

		this.save();
		return section;
	}
	moveSectionPosition(direction, section) {
		const sectionIndex = this.sections.findIndex((element) => element.id === section.id);
		let aux;
		switch (direction) {
			case 'forward':
				if (sectionIndex + 1 === this.sections.length) {
					this.sections.splice(0, 0, section);
					this.sections.pop();
				} else {
					aux = this.sections[sectionIndex + 1];
					this.sections[sectionIndex + 1] = section;
					this.sections[sectionIndex] = aux;
				}
				this.save();
				break;
			case 'backward':
				if (sectionIndex === 0) {
					this.sections.splice(this.sections.length, 0, section);
					this.sections.shift();
				} else {
					aux = this.sections[sectionIndex - 1];
					this.sections[sectionIndex - 1] = section;
					this.sections[sectionIndex] = aux;
				}
				this.save();
				break;
			default:
				return;
		}
	}
	removeSection(section) {
		pull(this.sections, section);
		this.save();
	}
	getSlot(id) {
		// TODO: This is not efficient as we're using nested loops.
		// Store slots flat in the session, each with a "sectionId" instead?
		for (let section of this.sections) {
			const slot = section.slots.find((slot) => slot.id === id);
			if (slot) {
				return slot;
			}
		}
	}
	createSlot(sectionId, initialData) {
		// Produce the slot based on default data and input data
		const slot = Object.assign({}, getDefaultSlotData(), initialData);
		const section = this.getSection(sectionId);
		if (!section) {
			throw new Error(`Cannot add slot to missing section (ID: ${sectionId})`);
		}
		// Add the slot to the right section
		section.slots.push(slot);
		this.save();
		return slot;
	}
	updateSlot(slotId, newData) {
		// Find the slot with id = slotId
		const slot = this.getSlot(slotId);
		if (!slot) {
			throw new Error(`Cannot update missing slot (ID: ${slotId})`);
		}
		// Amend its data
		Object.assign(slot, newData);
		// Keep track of last changed slot
		lastUpdatedSlot = slot;
		this.save();
		return slot;
	}
	removeSlot(removing) {
		// Find the section containing the slot
		for (let section of this.sections) {
			if (section.slots.find((slot) => slot === removing)) {
				// Remove the slot from its slots array
				pull(section.slots, removing);
				break;
			}
		}
		this.save();
	}
	getLastSlotDuration() {
		if (lastUpdatedSlot) {
			return lastUpdatedSlot.duration || 60;
		}
		return 60;
	}
	getIndexOfSection(section) {
		return this.sections.indexOf(section);
	}
	_handleApiSuccess(response) {
		console.log('_handleApiSuccess', response);
		return this;
	}
	_handleApiError(error) {
		console.log('_handleApiError', error);
	}
	async _save({ createUndoEntry = true } = {}) {
		if (this._isSavingPaused) {
			return;
		}
		this.key = Math.random();
		this._isSaving = true;
		this._triggerChange(this);
		const endpoint = `/sessions/${this.id}`;
		await put(endpoint, this._data)
			.then(this._handleApiSuccess)
			.catch(this._handleApiError);
		this._isSaving = false;
		this._triggerChange(this);
		if (createUndoEntry) {
			addToUndoStack(this.toJSON());
		}
		return this;
	}
	save(input) {
		// Some methods, like removeRow, calls a lot of other methods
		// which individually call save. We don't want to trigger lots
		// of saves, and so we allow pausing of saves by passing in a
		// function. This could be extended to automatically restore if
		// an error is thrown, making it work like a transaction.
		if (typeof input === 'function') {
			this._isSavingPaused = true;
			try {
				input();
				this._isSavingPaused = false;
				this._save();
			} catch (error) {
				this._isSavingPaused = false;
				console.warn(error);
			}
		} else {
			this._save(input);
		}
	}
	undo() {
		const restoredData = goBack();
		if (restoredData) {
			const data = JSON.parse(restoredData);
			Object.assign(this._data, data);
			this.save({ createUndoEntry: false });
		}
	}
	redo() {
		const restoredData = goForward();
		if (restoredData) {
			const data = JSON.parse(restoredData);
			Object.assign(this._data, data);
			this.save({ createUndoEntry: false });
		}
	}
	toJSON() {
		return JSON.stringify(this._data);
	}
}

function getDefaultSectionData() {
	return {
		id: generateId(),
		title: 'Blank section',
		description: '',
		duration: 5 * 60,
		slots: [],
	};
}

function getDefaultSlotData(initialData = {}) {
	return {
		id: generateId(),
		rowIndex: initialData.rowIndex,
		spanAll: initialData.spanAll || false,
		start: 0,
		duration: 60,
		text: '',
	};
}

function getDefaultRowData(rowCount) {
	return {
		id: generateId(),
		text: `Speaker ${rowCount}`,
		index: rowCount,
	};
}
