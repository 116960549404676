import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';

import { Main, Content, Side } from '~/src/lib/Layout';
import { Button, Link } from '~/src/lib/Buttons';
import { SessionList } from '~/src/lib/SessionList';
import { ErrorBox } from '~/src/lib/ErrorBox';
import { useSessions } from '~/src/data/sessions';
import { useUser } from '~/src/data/user';
import { OverlayCard } from '~/src/lib/OverlayCard';
import { SessionBuilder } from '~/src/lib/Session/SessionBuilder';
import { Redirect, useHistory } from 'react-router-dom';
import verticalLine from '../assets/media/line.png';

import * as css from './HomePage.css';

const HIDE_SHOW = { hide: { opacity: 0 }, show: { opacity: 1 } };
const USER_GUIDE_URL =
	'https://unequaled-guava-f9f.notion.site/User-Guide-f43befb2eed449b09f3632f66b290804';
export const HomePage = () => {
	const sessions = useSessions();
	const { user, error } = useUser();
	const history = useHistory();
	const [createNewSession, setCreateNewSession] = useState(false);

	const handleClickCreateNewSession = useCallback(() => {
		// setCreateNewSession((prevState) => !prevState);
		history.replace('/sessions/new');
	}, []);
	const CtaBtnStyle = {
		background: '#5D4ACD',
		color: 'white',
		height: '64px',
		width: '276px',
		borderRadius: '50px',
		fontWeight: '400',
		display: 'flex',
		justifyContent: 'center',
		alignSelf: 'center',
	};

	if (user) {
		return (
			<Main className={css.HomePage} mainContentCustomStyle={{ 'max-width': '100%' }}>
				<Content>
					<motion.div
						className={css.HomePage__intro}
						variants={HIDE_SHOW}
						initial="hide"
						animate="show"
						transition={{
							staggerChildren: 0.1,
						}}
					>
						<motion.h1 className={css.HomePage__introTitle} variants={HIDE_SHOW}>
							Welcome to our alpha tool
						</motion.h1>
						<motion.div className={css.HomePage__introText} variants={HIDE_SHOW}>
							<p style={{ marginBottom: '1em' }}>
								Here we invite you to plan meetings with the help our
								<br /> timeline technology.
							</p>
							<p className={css.HomePage__introText_strong_p}>
								Whether it’s making your meetings more accountable and transparent, or
								exploring the limits of what a meeting can be... we encourage you to
								experiement with the building blocks of dialogue.
							</p>
							<p>
								For help using our tool, use our Customer chat any time, or take a look at
								our video tutorials (coming soon). In your templates you'll also find a
								special demo template. Have a play around!
							</p>
						</motion.div>
						<motion.div className={css.HomePage__introCta} variants={HIDE_SHOW}>
							<Button
								shape="large"
								variant="primary"
								style={CtaBtnStyle}
								onClick={handleClickCreateNewSession}
								track={['Create Session', { where: 'Homepage' }]}
							>
								Create new meeting
							</Button>
						</motion.div>
					</motion.div>
					{createNewSession ? (
						<AnimatePresence>
							<OverlayCard onBackgroundClick={handleClickCreateNewSession}>
								<SessionBuilder onDone={handleClickCreateNewSession} />
							</OverlayCard>
						</AnimatePresence>
					) : null}
				</Content>
				<div className={css.HomePage__verticalLine}>
					<img src={verticalLine} />
				</div>
				{error ? (
					<Side>
						<ErrorBox error={error} />
					</Side>
				) : (
					<Side>{user ? <SessionsSidebar sessions={sessions} /> : null}</Side>
				)}
			</Main>
		);
	}

	if (!user) {
		return <Redirect to="/" />;
	}
};

const SessionsSidebar = ({ sessions }) => {
	const hasSessions = !!(sessions && sessions.data && sessions.data.length);
	const { user } = useUser();

	const templates = useMemo(
		() => sessions.data.filter((session) => session.isDemo),
		[sessions],
	);
	const personalSessions = useMemo(
		() => sessions.data.filter((session) => !session.isDemo).splice(0, 5),
		[sessions],
	);

	return (
		<Fragment>
			{hasSessions && (
				<motion.hr
					variants={HIDE_SHOW}
					initial="hide"
					animate="show"
					transition={{ delay: 0.2 }}
				/>
			)}
			<motion.div
				className={css.HomePage__sessions}
				variants={HIDE_SHOW}
				initial="hide"
				animate="show"
				transition={{ delay: 0.25 }}
			>
				{user && user.role === 'ADMIN' && (
					<Fragment className={css.HomePage__adminActions}>
						<motion.div className={css.HomePage__adminActions}>
							<Button to="/admin/active">Active Users</Button>
							<Button to="/admin/pending">Pending Users</Button>
						</motion.div>
					</Fragment>
				)}
				{hasSessions && (
					<Fragment>
						{!sessions.data || sessions.error ? (
							sessions.error ? (
								<div>
									<div>Error: {sessions.error.message}</div>
									<Button to="/home">Go home</Button>
								</div>
							) : (
								<div>Loading...</div>
							)
						) : (
							<Fragment>
								{templates.length > 0 && (
									<>
										<h1 className={css.HomePage__sessionsTitle}>Templates</h1>
										<span className={css.HomePage__sessionText}>
											Some useful templates to get you started.
										</span>
										<SessionList variant="preview" sessions={templates} templates />
									</>
								)}
								{personalSessions.length > 0 && (
									<>
										<h1 className={css.HomePage__sessionsTitle}>Your meetings</h1>
										<span className={css.HomePage__sessionText}>
											Recent meetings you create will be stored here. To view all your
											meetings click the
											<br /> “View all meetings” button below.
										</span>
										<SessionList
											style={{ marginLeft: '10px' }}
											variant="preview"
											sessions={personalSessions}
										/>
									</>
								)}
							</Fragment>
						)}
						<Link
							to="/sessions"
							variant="forward"
							className={css.HomePage__sessionsCta}
							track={['See All Sessions', { where: 'Homepage' }]}
						>
							View all my meetings
						</Link>
					</Fragment>
				)}
			</motion.div>
		</Fragment>
	);
};
