.BaseButtonError {
	position: absolute;
}

.BaseButton {
	position: relative;
	display: inline-block;
	box-sizing: border-box;
	font-family: inherit;
	cursor: pointer;
	user-select: none;
	background: transparent;
	padding: 0;
	border: none;
	text-decoration: none;
	vertical-align: text-bottom;
}
.BaseButton__children {
	opacity: 0;
	transition: opacity 150ms ease;
}
.BaseButton__children:only-child {
	opacity: 1;
}
.BaseButton__message {
	position: absolute;
	inset: 0;
	display: flex;
	align-items: center;
	justify-content: center;
}

.BaseButton--isLoading {
	opacity: 0.7;
	animation: loading-fade 2s infinite;
	pointer-events: none;
}
@keyframes loading-fade {
	0%,
	100% {
		opacity: 0.7;
	}
	50% {
		opacity: 0.25;
	}
}
.BaseButton:focus {
	outline: none;
}
.BaseButton[disabled] {
	opacity: 0.3;
	pointer-events: none;
}

.Button {
	box-sizing: border-box;
	text-align: center;
	white-space: nowrap;
}
.Button:focus {
	animation: button-click 150ms ease;
}
@keyframes button-click {
	0% {
		transform: scale(0.98);
	}
}
.Button--round {
	border-radius: 20px;
	min-height: 40px;
	padding: 10px 25px;
	font-weight: var(--semibold);
	font-size: 16px;
	line-height: 20px;
	box-shadow: var(--shadow-clickable);
}
.Button--wide {
	border-radius: 14px;
	min-height: 38px;
	padding: 9px 25px;
	font-weight: var(--semibold);
	font-size: 18px;
	line-height: 20px;
	box-shadow: var(--shadow-clickable);
}
.Button--square {
	border-radius: 4px;
	min-height: 40px;
	padding: 4px 30px;
	font-weight: var(--semibold);
	font-size: 16px;
	line-height: 20px;
	box-shadow: var(--shadow-clickable);
}
.Button--large {
	border-radius: 30px;
	min-height: var(--button-large-height);
	padding: 5px 30px;
	font-weight: var(--semibold);
	font-size: 20px;
	line-height: 20px;
	box-shadow: var(--shadow-clickable);
	display: inline-flex;
	align-items: center;
}

.Button--default {
	background: var(--default-color);
	color: var(--button-text-color);
}
.Button--default:hover {
	background: var(--default-color-hover);
	color: var(--button-text-color-hover);
}
.Button--primary {
	background: var(--primary-color);
	color: var(--primary-color-fg);
}
.Button--primary:hover {
	background: var(--primary-color-hover);
	color: var(--primary-color-fg);
}
.Button--secondary {
	background: var(--secondary-color);
	color: var(--secondary-color-fg);
}
.Button--secondary:hover {
	background: var(--secondary-color-hover);
	color: var(--secondary-color-fg);
}
.Button--danger {
	background: var(--danger-color);
	color: var(--danger-color-fg);
}
.Button--danger:hover {
	background: var(--danger-color-hover);
	color: var(--danger-color-fg);
}
.Button--has-icon::after {
	content: '';
	width: 20px;
	height: 20px;
	display: inline-block;
	margin-left: 16px;
	vertical-align: bottom;
	background-size: 18px auto;
	background-repeat: no-repeat;
	background-position: center center;
}

.Link {
	font-size: inherit;
	font-family: inherit;
	font-weight: inherit;
	line-height: inherit;
	vertical-align: baseline;
}
.Link--unstyled {
	color: inherit;
}
.Link--default {
	color: var(--link-color);
}
.Link--default:hover {
	text-decoration: underline;
}
.Link--action {
	text-decoration: underline;
	color: var(--action-link-color);
	font-weight: var(--semibold);
}
.Link--action:hover {
	opacity: 0.8;
}
.Link--forward {
	font-weight: var(--semibold);
	position: relative;
}
.Link--forward:after,
.Link--forward-white:after {
	content: '';
	display: inline-block;
	width: 0.75em;
	height: 0.75em;
	vertical-align: middle;
	background-size: 0.75em auto;
	background-repeat: no-repeat;
	background-position: center center;
	background-image: url('~/src/assets/icons/arrow-right.svg');
	margin-left: 10px;
}
.Link--forward:before,
.Link--forward-white:before {
	content: '';
	display: block;
	position: absolute;
	width: calc(100% - 24px);
	height: 2px;
	bottom: 0;
	left: 1px;
	background: currentColor;
	transform: scaleX(0);
	opacity: 0;
	transition: transform 200ms ease, opacity 150ms ease;
	transform-origin: left center;
}
.Link--forward:hover:before {
	transform: scaleX(1);
	opacity: 1;
}
.Link--forward:hover:after {
	animation: link-forward-arrow 300ms ease 130ms;
}
.Link--forward-white:after {
	background-image: url('~/src/assets/icons/arrow-right-white.svg');
}
@keyframes link-forward-arrow {
	40% {
		transform: translateX(3px);
	}
}
.Link--back {
	font-weight: var(--semibold);
	position: relative;
	color: var(--darkgray);
}
.Link--back:before {
	content: '';
	display: inline-block;
	width: 12px;
	height: 12px;
	vertical-align: middle;
	background-size: 12px auto;
	background-repeat: no-repeat;
	background-position: center center;
	background-image: url('~/src/assets/icons/arrow-left.svg');
	margin-right: 10px;
}
.Link--back:after {
	content: '';
	display: block;
	position: absolute;
	width: calc(100% - 24px);
	height: 2px;
	bottom: 0;
	right: 1px;
	background: currentColor;
	transform: scaleX(0);
	opacity: 0;
	transition: transform 200ms ease, opacity 150ms ease;
	transform-origin: right center;
}
.Link--back:hover:after {
	transform: scaleX(1);
	opacity: 1;
}
.Link--back:hover:before {
	animation: link-back-arrow 300ms ease 130ms;
}
@keyframes link-back-arrow {
	40% {
		transform: translateX(-3px);
	}
}

.IconButton {
	width: var(--button-size);
	height: var(--button-size);
	border-radius: 50%;
	background-size: 14px auto;
	background-position: center center;
	background-repeat: no-repeat;
	text-indent: -999px;
	overflow: hidden;
	background-color: var(--off-white);
	transition: background-color 150ms ease;
	position: relative;
}
.IconButton:hover {
	background-color: var(--gray);
}
.IconButton:after {
	content: '';
	display: inline-block;
}

.RoundButton {
	width: 30px;
	height: 30px;
	border-radius: 50%;
	flex-shrink: 0;
	background-size: 14px auto;
	background-position: center center;
	background-repeat: no-repeat;
	overflow: hidden;
	transition: background-color 150ms ease;
	background-color: var(--gray);
}
.RoundButton--has-children span svg {
	margin: auto;
	padding: auto;
	font-size: 15px;
}
.RoundButton--size-small {
}
.RoundButton--size-auto {
	width: 2em;
	height: 2em;
	background-size: 1em auto;
}
.RoundButton--size-48 {
	width: 48px;
	height: 48px;
	background-size: 32px auto;
}
.RoundButton:hover {
	background-color: var(--gray-hover);
}

.ActionLink {
	background: var(--secondary-color);
	color: var(--secondary-color-fg);
	min-height: 30px;
	font-size: 14px;
	font-weight: var(--semibold);
	line-height: 20px;
	padding: 5px 10px;
	border-radius: 4px;
	box-shadow: var(--shadow-clickable);
	text-decoration: none;
}
.ActionLink:hover {
	background: var(--secondary-color-hover);
}
.ActionLink:active {
	transform: translateY(1px);
}

.ActionLink--has-icon::after {
	content: '';
	display: inline-block;
	margin-left: 10px;
	width: 16px;
	height: 16px;
	background-position: center center;
	background-size: auto 16px;
	background-repeat: no-repeat;
	vertical-align: middle;
}

.icon-bg--link,
.icon--link::before,
.icon--link::after {
	background-image: url('~/src/assets/icons/link.svg');
}
.icon-bg--edit,
.icon--edit::before,
.icon--edit::after {
	background-image: url('~/src/assets/icons/edit.svg');
}
.icon-bg--people,
.icon--people::before,
.icon--people::after {
	background-image: url('~/src/assets/icons/people.svg');
}
.icon-bg--add-person,
.icon--add-person::before,
.icon--add-person::after {
	background-image: url('~/src/assets/icons/add-person.svg');
}
.icon-bg--archive,
.icon--archive::before,
.icon--archive::after {
	background-image: url('~/src/assets/icons/archive.svg');
}
.icon-bg--view,
.icon--view::before,
.icon--view::after {
	width: 12px;
	background-image: url('~/src/assets/icons/view.svg');
}
.icon-bg--more-v,
.icon--more-v::before,
.icon--more-v::after {
	background-size: 20px auto;
	background-image: url('~/src/assets/icons/more-v.svg');
}
.icon-bg--more-h,
.icon--more-h::before,
.icon--more-h::after {
	background-size: 20px auto;
	background-image: url('~/src/assets/icons/more-h.svg');
}
.icon-bg--remove,
.icon--remove::before,
.icon--remove::after {
	background-size: 15px auto;
	background-position: center center;
	background-image: url('~/src/assets/icons/remove.svg');
}
.icon-bg--next,
.icon--next::before,
.icon--next::after {
	background-size: 15px auto;
	background-position: center center;
	background-image: url('~/src/assets/icons/next.svg');
}
.icon-bg--previous,
.icon--previous::before,
.icon--previous::after {
	background-size: 15px auto;
	background-position: center center;
	background-image: url('~/src/assets/icons/previous.svg');
}
.icon-bg--arrow-left,
.icon--arrow-left::before,
.icon--arrow-left::after {
	background-size: 13px auto;
	background-position: center center;
	background-image: url('~/src/assets/icons/arrow-left.svg');
}
.icon-bg--arrow-right,
.icon--arrow-right::before,
.icon--arrow-right::after {
	background-size: 13px auto;
	background-position: center center;
	background-image: url('~/src/assets/icons/arrow-right.svg');
}
.icon-bg--duplicate,
.icon--duplicate::before,
.icon--duplicate::after {
	background-size: 16px auto;
	background-position: center center;
	background-image: url('~/src/assets/icons/duplicate.svg');
}
.icon-bg--trash,
.icon--trash::before,
.icon--trash::after {
	background-size: 14px auto;
	background-position: center center;
	background-image: url('~/src/assets/icons/trash.svg');
}
.icon-bg--sound-on,
.icon--sound-on::before,
.icon--sound-on::after {
	background-size: 26px auto;
	background-position: center center;
	background-image: url('~/src/assets/icons/sound-on.svg');
}
.icon-bg--sound-off,
.icon--sound-off::before,
.icon--sound-off::after {
	background-size: 26px auto;
	background-position: center center;
	background-image: url('~/src/assets/icons/sound-off.svg');
}
.icon-bg--time,
.icon--time::before,
.icon--time::after {
	background-size: 24px auto;
	background-position: center center;
	background-image: url('~/src/assets/icons/time.svg');
}
.icon-bg--printer,
.icon--printer::before,
.icon--printer::after {
	background-size: 24px auto;
	background-position: center center;
	background-image: url('~/src/assets/icons/printer.svg');
}
.icon-bg--user,
.icon--user::before,
.icon--user::after {
	background-size: 24px auto;
	background-position: center center;
	background-image: url('~/src/assets/icons/user.svg');
}
.icon-bg--users,
.icon--users::before,
.icon--users::after {
	background-size: 24px auto;
	background-position: center center;
	background-image: url('~/src/assets/icons/users.svg');
}
.icon-bg--right-curved-arrow,
.icon--right-curved-arrow::before,
.icon--right-curved-arrow::after {
	background-color: #000;
	background-size: 26px auto;
	background-position: center center;
	background-image: url('~/src/assets/icons/right-curved-arrow.svg');
}

.icon-bg--location,
.icon--location::before,
.icon--location::after {
	background-size: 26px auto;
	background-position: center center;
	background-image: url('~/src/assets/icons/location.svg');
}
.icon-bg--google-meeting,
.icon--google-meeting::before,
.icon--google-meeting::after {
	background-size: 260px auto;
	background-position: center center;
	background-image: url('~/src/assets/icons/google-meeting.svg');
}
.icon-bg--google,
.icon--google::before,
.icon--google::after {
	background-size: 260px auto;
	background-position: center center;
	background-image: url('~/src/assets/icons/google.svg');
}
.icon-bg--zoom,
.icon--zoom::before,
.icon--zoom::after {
	background-size: 260px auto;
	background-position: center center;
	background-image: url('~/src/assets/icons/zoom.svg');
}
