import React from 'react';
import classnames from 'classnames';

import { Button } from '~/src/lib/Buttons';

import * as css from './OverlayEditor.css';

export const OverlayEditor = ({ children, className }) => (
	<div className={classnames(css.OverlayEditor, className)}>{children}</div>
);

export const OverlayEditorHeader = ({
	children,
	className,
	title,
	titleStyle,
	align,
}) => (
	<div className={classnames(css.OverlayEditor__header, className)}>
		<h1
			className={classnames(
				css.OverlayEditor__title,
				css[`OverlayEditor__title_${align}`],
			)}
			style={titleStyle}
		>
			{title}
		</h1>
		{children}
	</div>
);

export const OverlayEditorContent = ({ children, className }) => (
	<div className={classnames(css.OverlayEditor__content, className)}>{children}</div>
);

export const OverlayEditorFooter = ({
	children,
	className,
	btnText,
	onDone,
	itemType,
	onClickText,
	onClickAction,
	onDelete,
	onArchive,
	customButton,
}) => (
	<div className={classnames(css.OverlayEditor__footer, className)}>
		<div>{children}</div>
		<div className={classnames(css.OverlayEditor__footer__actionBtn, className)}>
			{customButton && <div className={css.OverlayEditor__custombtn}>{customButton}</div>}
			{onDelete && (
				<Button
					shape="square"
					variant="danger"
					onClick={onDelete}
					track={[`Delete ${itemType}`, { where: 'Overlay Editor Header' }]}
				>
					Delete
				</Button>
			)}
			{onArchive && (
				<Button
					shape="square"
					variant="secondary"
					onClick={onArchive}
					track={[`Archive ${itemType}`, { where: 'Overlay Editor Header' }]}
				>
					Archive
				</Button>
			)}
			{onClickAction && (
				<Button
					shape="square"
					variant="secondary"
					onClick={onClickAction}
					track={[`Actions Click ${itemType}`, { where: 'Overlay Editor Header' }]}
				>
					{onClickText}
				</Button>
			)}
			{onDone && (
				<Button
					shape="square"
					variant="primary"
					onClick={onDone}
					track={[`Done Editing ${itemType}`, { where: 'Overlay Editor Header' }]}
				>
					{btnText && btnText}
					{!btnText && 'Done'}
				</Button>
			)}
		</div>
	</div>
);
