import React from 'react';
import { formatDigitalClock } from '~/src/utils/format';

import * as css from './DigitalClock.css';

export const DigitalClock = ({ remaining, total }) => (
	<div className={css.DigitalClock}>
		<span className={css.DigitalClock__remaining}>{formatDigitalClock(remaining)}</span>
		<span className={css.DigitalClock__total}>{formatDigitalClock(total)}</span>
	</div>
);
