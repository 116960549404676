.SessionPage {
	--content-width: 100%;
	--content-space-top: 20px;
	--page-pad-x: 0;
	align-items: stretch;
	box-sizing: border-box;
	max-height: 100%;
}

@media (max-height: 700px) {
	.SessionPage {
		--content-space-top: 20px;
	}
}
