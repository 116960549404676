.AppHeader {
	position: fixed;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
	height: var(--header-height);
	top: 0;
	left: 0;
	width: 100%;
	height: 10%;
	z-index: var(--z-app-header);
	pointer-events: none;
}
.logo {
	padding: 0 var(--page-pad-x);
	margin-right: auto;
	display: inline-block;
	height: 30px;
	font-size: 30px;
	font-weight: var(--semibold);
	line-height: 30px;
	pointer-events: all;
}
.logo:hover {
	text-decoration: none;
}
.profile {
	display: flex;
	margin-left: auto;
	pointer-events: all;
	padding: 0 var(--page-pad-x);
}

.copySuccess {
	display: none;
	position: absolute;
	bottom: -2px;
	font-size: 10px;
	background: rgb(226, 240, 203);
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 2px;
	border-radius: 30px;
	width: 50px;
	height: 20px;
}

.middle {
	align-self: stretch;
	display: flex;
	justify-content: center;
	align-items: center;
	pointer-events: all;
	padding: 10px 0;
	width: 90%;
}

.middle * {
	margin-inline: 4px;
}
.start {
	justify-content: center;
	align-items: center;
	pointer-events: all;
	margin-top: 5px;
	width: 5%;
}
.sessionTitle {
	cursor: default;
	font-size: 40px;
	line-height: 1em;
	font-weight: var(--semibold);
}
.backHomeBtn {
	margin-left: 40px;
}

@media (max-height: 700px) {
	.sessionTitle {
		font-size: 30px;
	}

	.AppHeader {
		margin-top: 15px;
	}
}
@media (max-height: 500px) {
	.sessionTitle {
		font-size: 25px;
	}
}

@media (max-width: 900px) {
}
