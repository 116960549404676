import React from 'react';
import { motion } from 'framer-motion';

import { Main, Content } from '~/src/lib/Layout';
import { Button } from '~/src/lib/Buttons';

import * as css from './WaitingApprovalPage.css';

const HIDE_SHOW = { hide: { opacity: 0 }, show: { opacity: 1 } };

export const WaitingApprovalPage = ({ location }) => {
	const params = new URLSearchParams(location.search);
	const isLogin = params.get('login');

	return (
		<Main className={css.WaitingApproval}>
			<Content>
				<motion.div
					variants={HIDE_SHOW}
					initial="hide"
					animate="show"
					transition={{
						staggerChildren: 0.1,
					}}
				>
					<h1 className={css.WaitingApproval__title}>Thank you!</h1>
					<div className={css.WaitingApproval__info}>
						<p>
							{ !isLogin ? `We've received your registration.` : '' } As soon as one of our administrators
							approves your account you will receive an email confirmation in your inbox
							(don't forget to check your spam.) We look forward to onboarding you to our
							community of first-access users.
						</p>
					</div>
					<Button
						href="mailto:support@thisandthat.chat"
						variant="primary"
						className={css.WaitingApproval__link}
						track={['Contact us', { where: 'Waiting for Approval' }]}
					>
						Contact us
					</Button>
				</motion.div>
			</Content>
		</Main>
	);
};
