import React, { Fragment } from 'react';

import { useSessionPrivileges } from '~/src/data/sessions';
import { RoundButton } from '~/src/lib/Buttons';
// import { MiniProgressBar } from '~/src/lib/MiniProgressBar';
import { useLayout } from '~/src/utils/useLayout';

import * as css from './SectionSeparator.css';

export const SectionSeparator = ({ onNextClick, session }) => {
	const layout = useLayout();
	const privileges = useSessionPrivileges(session);
	// TODO: Count down mini progress bar
	return (
		<div className={css.SectionSeparator} style={layout.getSectionSeparatorStyle()}>
			<div className={css.SectionSeparator__ruler}></div>
			<div className={css.SectionSeparator__content}>
				<div className={css.SectionSeparator__label}>Next section</div>
				{privileges.canEdit && (
					<Fragment>
						<RoundButton
							className={css.SectionSeparator__nextButton}
							icon="next"
							onClick={onNextClick}
							size="48"
							track={['Next Section', { where: 'Inter-section button' }]}
						/>
					</Fragment>
				)}
				{/*<MiniProgressBar progress={0.8} />*/}
			</div>
			<div className={css.SectionSeparator__ruler}></div>
		</div>
	);
};
