import React, { useEffect, useState, Fragment } from 'react';
import { useHistory } from 'react-router-dom';

import { useUserForAuth } from '~/src/data/user';
import { Main, Content } from '~/src/lib/Layout';
import { Button } from '~/src/lib/Buttons';

import * as css from './AuthPage.css';

export const LogoutPage = () => {
	const history = useHistory();
	const [isSignedOut, setIsSignedOut] = useState(false);
	const { message, signoutUser, user } = useUserForAuth();
	useEffect(() => {
		if (isSignedOut) {
			history.push('/');
		}
	}, [isSignedOut, history]);
	const handleSubmitSignout = async (event) => {
		event.preventDefault();
		await signoutUser();
		setIsSignedOut(true);
	};
	return (
		<Main className={css.AuthPage}>
			<Content layout="fill-and-center">
				{user ? (
					<Fragment>
						<div className={css.AuthPage__message}>Signed in as {user.name}</div>
						<Button
							onClick={handleSubmitSignout}
							track={['Submit Signout', { where: 'Logout page' }]}
						>
							Log out of account
						</Button>
					</Fragment>
				) : (
					<Fragment>
						<div className={css.AuthPage__message}>Not signed in</div>
						<Button to="/home" track={['Go Home', { where: 'Logout page' }]}>
							Home
						</Button>
					</Fragment>
				)}
				<p>{message ? message : null}</p>
			</Content>
		</Main>
	);
};
