import endSection from '~/src/assets/sounds/end-section.wav';
import endSlot from '~/src/assets/sounds/end-slot.wav';
import endSession from '~/src/assets/sounds/end-session.wav';

export const fileSounds = (sound) => {
	switch (sound) {
		case 'end-section.wav':
			return endSection;
		case 'end-slot.wav':
			return endSlot;
		case 'end-session.wav':
			return endSession;
		default:
			return;
	}
};

export const SOUNDS = {
	START_SESSION: 'start-session.wav',
	START_SECTION: 'start-section.wav',
	START_SLOT: 'start-slot.wav',
	END_SESSION: 'end-session.wav',
	END_SECTION: 'end-section.wav',
	END_SLOT: 'end-slot.wav',
};
