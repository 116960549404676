.Session {
	--session-sidebar-width: 200px;
	--section-header-height: 100px;
	--session-header-height: 50px;
	--session-footer-height: 100px;
	--slot-border-width: 3px;
	--session-header-bottom-space: 30px;
	--section-title-size: 25px;
	--section-clock-size: 20px;
	flex: 1;
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
}
@media (max-height: 700px) {
	.Session {
		--content-space-top: 20px;
		--session-header-bottom-space: 10px;
		--section-title-size: 18px;
		--section-clock-size: 16px;
	}
}

.Session__addSection {
	display: flex;
	align-items: center;
}
.Session__addSectionButton {
	width: 20vw;
	height: 20vw;
	margin-left: 100px;
	background-image: url('~/src/assets/icons/add.svg');
	background-size: 40px auto;
	background-repeat: no-repeat;
	background-position: center center;
	cursor: pointer;
	border-radius: 40px;
	background-color: var(--primary-color);
	box-shadow: 1px 2px 1px rgba(0, 0, 0, 0.2);
}
.Session__addSectionButton:hover {
	opacity: 1;
}
