import React, { useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';

import { useUserForAuth } from '~/src/data/user';
import { Button, Link } from '~/src/lib/Buttons';
import { Main, Content } from '~/src/lib/Layout';
import { Label } from '~/src/lib/Form/Label';
import { ErrorBox } from '~/src/lib/ErrorBox';
import { TextInput } from '~/src/lib/Form/TextInput';

import * as css from './AuthPage.css';

export const SignupPage = ({ location }) => {
	const { user } = useUserForAuth();
	const params = new URLSearchParams(location.search);
	const token = params.get('token');
	if (user) {
		return <Redirect to="/home" />;
	}
	if (!token) {
		return <Step1 />;
	}
};

// Submit email
const Step1 = () => {
	const [isLoading, setIsLoading] = useState(false);
	const { message, submitSignup, submitLogin, error } = useUserForAuth();
	const [email, setEmail] = useState('');
	const [name, setName] = useState('');
	const [company, setCompany] =  useState('');
	const [password, setPassword] = useState('');
	const campaign = new URLSearchParams(window.location.search).get('campaign');

	const history = useHistory();

	// Step 1, user submits signup data
	const handleSignupSubmit = async (event) => {
		event.preventDefault();
		setIsLoading(true);
		const saved = await submitSignup({ email, name, company, password, campaign });
		await submitLogin({ email, password });
		setIsLoading(false);
		if (saved) {
			history.push('/home');
		}
	};
	return (
		<Main className={css.AuthPage} headerProps={{ showLogin: false }}>
			<Content>
				{isLoading ? (
					'Loading...'
				) : message ? (
					<div className={css.AuthPage__message}>{message}</div>
				) : (
					<form onSubmit={handleSignupSubmit}>
						<label className={css.AuthPage__input}>
							<Label>Name</Label>
							<TextInput
								type="name"
								name="name"
								placeholder="Your Name"
								onChange={(event) => setName(event.target.value)}
							/>
						</label>
						<label className={css.AuthPage__input}>
							<Label>Email</Label>
							<TextInput
								type="email"
								name="email"
								placeholder="your@email.com"
								onChange={(event) => setEmail(event.target.value)}
							/>
						</label>
						<label className={css.AuthPage__input}>
							<Label>Company (optional)</Label>
							<TextInput
								type="text"
								name="name"
								placeholder="Your Company"
								onChange={(event) => setCompany(event.target.value)}
							/>
						</label>
						<label className={css.AuthPage__input}>
							<Label>Password</Label>
							<TextInput
								type="password"
								name="password"
								placeholder="*******"
								onChange={(event) => setPassword(event.target.value)}
							/>
						</label>
						<div className={css.AuthPage__actions}>
							<Button type="submit" track={['Submit Signup', { where: 'Signup page' }]}>
								Sign up
							</Button>
							<p className={css.AuthPage__swapMessage}>
								or{' '}
								<Link to="/login" track={['Go Login', { where: 'Signup page' }]}>
									log in
								</Link>
							</p>
						</div>
						<p className={css.AuthPage__legalNotice}>
							You will receive an email to complete your sign&nbsp;up. By signing up you
							accept the{' '}
							<Link
								to="/privacy-policy"
								track={['Go Privacy policy', { where: 'Signup page' }]}
							>
								privacy policy
							</Link>{' '}
							and{' '}
							<Link
								to="/terms-and-conditions"
								track={['Go T&C', { where: 'Signup page' }]}
							>
								terms & conditions
							</Link>
							.
						</p>
						{error && (
							<div className={css.AuthPage__error}>
								<ErrorBox error={error} />
							</div>
						)}
					</form>
				)}
			</Content>
		</Main>
	);
};
