.Main {
	flex: 1;
	display: flex;
	align-items: flex-start;
	padding-top: var(--header-height);
}
.Main__content {
	max-width: var(--content-width);
	flex: 1;
	display: flex;
	align-items: flex-start;
	padding: 0 var(--page-pad-x);
	position: relative;
}

.Loader {
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-top: var(--header-height);
}
.Loader__content {
	max-width: var(--content-width);
	flex: 1;
	display: flex;
	flex-direction: column;
	grid-gap: 20px;
	align-items: center;
	justify-content: center;
	position: relative;
	font-size: 30px;
	animation: loader-fade-in 300ms ease 100ms backwards;
	margin-bottom: 10vh;
}
@keyframes loader-fade-in {
	0% {
		opacity: 0;
	}
}

.Content--layout-fill-and-center {
	flex: 1;
	display: flex;
	align-self: stretch;
	align-items: center;
	flex-direction: column;
	justify-content: center;
}

@media (min-width: 750px) {
	.Main__content {
		margin: var(--content-space-top) auto 0;
	}
	.Content {
		flex: 55;
	}
	.Side {
		flex: 45;
		padding: var(--space-top) 0 var(--space-bottom) 90px;
	}
}

@media (max-width: 900px) {
	.Main__content {
		display: flex;
		flex-direction: column;
		row-gap: 50px;
	}
	.Content,
	.Side {
		flex: auto;
	}
}
