.SessionList {
	display: flex;
	flex-direction: column;
	width: 100%;
	text-align: left;
	margin-top: 28px;
	margin-bottom: 13px;
}
.SessionList--preview {
}
.SessionList--full {
	display: flex;
	flex-direction: column;
}
.SessionListItem {
	border-radius: 15px;
	transition: background-color 150ms ease, box-shadow 150ms ease;
	margin-left: calc(5px - var(--space-x));
	margin-right: calc(0px - var(--space-x));
}
.SessionListItem--preview {
	--title-font: var(--title-tiny);
	--info-font: var(--body-small);
	margin-bottom: 19px;
	height: 53px;
}
.SessionListItem--full {
	--title-font: var(--title-small);
	--info-font: var(--body-regular);
	--space-x: 30px;
	--space-y: 20px;
	row-gap: 10px;
}
.SessionListItem--preview + .SessionListItem--preview {
}
.SessionListItem--full + .SessionListItem--full {
	margin-top: 20px;
}

.SessionListItem:hover {
}
.SessionListItem__link:after {
	content: '';
	grid-row: 1 / -1;
	grid-column: 2;
	display: inline-block;
	width: 30px;
	height: 100%;
	background-size: 14px auto;
	background-repeat: no-repeat;
	background-position: center right;
	background-image: url('~/src/assets/icons/chevron-right.svg');
	transition: transform 150ms ease;
}
.SessionListItem__link:hover:after {
	transform: translateX(3px);
}

.SessionListItem__link {
	text-decoration: none;
	display: grid;
	grid-template-rows: auto auto auto;
	grid-template-columns: 1fr auto;
	padding: var(--space-y) var(--space-x);
	gap: inherit;
	cursor: pointer;
}
.SessionListItem__link:hover {
	text-decoration: none;
}
.SessionListItem__title {
	text-align: left;
	font: var(--title-font);
	font-size: 16px;
	font-weight: 700;
	grid-row: 1;
	grid-column: 1;
}
.SessionListItem__info {
	font: var(--info-font);
	font-size: 14px;
	grid-row: 2;
	grid-column: 1;
}
.SessionListItem__actions {
	grid-row: 3;
	grid-column: 1;
	cursor: default;
	display: flex;
	grid-gap: 20px;
}
.SessionListItem__actionItem {
}
