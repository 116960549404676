.PendingUsersList {
	display: flex;
	flex-direction: column;
	width: 100%;
	text-align: left;
}
.PendingUsersList--preview {
}
.PendingUsersList--full {
	display: flex;
	flex-direction: column;
}
.PendingUsersListItem {
	border-radius: 15px;
	transition: background-color 150ms ease, box-shadow 150ms ease;
	margin-left: calc(0px - var(--space-x));
	margin-right: calc(0px - var(--space-x));
}
.PendingUsersListItem--preview {
	--title-font: var(--title-tiny);
	--info-font: var(--body-small);
	--space-x: 20px;
	--space-y: 15px;
}
.PendingUsersListItem--full {
	--title-font: var(--title-small);
	--info-font: var(--body-regular);
	--space-x: 30px;
	--space-y: 20px;
	row-gap: 10px;
}
.PendingUsersListItem--preview + .PendingUsersListItem--preview {
	margin-top: 10px;
}
.PendingUsersListItem--full + .PendingUsersListItem--full {
	margin-top: 20px;
}

.PendingUsersListItem:hover {
}
.PendingUsersListItem__link:after {
	content: '';
	grid-row: 1 / -1;
	grid-column: 2;
	display: inline-block;
	width: 30px;
	height: 100%;
	background-size: 14px auto;
	background-repeat: no-repeat;
	background-position: center right;
	background-image: url('~/src/assets/icons/chevron-right.svg');
	transition: transform 150ms ease;
}
.PendingUsersListItem__link:hover:after {
	transform: translateX(3px);
}

.PendingUsersListItem__link {
	text-decoration: none;
	display: grid;
	grid-template-rows: auto auto auto;
	grid-template-columns: 1fr auto;
	padding: var(--space-y) var(--space-x);
	gap: inherit;
	cursor: pointer;
}
.PendingUsersListItem__link:hover {
	text-decoration: none;
}
.PendingUsersListItem__name {
	text-align: left;
	font: var(--title-font);
	grid-row: 1;
	grid-column: 1;
}
.PendingUsersListItem__email {
	font: var(--info-font);
	grid-row: 2;
	grid-column: 1;
}
.PendingUsersListItem__actions {
	grid-row: 3;
	grid-column: 1;
	cursor: default;
	display: flex;
	grid-gap: 20px;
}
.PendingUsersListItem__actionItem {
}
