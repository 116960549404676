import React from 'react';
import { motion } from 'framer-motion';

import { Main, Content } from '~/src/lib/Layout';
import { Button } from '~/src/lib/Buttons';

import * as css from './WaitingApprovalPage.css';

const HIDE_SHOW = { hide: { opacity: 0 }, show: { opacity: 1 } };

export const WaitingRedefineApprovalPage = ({ location }) => {
	const params = new URLSearchParams(location.search);
	const isLogin = params.get('login');

	return (
		<Main className={css.WaitingApproval}>
			<Content>
				<motion.div
					variants={HIDE_SHOW}
					initial="hide"
					animate="show"
					transition={{
						staggerChildren: 0.1,
					}}
				>
					<h1 className={css.WaitingApproval__title}>Password successfully reset!</h1>
					<div className={css.WaitingApproval__info}>
						<p>
							For users who have not yet received their approval email, your new password
							will become active once our admins grant you access. See you soon!
						</p>
					</div>
					<Button
						href="mailto:support@thisandthat.chat"
						variant="primary"
						className={css.WaitingApproval__link}
						track={['Contact us', { where: 'Waiting for Approval' }]}
					>
						Contact us
					</Button>
				</motion.div>
			</Content>
		</Main>
	);
};
