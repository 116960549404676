import React, { Fragment, useState, useRef } from 'react';
import classnames from 'classnames';
import { motion } from 'framer-motion';
import { useHistory } from 'react-router-dom';
import defaultTo from 'lodash/defaultTo';

import { formatDigitalClock } from '~/src/utils/format';
import { getColorFromIndex } from '~/src/utils/color';
import { useLivePlayState } from '~/src/data/playState';
import { useZoom } from '~/src/utils/useZoom';
import { useLayout } from '~/src/utils/useLayout';
import { quantize } from '~/src/utils/quantize';
import { limit } from '~/src/utils/limit';
import {
	getSlotRowStartIndex,
	getSlotRowEndIndex,
	getSlotColorIndex,
} from '~/src/utils/slot';

import * as css from './SessionSlot.css';
import { usePlayState } from '../../data/playState';

const PAN_CLICK_THRESHOLD = 100;
// HACK: When "panning" a slot, a click is triggered when releasing
// the mouse. This means that moving a slot clicks it, highlighting it.
// So, we keep track of when we last finished panning, and in the click
// handler we make sure that we've passed the threshold before taking action.
// This works since we only pan one slot at a time.
let lastPanEnd = 0;

export const SessionSlot = ({
	slot,
	isSelected,
	canEdit,
	section,
	session,
	sectionIsActive,
}) => {
	const zoom = useZoom();
	const ref = useRef();
	const layout = useLayout();
	const playStateLive = useLivePlayState(session);
	const playState = usePlayState(session);
	const [resize, setResize] = useState(null);
	const history = useHistory();
	if (!slot) {
		return null;
	}
	const handlePan = (event, info) => {
		event.preventDefault();
		const panType =
			(resize && resize.panType) || event.target.getAttribute('data-pan-type');
		if (!panType) {
			console.warn('Missing panType', event.target);
		}
		playState.setSession(session);
		return setResize({
			// TODO: Sometimes the panType isn't set because the pan starts outside of
			// the pan elements, if so we fall back to bottom which seems to be the type
			// most commonly affected by the issue.
			panType: panType || 'move',
			offsetX: info.offset.x,
			offsetY: info.offset.y,
		});
	};
	const liveSlot = getSlotSize(slot, resize, zoom.x, layout, section, session);
	const handlePanEnd = (event) => {
		lastPanEnd = Date.now();
		event.stopPropagation();
		event.preventDefault();
		setResize(null);
		session.updateSlot(slot.id, {
			duration: defaultTo(liveSlot.duration, slot.duration),
			start: defaultTo(liveSlot.start, slot.start),
			rowIndex: defaultTo(liveSlot.rowStartIndex, getSlotRowStartIndex(slot)),
		});
		playState.setSession(session);
	};
	const handleSlotClick = () => {
		if (!canEdit) {
			return;
		}
		if (Date.now() - lastPanEnd < PAN_CLICK_THRESHOLD) {
			return;
		}
		history.push(`/sessions/${session.id}/slots/${slot.id}`);
	};
	const colors = getColorFromIndex(
		defaultTo(liveSlot && liveSlot.rowStartIndex, getSlotColorIndex(slot)),
	);
	const style = {
		position: 'absolute',
		...layout.getSlotStyle(slot, liveSlot),
		background: colors.normal,
		borderColor: colors.stroke,
		boxShadow: getBoxShadow(resize, isSelected),
	};
	const progress = sectionIsActive ? playStateLive.getSlotProgress(slot) : 0;
	const slotIsActive = progress > 0 && progress < 1;
	const isResizingWidth = resize && resize.panType !== 'move';
	return (
		<motion.div
			ref={ref}
			className={css.Slot}
			animate={style}
			transition={{ bounce: 0, duration: resize ? 0 : 0.1 }}
			onPan={canEdit && handlePan}
			onPanEnd={canEdit && handlePanEnd}
			initial={false}
		>
			<div className={css.Slot__progressWrap}>
				<div
					style={{
						transform: `translateX(-${(1 - progress) * 100}%)`,
						backgroundColor: colors.darker,
					}}
					className={css.Slot__progress}
				></div>
			</div>
			{slot.spanAll ? (
				<div className={css.Slot__background}>
					{session.rows.map((row, index) => (
						<div
							key={row.id}
							className={css.Slot__backgroundStripe}
							style={{
								...layout.getRowStyle(index, 0),
								background: getColorFromIndex(index).normal,
							}}
						></div>
					))}
				</div>
			) : null}
			<div style={{ opacity: isResizingWidth ? 1 : 0 }} className={css.Slot__duration}>
				{formatDigitalClock(defaultTo(liveSlot && liveSlot.duration, slot.duration))}
			</div>
			<div style={{ opacity: slotIsActive ? 1 : 0 }} className={css.Slot__countdown}>
				{formatDigitalClock((1 - progress) * slot.duration)}
			</div>
			<div
				style={{ opacity: isResizingWidth || slotIsActive ? 0 : 1 }}
				className={css.Slot__text}
			>
				{slot.text}
			</div>
			{canEdit && (
				<Fragment>
					<div
						data-pan-type="start"
						className={classnames(css.Slot__handle, css['Slot__handle--start'])}
						style={layout.getSlotHandleStyle('start', slot)}
					></div>
					<div
						data-pan-type="move"
						onClick={handleSlotClick}
						className={classnames(css.Slot__handle, css['Slot__handle--middle'])}
						style={{
							...layout.getSlotHandleStyle('middle', slot),
							opacity: resize && resize.panType !== 'move' ? 0 : null,
						}}
					></div>
					<div
						data-pan-type="end"
						className={classnames(css.Slot__handle, css['Slot__handle--end'])}
						style={layout.getSlotHandleStyle('end', slot)}
					></div>
				</Fragment>
			)}
		</motion.div>
	);
};

const getSlotSize = (slot, resize, zoomX, layout, section, session) => {
	if (!resize) {
		return null;
	}
	if (resize.panType === 'start') {
		const start = quantize(limit(slot.start + resize.offsetX / zoomX, 0));
		// TODO: BUG: When dragging further than 0 to the left, the duration increases
		return {
			start,
			duration: quantize(
				limit(slot.duration - resize.offsetX / zoomX, 10, section.duration),
			),
		};
	} else if (resize.panType === 'end') {
		const duration =
			limit(slot.duration + resize.offsetX / zoomX + slot.start, 0, section.duration) -
			slot.start;
		return {
			start: quantize(slot.start),
			duration: quantize(duration),
		};
	} else if (resize.panType === 'move') {
		const start = quantize(
			limit(slot.start + resize.offsetX / zoomX, 0, section.duration - slot.duration),
		);
		const duration = quantize(slot.duration);
		if (slot.spanAll) {
			return {
				start,
				duration,
				rowStartIndex: getSlotRowStartIndex(slot),
				rowEndIndex: getSlotRowEndIndex(slot, session),
			};
		}
		const rowDelta = layout.getIndexDeltaFromOffset(resize.offsetY);
		const rowIndex = Math.max(0, slot.rowIndex + rowDelta);
		return {
			start,
			duration,
			rowStartIndex: rowIndex,
			rowEndIndex: rowIndex,
		};
	} else {
		return slot;
	}
};

const getBoxShadow = (resize, isSelected) => {
	if (resize) {
		if (resize.panType === 'start') {
			return '-9px 0px 10px -7px rgba(0, 0, 0, 0.2)';
		} else if (resize.panType === 'end') {
			return '9px 0px 10px -7px rgba(0, 0, 0, 0.2)';
		}
	} else if (isSelected) {
		return '0px 0.5px 20px 0px rgba(0, 0, 0, 0.1)';
	}
	return '0px 0.5px 1px 0px rgba(0, 0, 0, 0.25)';
};
