import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { track } from '~/src/utils/analytics';
import {
	OverlayEditor,
	OverlayEditorHeader,
	OverlayEditorContent,
} from '~/src/lib/OverlayEditor';
import { TextArea } from '~/src/lib/Form/TextArea';
import { TextInput } from '~/src/lib/Form/TextInput';
import { Label } from '~/src/lib/Form/Label';

import * as css from './SessionDetailsEditor.css';
import { OverlayEditorFooter } from '../OverlayEditor';
import { RoundButton } from '../Buttons';
import { usePlayState } from '../../data/playState';

export const SessionDetailsEditor = ({ onDone, session }) => {
	const history = useHistory();
	const [title, setTitle] = useState(session.title);
	const [description, setDescription] = useState(session.description);
	const [gap, setGap] = useState(session.gapBetweenSections);
	const [isMuted, setMuted] = useState(session.isMuted || false);
	const playstate = usePlayState(session);
	const handleTitleChange = (event) => {
		setTitle(event.target.value);
	};
	const handleDescriptionChange = (event) => {
		setDescription(event.target.value);
	};
	const handleGapDurationChange = (event) => {
		setGap(event.target.value);
	};
	const handleArchive = async () => {
		session.update({ archivedAt: new Date() });
		history.push(`/sessions/${session.id}`);
	};
	const handleOnDone = () => {
		if (title !== session.title) {
			track('Set Session Title');
		}
		if (description !== session.description) {
			track('Set Session Description');
		}
		if (gap !== session.gapBetweenSections) {
			track('Set Session Gap Between Sections');
		}
		if (isMuted !== session.isMuted) {
			track('Set Session isMuted');
		}
		session.update({ title, description, gapBetweenSections: gap, isMuted: isMuted });
		playstate.setSession(session);
		onDone();
	};
	const toggleAudioDisplay = useCallback(() => {
		setMuted((prevState) => !prevState);
	}, []);
	const handleKeydown = (event) => {
		if (event.which === 13 && !event.shiftKey) {
			track('Done Editing Session', { how: 'Enter key' });
			event.preventDefault();
			handleOnDone();
		}
	};
	return (
		<OverlayEditor className={css.SessionDetailsEditor}>
			<OverlayEditorHeader
				title="Session details"
				itemType="Session"
			></OverlayEditorHeader>
			<OverlayEditorContent>
				<Label>Title</Label>
				<TextArea onChange={handleTitleChange} onKeyDown={handleKeydown} value={title} />
				<Label>Description</Label>
				<TextArea onChange={handleDescriptionChange} value={description} />
				<Label>Duration between sections</Label>
				<TextInput
					onChange={handleGapDurationChange}
					onKeyDown={handleKeydown}
					value={gap}
				/>
				<Label>Session sounds</Label>
				<RoundButton
					icon={isMuted ? 'sound-off' : 'sound-on'}
					onClick={toggleAudioDisplay}
					track={['Edit Section', { where: 'Section Header edit button' }]}
				/>
			</OverlayEditorContent>
			<OverlayEditorFooter
				onArchive={handleArchive}
				onDone={handleOnDone}
				itemType="Session"
			/>
		</OverlayEditor>
	);
};
