import React from 'react';
import classnames from 'classnames';

import { useSessionPrivileges } from '~/src/data/sessions';
import { usePlayState, useLivePlayState } from '~/src/data/playState';
import { DigitalClock } from '~/src/lib/DigitalClock';
import { BaseButton } from '~/src/lib/Buttons';

import * as css from './SessionClock.css';

export const SessionClock = ({ session }) => {
	const privileges = useSessionPrivileges(session);
	return (
		<div className={css.SessionClock}>
			{privileges.canEdit ? (
				<OwnerClock session={session} />
			) : (
				<ParticipantClock session={session} />
			)}
		</div>
	);
};

const ParticipantClock = ({ session }) => {
	const { isPlaying } = usePlayState(session);
	const playState = useLivePlayState(session);
	return (
		<div className={css.ParticipantClock}>
			<div className={css.ParticipantClock__state}>
				{isPlaying ? 'Playing' : 'Paused'}
			</div>
			<div className={css.ParticipantClock__time}>
				<DigitalClock remaining={playState.currentTime} total={session.duration} />
			</div>
		</div>
	);
};

const OwnerClock = ({ session }) => {
	const { isPlaying, togglePlay } = usePlayState(session);
	const playState = useLivePlayState(session);
	return (
		<div className={css.OwnerClock}>
			<BaseButton
				className={classnames(
					css.OwnerClock__button,
					css['OwnerClock__button--previous'],
				)}
				onClick={() => playState.goToBoundary(-1)}
				track={['Previous Section']}
			>
				Previous
			</BaseButton>
			{isPlaying ? (
				<BaseButton
					className={classnames(css.OwnerClock__button, css['OwnerClock__button--pause'])}
					onClick={togglePlay}
					track={['Pause Session']}
				>
					Pause
				</BaseButton>
			) : (
				<BaseButton
					className={classnames(css.OwnerClock__button, css['OwnerClock__button--play'])}
					onClick={togglePlay}
					track={['Play Session']}
				>
					Play
				</BaseButton>
			)}
			<BaseButton
				className={classnames(css.OwnerClock__button, css['OwnerClock__button--next'])}
				onClick={() => playState.goToBoundary(+1)}
				track={['Next Section']}
			>
				Next
			</BaseButton>
			<div className={css.OwnerClock__time}>
				<DigitalClock remaining={playState.currentTime} total={session.duration} />
			</div>
		</div>
	);
};
