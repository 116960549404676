import React, { Fragment } from 'react';
import classnames from 'classnames';

import { useUI } from '~/src/utils/useUI';
import { AppHeader } from '~/src/lib/AppHeader';

import * as css from './Layout.css';

export const Main = ({
	children,
	className,
	background,
	headerProps = {},
	mainContentCustomStyle = {},
}) => {
	const ui = useUI();
	return (
		<Fragment>
			<AppHeader {...headerProps} />
			<main
				style={{ paddingTop: ui.appHeaderVisible ? null : 0 }}
				className={classnames(css.Main, className)}
			>
				{background}
				<div style={mainContentCustomStyle} className={css.Main__content}>
					{children}
				</div>
			</main>
		</Fragment>
	);
};

export const Content = ({ children, layout = 'default' }) => {
	return (
		<div className={classnames(css.Content, css[`Content--layout-${layout}`])}>
			{children}
		</div>
	);
};

export const Side = ({ children }) => {
	return <div className={css.Side}>{children}</div>;
};

export const Loader = ({ children, className, showHeader = false }) => (
	<Fragment>
		{showHeader ? <AppHeader /> : null}
		<main className={classnames(css.Loader, className)}>
			<div className={css.Loader__content}>{children}</div>
		</main>
	</Fragment>
);
