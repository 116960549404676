import React from 'react';
import classnames from 'classnames';
import { motion } from 'framer-motion';

import { ActionLink, Link } from '~/src/lib/Buttons';
import { post, put } from '../data/api';

import { useToasts } from 'react-toast-notifications';

import * as css from './PendingUsersList.css';
import { usePendingUsers, useUser } from '../data/user';
const TOAST_DURATION = 3000;

export const PendingUsersList = ({ users, variant }) => {
	const { refresh } = usePendingUsers();
	const { addToast } = useToasts();

	const handleArchive = async (user) => {
		put(`/users/${user.id}/archive`)
			.then(() => {
				refresh();
				addToast(`Successfully archived.`, {
					appearance: 'success',
					autoDismiss: true,
					autoDismissTimeout: TOAST_DURATION,
				});
			})
			.catch((error) =>
				addToast(error.message, {
					appearance: 'error',
					autoDismiss: true,
					autoDismissTimeout: TOAST_DURATION,
				}),
			);
	};
	const handleApprove = async (user) => {
		put(`/users/${user.id}/approve`)
			.then((data) => {
				refresh();
				addToast(`Successfully approved.`, {
					appearance: 'success',
					autoDismiss: true,
					autoDismissTimeout: TOAST_DURATION,
				});
			})
			.catch((error) =>
				addToast(error.message, {
					appearance: 'error',
					autoDismiss: true,
					autoDismissTimeout: TOAST_DURATION,
				}),
			);
	};
	return (
		<div
			className={classnames(css.PendingUsersList, css[`PendingUsersList--${variant}`])}
		>
			{(users && users.length) &&
				users.map((user) => (
					<PendingUsersListItem
						key={user.id}
						user={user}
						handleArchive={handleArchive}
						handleApprove={handleApprove}
						variant={variant}
					/>
				))}
			{!users && <h4>No pending users</h4>}
		</div>
	);
};

export const PendingUsersListItem = ({
	user,
	handleArchive,
	handleApprove,
	variant = 'preview',
}) => {
	const isFull = variant === 'full';
	const LinkComponent = isFull ? 'div' : Link;

	return (
		<motion.article
			className={classnames(
				css.PendingUsersListItem,
				css[`PendingUsersListItem--${variant}`],
			)}
		>
			<LinkComponent
				className={css.PendingUsersListItem__link}
				track={isFull ? null : ['View Pending User', { where: 'Pending Users List' }]}
			>
				<h3 className={css.PendingUsersListItem__name}>{user.name}</h3>
				<p className={css.PendingUsersListItem__email}>{user.email}</p>
				<Actions user={user} handleArchive={handleArchive} handleApprove={handleApprove}>
					Actions
				</Actions>
			</LinkComponent>
		</motion.article>
	);
};

const Actions = ({ user, handleArchive, handleApprove }) => {
	const handleArchiveClick = () => {
		handleArchive(user);
	};
	const handleApprovalClick = () => {
		handleApprove(user);
	};
	return (
		<div className={css.PendingUsersListItem__actions}>
			<ActionLink
				onClick={handleApprovalClick}
				className={css.PendingUsersListItem__actionItem}
				track={['View Participants', { where: 'Full Session List' }]}
			>
				Approve
			</ActionLink>
			<ActionLink
				className={css.PendingUsersListItem__actionItem}
				onClick={handleArchiveClick}
				track={['Duplicate Session', { where: 'Full Session List' }]}
			>
				Archive
			</ActionLink>
		</div>
	);
};
