import { createContext, useContext, useState, useEffect } from 'react';

import { useResource, post, del, put } from './api';
import { Session } from '~/src/models';
import { useUser } from '~/src/data/user';

export const SessionContext = createContext();

export const useSessionLoader = ({ id }) => {
	const [session, setSession] = useState(null);
	const privileges = useSessionPrivileges({ id });
	const [sessionKey, setSessionKey] = useState(null);
	const response = useResource(`/sessions/${id}`);
	// Load data and create session object
	useEffect(() => {
		if (response.data && response.data.session) {
			// We have an active session in memory, update it
			if (session && session.id === id) {
				// Since we update the session in place we need
				// a different way of indicating that change has
				// happened. We do that by using a random key which
				// is automatically updated in the session whenever
				// its save method is called. If it hasn't changed,
				// then our data hasn't changed either, and so we
				// shouldn't call update.
				if (sessionKey === session.key) {
					return;
				}
				session.update(response.data.session, privileges.canEdit);
			} else {
				// No active session instance, create a new
				const newSession = new Session(id, response.data.session);
				setSession(newSession);
				setSessionKey(newSession.key);
			}
		}
	}, [id, response.data, session, privileges, sessionKey]);
	// Make sure we set a new random key whenever the session updates
	useEffect(() => {
		if (session) {
			// Subscribe to updates
			return session.onUpdate(() => {
				setSessionKey(session.key);
			});
		}
	}, [session, sessionKey]);
	// Undo/redo
	useEffect(() => {
		const handleKeydown = (event) => {
			if (!session) {
				return;
			}
			const isSuper = event.ctrlKey || event.metaKey;
			const isShift = event.shiftKey;
			const isZ = event.which === 90;
			if (isSuper && isZ) {
				event.preventDefault();
				if (isShift) {
					// Redo
					session.redo();
				} else {
					// Undo
					session.undo();
				}
			}
		};
		window.addEventListener('keydown', handleKeydown);
		return () => window.removeEventListener('keydown', handleKeydown);
	}, [session]);
	return {
		isLoading: response.isLoading || !session,
		error: response.error,
		session,
	};
};

export const useSession = () => {
	return useContext(SessionContext);
};

export const useSessionPrivileges = (session) => {
	const { user } = useUser();
	const { participants } = useSessionParticipants(session && session.id);

	if (!session) {
		return null;
	}

	if (!(user && participants)) {
		return {
			canEdit: true, // everyone can edit and control for now
			canControl: true, // everyone can edit and control for now
			canView: true,
			isOwner: false
		};
	}

	const matchedUser = participants.find((participant) => participant.id === user.id);

	if (!matchedUser) {
		return {
			canEdit: true, // everyone can edit and control for now
			canControl: true, // everyone can edit and control for now
			canView: true,
			isOwner: false
		};
	}

	return {
		canEdit: true, // matchedUser.isOwner || matchedUser.isEditor, // everyone can edit and control for now
		canControl: true, // matchedUser.isOwner || matchedUser.isEditor, // everyone can edit and control for now
		canView: true,
		isOwner: matchedUser.isOwner
	};
};

export const useSessions = () => {
	const { user } = useUser();
	const { data, isLoading, error, mutate } = useResource(user ? '/sessions' : null);
	return {
		data: data && data.sessions ? data.sessions : [],
		error,
		isLoading,
		refresh: () => mutate(),
	};
};

export const useSessionParticipants = (sessionId) => {
	const [isSubmitting, setIsSubmitting] = useState(false);
	const endpoint = `/sessions/${sessionId}/participants`;
	const participantsResource = useResource(sessionId ? endpoint : null);
	const isLoading = isSubmitting || participantsResource.isLoading;
	return {
		isLoading,
		error: participantsResource.error,
		participants: participantsResource.data && participantsResource.data.participants,
		removeParticipant: async (id) => {
			try {
				setIsSubmitting(true);
				await del(`${endpoint}/${id}`);
				participantsResource.mutate();
			} finally {
				setIsSubmitting(false);
			}
		},
		addParticipantByEmail: async (email) => {
			try {
				setIsSubmitting(true);
				await post(endpoint, { email });
				participantsResource.mutate();
			} finally {
				setIsSubmitting(false);
			}
		},
		makeParticipantEditor: async (participant_id, session_id) => {
			try {
				setIsSubmitting(true);
				const endpoint = `/sessions/make-editor/${session_id}/${participant_id}`;
				await put(endpoint, { participant_id, session_id });
				participantsResource.mutate();
			} finally {
				setIsSubmitting(false);
			}
		},
		removeParticipantEditor: async (participant_id, session_id) => {
			try {
				setIsSubmitting(true);
				const endpoint = `/sessions/remove-editor/${session_id}/${participant_id}`;
				await put(endpoint, { participant_id, session_id });
				participantsResource.mutate();
			} finally {
				setIsSubmitting(false);
			}
		},
	};
};

export const getPublicSessionLink = (sessionId) => {
	return `${window.location.origin}/sessions/${sessionId}/agenda`;
};
