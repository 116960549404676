import React from 'react';
import { useCallback } from 'react';

import { useSessionParticipants } from '~/src/data/sessions';
import { ActionLink } from '~/src/lib/Buttons';
import { LineField } from '~/src/lib/LineField';
import {
	OverlayEditor,
	OverlayEditorHeader,
	OverlayEditorContent,
	OverlayEditorFooter,
} from '~/src/lib/OverlayEditor';

import * as css from './ParticipantsEditor.css';

export const ParticipantsEditor = ({ onDone: handleDone, session }) => {
	const {
		participants,
		addParticipantByEmail,
		removeParticipant,
		makeParticipantEditor,
		removeParticipantEditor,
	} = useSessionParticipants(session.id);

	const inviteParticipant = async (email) => {
		await addParticipantByEmail(email);
	};
	const resendInvite = async (email) => {
		await addParticipantByEmail(email);
	};
	const makeParticipantAsEditor = async (participant_id) => {
		await makeParticipantEditor(participant_id, session.id);
	};
	const removeParticipantAsEditor = async (participant_id) => {
		await removeParticipantEditor(participant_id, session.id);
	};
	const makeSessionPublic = async () => {
		session.update({ isPublic: true });
	};
	if (!session) {
		// TODO: Loader
		return null;
	}
	return (
		<OverlayEditor className={css.ParticipantsEditor}>
			<OverlayEditorHeader
				className={css.ParticipantsEditor__header}
				title="Participants"
				itemType="Participants"
			></OverlayEditorHeader>
			<OverlayEditorContent>
				{participants
					? participants.map((participant) => (
							<Participant
								key={participant.id}
								participant={participant}
								resendInvite={resendInvite}
								makeParticipantEditor={makeParticipantAsEditor}
								removeParticipantEditor={removeParticipantAsEditor}
								removeParticipant={() => removeParticipant(participant.id)}
							/>
					  ))
					: null}
			</OverlayEditorContent>
			<OverlayEditorFooter
				customButton={
					<CopyLinkButton session={session} makeSessionPublic={makeSessionPublic} />
				}
				itemType="Participants"
				onDone={handleDone}
			>
				<h2 className={css.ParticipantsEditor__sectionTitle}>Invite participants</h2>
				<LineField
					placeholder="Type email..."
					onSubmit={inviteParticipant}
					track={['Invite participant', { where: 'Participants Editor' }]}
				/>
			</OverlayEditorFooter>
		</OverlayEditor>
	);
};

const CopyLinkButton = ({ session, makeSessionPublic }) => {
	return session.isPublic ? (
		<ActionLink
			icon="link"
			onClick={session.copyInviteLink}
			track={['Copy invite link', { where: 'Participants Editor' }]}
		>
			Copy link
		</ActionLink>
	) : (
		<ActionLink
			onClick={makeSessionPublic}
			track={['Make Session Public', { where: 'Participants Editor' }]}
		>
			Make session public
		</ActionLink>
	);
};

const Participant = ({
	participant,
	resendInvite,
	removeParticipant,
	makeParticipantEditor,
	removeParticipantEditor,
}) => {
	return (
		<article className={css.Participant}>
			<div className={css.Participant__thumbnail}></div>
			<div className={css.Participant__title}>
				<span className={css.Participant__name}>
					{participant.hasAccepted ? participant.name : participant.email}
				</span>
				{participant.isOwner ? (
					<span className={css.Participant__isOwner}>Owner</span>
				) : null}
				{participant.isEditor ? (
					<span className={css.Participant__isOwner}>Editor</span>
				) : null}
			</div>
			<div className={css.Participant__email}>
				{participant.hasAccepted ? participant.email : 'Invited'}
			</div>
			<div className={css.Participant__actions}>
				{!participant.isOwner && !participant.isEditor && (
					<ActionLink
						onClick={() => makeParticipantEditor(participant.id)}
						track={['Make Editor', { where: 'Participants Editor' }]}
					>
						Make editor
					</ActionLink>
				)}
				{!participant.isOwner && participant.isEditor && (
					<ActionLink
						onClick={() => removeParticipantEditor(participant.id)}
						track={['Remove Editor', { where: 'Participants Editor' }]}
					>
						Remove editor
					</ActionLink>
				)}
				{!participant.isOwner && !participant.hasAccepted && (
					<ActionLink
						onClick={() => resendInvite(participant.id)}
						track={['Resend invite', { where: 'Participants Editor' }]}
					>
						Resend invite
					</ActionLink>
				)}
				{!participant.isOwner && (
					<ActionLink
						onClick={() => removeParticipant(participant.id)}
						track={['Remove participant', { where: 'Participants Editor' }]}
					>
						Remove
					</ActionLink>
				)}
			</div>
		</article>
	);
};
