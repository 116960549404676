import React from 'react';
import { motion } from 'framer-motion';

import { useLivePlayState } from '~/src/data/playState';
import { useLayout } from '~/src/utils/useLayout';

import * as css from './TimelinePlayhead.css';

export const TimelinePlayhead = ({ session }) => {
	const layout = useLayout();
	const playState = useLivePlayState(session);
	return (
		<motion.div
			initial={{
				...layout.getPlayheadStyle(playState.getSectionRelativeTime()),
				opacity: 0,
			}}
			style={{
				pointerEvents: playState.isPlaying ? 'all' : 'none',
				...layout.getPlayheadStaticStyle(),
			}}
			transition={{ type: 'spring', bounce: 0, duration: 0.5 }}
			animate={{
				opacity: 1,
				...layout.getPlayheadStyle(playState.getSectionRelativeTime()),
			}}
			className={css.TimelinePlayhead}
		>
			<div className={css.TimelinePlayhead__bar} />
		</motion.div>
	);
};
