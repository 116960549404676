const MAX_SIZE = 25;

const UNDO_STACK = [];
const REDO_STACK = [];
let justAdded = true;

export function addToUndoStack(data) {
	// We store a stringified version so that
	// we lose all references. We could do a
	// deep clone instead, but this is simpler.
	UNDO_STACK.push(data);
	justAdded = 'UNDO';
	if (UNDO_STACK.length > MAX_SIZE) {
		const count = UNDO_STACK.length - MAX_SIZE;
		UNDO_STACK.splice(0, count);
	}
}

export function goBack() {
	if (justAdded === 'UNDO') {
		addToRedoStack(UNDO_STACK.pop());
		justAdded = null;
	}
	const data = UNDO_STACK.pop();
	if (!data) {
		return null;
	}
	return addToRedoStack(data);
}

function addToRedoStack(data) {
	REDO_STACK.push(data);
	justAdded = 'REDO';
	return data;
}

export function goForward() {
	if (justAdded === 'REDO') {
		addToUndoStack(REDO_STACK.pop());
		justAdded = null;
	}
	const data = REDO_STACK.pop();
	if (!data) {
		return null;
	}
	addToUndoStack(data);
	return data;
}
