import Tooltip from '@mui/material/Tooltip';
import React, { useState, useRef, useEffect } from 'react';

import * as css from './InlineEdit.css';

export const InlineEdit = ({
	privileges,
	initialValue,
	onSubmit,
	prefix = '',
	suffix = '',
	inlineTextLength = 25,
}) => {
	const ref = useRef();
	const [isEditing, setIsEditing] = useState(false);
	const handleClick = () => {
		setIsEditing(true);
	};
	useEffect(() => {
		if (!isEditing) {
			return;
		}
		ref.current.focus();
		const handleBlur = () => {
			onSubmit(ref.current.innerText);
			setIsEditing(false);
		};
		const handleKeydown = (event) => {
			event.stopPropagation();
			if (event.which === 13) {
				event.preventDefault();
				setIsEditing(false);
			}
		};
		const boundElement = ref.current;
		boundElement.addEventListener('blur', handleBlur);
		boundElement.addEventListener('keydown', handleKeydown);
		return () => {
			boundElement.removeEventListener('blur', handleBlur);
			boundElement.removeEventListener('keydown', handleKeydown);
		};
	}, [isEditing, onSubmit]);
	return (
		<span className={css.InlineEdit} onClick={privileges.canEdit ? handleClick : null}>
			{prefix}
			<Tooltip title={initialValue}>
				<span
					ref={ref}
					className={css.InlineEdit__field}
					contentEditable={isEditing}
					dangerouslySetInnerHTML={{
						__html:
							!isEditing && initialValue.length > inlineTextLength
								? `${initialValue.substring(0, inlineTextLength)}...`
								: initialValue,
					}}
				/>
			</Tooltip>
			{suffix}
		</span>
	);
};
