.ErrorBox {
	background: #1d1166;
	color: white;
	border-radius: 5px;
	padding: 10px 15px;
}
.ErrorBox__message {
	font-weight: bold;
}
.ErrorBox__code {
	font-weight: bold;
}
.ErrorBox__actions {
	font-weight: bold;
	margin-top: 10px;
	font-size: 18px;
	text-decoration: underline;
}
