.SectionSeparator {
	width: 140px;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.SectionSeparator__ruler {
	width: 1px;
	flex: 1;
	background: var(--divider-color);
	max-height: 100px;
}
.SectionSeparator__content {
	margin: 20px 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	row-gap: 15px;
}
.SectionSeparator__label {
	font: var(--label-medium);
}
.SectionSeparator__nextButton {
	width: 48px;
	height: 48px;
}
