import React, { Fragment, useCallback, useState } from 'react';

import { Button, RoundButton } from '~/src/lib/Buttons';
import { track } from '~/src/utils/analytics';
import {
	OverlayEditor,
	OverlayEditorHeader,
	OverlayEditorContent,
} from '~/src/lib/OverlayEditor';
import { TextArea } from '~/src/lib/Form/TextArea';
import { TextInput } from '~/src/lib/Form/TextInput';
import { Label } from '~/src/lib/Form/Label';
import { Loader } from '~/src/lib/Layout';
import { post } from '~/src/data/api';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { generateId } from '~/src/utils/generateId';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { validateDurationFormat } from '../../utils/format';

import * as Yup from 'yup';
import * as css from './SessionBuilder.css';
import { OverlayEditorFooter } from '../OverlayEditor';

const sessionBuilderFormSchema = Yup.object().shape({
	title: Yup.string(),
	speakers: Yup.number(),
});

export const SessionBuilder = () => {
	const history = useHistory();
	const [sections, setSections] = useState([]);
	const [newSectionTitle, setNewSectionTitle] = useState('');
	const [newSectionDuration, setNewSectionDuration] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState({});
	const { addToast } = useToasts();
	const { register, handleSubmit } = useForm({
		resolver: yupResolver(sessionBuilderFormSchema),
		defaultValues: {
			title: '',
			speakers: 1,
		},
	});

	const handleCreateSession = useCallback(
		async (body) => {
			body = { ...body, sections: sections };
			setIsLoading(true);
			post('/sessions/builder', body)
				.then((data) => {
					setIsLoading(false);
					history.replace(`/sessions/${data.newSessionId}`);
				})
				.catch((err) => {
					console.log(err);
					setIsLoading(false);
					addToast(`Something went wrong - ${err.message}`, {
						appearance: 'error',
						autoDismiss: true,
						autoDismissTimeout: 3000,
					});
					history.push('/home');
				});
		},
		[addToast, history, sections],
	);

	const removeSection = useCallback((sectionId) => {
		setSections((prevState) => prevState.filter((section) => section.id != sectionId));
	}, []);

	const resetSectionInputs = useCallback(() => {
		setNewSectionTitle('');
		setNewSectionDuration('');
	}, []);

	const handleTimeErrorMessage = useCallback(() => {
		setError({ time: { message: 'Invalid Time' } });
	}, []);

	const newSectionValues = useCallback(() => {
		let data = { id: generateId(), title: 'Blank section', duration: '05:00' };
		if (newSectionTitle) {
			data = Object.assign(data, { title: newSectionTitle });
		}
		if (newSectionDuration) {
			data = Object.assign(data, { duration: newSectionDuration });
		}
		return data;
	}, [newSectionDuration, newSectionTitle]);

	const addNewSectionToSectionsList = useCallback(() => {
		let sectionsList = sections;
		sectionsList.push(newSectionValues());
		setSections([...sectionsList]);
	}, [newSectionValues, sections]);

	const addSection = useCallback(() => {
		if (newSectionDuration != '' && !validateDurationFormat(newSectionDuration)) {
			handleTimeErrorMessage();
			return;
		}
		track('Add section to list', { how: 'Add button' });
		addNewSectionToSectionsList();
		resetSectionInputs();
	}, [
		addNewSectionToSectionsList,
		handleTimeErrorMessage,
		newSectionDuration,
		resetSectionInputs,
	]);

	const skipSessionBuilder = useCallback(() => {
		history.replace('sessions/new');
	}, [history]);

	const handleDurationInputChange = (e) => {
		setError({});
		setNewSectionDuration(e.target.value);
	};

	const handleSectionTitleInputChange = (e) => {
		setNewSectionTitle(e.target.value);
	};
	return (
		<Fragment>
			<OverlayEditor className={css.SessionBuilder}>
				<OverlayEditorHeader title="Session Builder"></OverlayEditorHeader>
				<OverlayEditorContent>
					<Label>Name your session</Label>
					<TextArea
						placeholder="e.g. Q2 Team Leadership Townhall / Communication Workshop / Xmas Party Election / Autumn Houseshare Budget"
						name="title"
						{...register('title')}
					/>
					<Label>Number of speakers participating</Label>
					<TextInput
						type="number"
						min="0"
						max="30"
						placeholder="Speakers amount"
						{...register('speakers')}
					/>
					<Sections
						isLoading={isLoading}
						handleSubmit={handleSubmit}
						skipSessionBuilder={skipSessionBuilder}
						handleSectionTitleInputChange={handleSectionTitleInputChange}
						newSectionTitle={newSectionTitle}
						handleDurationInputChange={handleDurationInputChange}
						newSectionDuration={newSectionDuration}
						addSection={addSection}
						sections={sections}
						removeSection={removeSection}
						error={error}
					/>
				</OverlayEditorContent>
				<OverlayEditorFooter
					onDone={handleSubmit(handleCreateSession)}
					btnText="Done"
					itemType="Session"
					onClickAction={skipSessionBuilder}
					onClickText="Skip"
				/>
			</OverlayEditor>
		</Fragment>
	);
};

const Sections = ({
	isLoading,
	handleSectionTitleInputChange,
	newSectionTitle,
	handleDurationInputChange,
	newSectionDuration,
	addSection,
	sections,
	removeSection,
	error,
}) => (
	<Fragment>
		<h3 className={css.SessionBuilder__sectionTitle}>Sections</h3>
		<Label>Name the sections which will make up your session</Label>
		<TextInput
			placeholder="e.g. Introductions / Opening statements / Recap / Discussion / Next steps"
			onChange={handleSectionTitleInputChange}
			value={newSectionTitle}
			track={['Insert section title', { where: 'Section title' }]}
		/>
		<Label>Duration</Label>
		<TextInput
			placeholder="05:00"
			mask="time"
			error={error.time}
			value={newSectionDuration}
			onChange={handleDurationInputChange}
			track={['Insert section duration', { where: 'Section duration' }]}
		/>
		<Button shape="square" className={css.SessionBuilder__addButton} onClick={addSection}>
			Add
		</Button>
		{sections.length > 0 && (
			<table className={css.Table}>
				<thead>
					<tr className={css.Table__row}>
						<th className={css.Table__head}>Title</th>
						<th className={css.Table__head}>Duration</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					{sections.map((section) => (
						<tr key={section.id} className={css.Table__row}>
							<td className={css.Table__row_data}>{section.title}</td>
							<td className={css.Table__row_data}>{section.duration}</td>
							<td className={css.Table__row_data}>
								<RoundButton
									icon="remove"
									onClick={() => removeSection(section.id)}
									track={['remove Section', { where: 'Section Builder remove button' }]}
								/>
							</td>
						</tr>
					))}
				</tbody>
			</table>
		)}
		{isLoading && (
			<OverlayEditorContent className={css.SessionBuilder__loading}>
				<Loader>
					<div>Creating new session...</div>
				</Loader>
			</OverlayEditorContent>
		)}
	</Fragment>
);
