import React from 'react';
import classnames from 'classnames';
import { motion } from 'framer-motion';
import { useHistory } from 'react-router-dom';

import { track } from '~/src/utils/analytics';
import { durationToMinutes } from '~/src/utils/format';
import { pluralize } from '~/src/utils/pluralize';
import { ActionLink, Link } from '~/src/lib/Buttons';
import { post, put } from '../data/api';

import * as css from './SessionList.css';
import { useSessions } from '../data/sessions';
import { useToasts } from 'react-toast-notifications';

const TOAST_DURATION = 3000;

export const SessionList = ({ sessions, variant, templates = false }) => {
	const { refresh } = useSessions();
	const { addToast } = useToasts();
	const history = useHistory();

	const handleArchive = async (session) => {
		put(`/sessions/${session.id}`, { archivedAt: new Date() })
			.then(() => {
				refresh();
				addToast(`${session.title} was successfully archived.`, {
					appearance: 'success',
					autoDismiss: true,
					autoDismissTimeout: TOAST_DURATION,
				});
			})
			.catch((error) =>
				addToast(error.message, {
					appearance: 'error',
					autoDismiss: true,
					autoDismissTimeout: TOAST_DURATION,
				}),
			);
	};
	const handleDuplicate = async (session) => {
		post(`/sessions/${session.id}/duplicate`)
			.then((data) => {
				addToast(`${session.title} was successfully duplicated.`, {
					appearance: 'success',
					autoDismiss: true,
					autoDismissTimeout: TOAST_DURATION,
				});
				history.replace(`sessions/${data.newSessionId}`);
			})
			.catch((error) =>
				addToast(error.message, {
					appearance: 'error',
					autoDismiss: true,
					autoDismissTimeout: TOAST_DURATION,
				}),
			);
	};
	return (
		<div className={classnames(css.SessionList, css[`SessionList--${variant}`])}>
			{templates &&
				sessions
					.splice(0, 3)
					.map((session) => (
						<SessionListItem
							key={session.id}
							session={session}
							handleArchive={handleArchive}
							handleDuplicate={handleDuplicate}
							variant={variant}
						/>
					))}
			{!templates &&
				sessions.map((session) => (
					<SessionListItem
						key={session.id}
						session={session}
						handleArchive={handleArchive}
						handleDuplicate={handleDuplicate}
						variant={variant}
					/>
				))}
		</div>
	);
};

export const SessionListItem = ({
	session,
	handleArchive,
	handleDuplicate,
	variant = 'preview',
}) => {
	const isFull = variant === 'full';
	const LinkComponent = isFull ? 'div' : Link;
	const history = useHistory();
	const handleClick = () => {
		if (isFull) {
			track('View Session', { where: 'Full Session List' });
			history.push(`/sessions/${session.id}`);
		}
	};
	return (
		<motion.article
			className={classnames(css.SessionListItem, css[`SessionListItem--${variant}`])}
		>
			<LinkComponent
				to={isFull ? null : `/sessions/${session.id}`}
				className={css.SessionListItem__link}
				track={isFull ? null : ['View Session', { where: 'Session List' }]}
				onClick={handleClick}
			>
				<h3 className={css.SessionListItem__title}>
					{session.title || 'Unnamed session'}
				</h3>
				<p className={css.SessionListItem__info}>{getSessionInfoText(session)}</p>
				{isFull && (
					<Actions
						session={session}
						handleArchive={handleArchive}
						handleDuplicate={handleDuplicate}
					>
						Actions
					</Actions>
				)}
			</LinkComponent>
		</motion.article>
	);
};

const Actions = ({ session, handleArchive, handleDuplicate }) => {
	const handleArchiveClick = () => {
		handleArchive(session);
	};
	const handleDuplicateClick = () => {
		handleDuplicate(session);
	};
	return (
		<div className={css.SessionListItem__actions}>
			<ActionLink
				className={css.SessionListItem__actionItem}
				to={`/sessions/${session.id}/participants`}
				icon="people"
				track={['View Participants', { where: 'Full Session List' }]}
			>
				{session.participantCount}{' '}
				{pluralize(session.participantCount, 'participant', 'participants')}
			</ActionLink>
			<ActionLink
				className={css.SessionListItem__actionItem}
				onClick={handleDuplicateClick}
				icon="duplicate"
				track={['Duplicate Session', { where: 'Full Session List' }]}
			>
				Duplicate
			</ActionLink>
			<ActionLink
				className={css.SessionListItem__actionItem}
				onClick={handleArchiveClick}
				icon="archive"
				track={['Archive Session', { where: 'Full Session List' }]}
			>
				Archive
			</ActionLink>
			<ActionLink
				className={css.SessionListItem__actionItem}
				to={`/sessions/${session.id}`}
				icon="view"
				track={['View Session', { where: 'Full Session List' }]}
			>
				View
			</ActionLink>
		</div>
	);
};

const getSessionInfoText = (session) => {
	const duration = durationToMinutes(session.duration);
	return [
		`${duration} ${pluralize(duration, 'minute', 'minutes')}`,
		`${session.sectionCount} ${pluralize(session.sectionCount, 'section', 'sections')}`,
		`${session.participantCount} ${pluralize(
			session.participantCount,
			'participant',
			'participants',
		)}`,
	].join(', ');
};
