.OverlayCardWrap {
	position: fixed;
	background: rgba(255, 255, 255, 0.75);
	border: 1px solid var(--border-color);
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	z-index: var(--z-overlay-editor);
	--pad-x: 30px;
	--pad-y: 35px;
}

.OverlayCard {
	--background-color: var(--white);
	background: var(--background-color);
	z-index: calc(var(--z-app-header) + 5);
	display: flex;
	align-items: start;
	justify-content: center;
	box-shadow: 0 1px 50px rgba(0, 0, 0, 0.2);
	padding: 0 0;
	width: 100%;
	margin-top: 5vh;
	max-width: 70vw;
	max-height: 90vh;
	border-radius: 10px;
	background: var(--white);
	border: 1px solid var(--gray);
}
.OverlayCard__content {
	flex: 1;
	overflow: hidden;
}

@media (max-width: 800px) {
	.OverlayCard {
		margin-top: 0;
		margin-bottom: 0;
		max-width: 100vw;
		height: 100vh;
		max-height: 100vh;
	}
}
@media (max-height: 500px) {
	.OverlayCard {
		margin-top: 0;
		height: 100vh;
		max-height: 100vh;
	}
}
