import { createContext, useContext, useState } from 'react';

export const UIContext = createContext();

export const useUIProvider = () => {
	const [ui, setUI] = useState({ appHeaderVisible: true });
	return {
		...ui,
		toggleHeader: () => {
			setUI({ ...ui, appHeaderVisible: !ui.appHeaderVisible });
		},
	};
};

export const useUI = () => {
	return useContext(UIContext);
};
