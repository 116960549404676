import React from 'react';
import { motion } from 'framer-motion';

import * as css from './OverlayCard.css';

export const OverlayCard = ({ children, onBackgroundClick, style }) => {
	const handleBackgroundClick = (event) => {
		if (event.currentTarget === event.target) {
			onBackgroundClick();
		}
	};
	// TODO: Implement an error boundary
	return (
		<motion.div
			onMouseDown={handleBackgroundClick}
			exit="hide"
			initial="hide"
			animate={children ? 'show' : 'hide'}
			transition={{ bounce: 0, duration: 0.1 }}
			variants={{
				show: { opacity: 1, pointerEvents: 'all' },
				hide: { opacity: 0, pointerEvents: 'none' },
			}}
			className={css.OverlayCardWrap}
		>
			<motion.div
				className={css.OverlayCard}
				exit="hide"
				initial="hide"
				style={style}
				animate={children ? 'show' : 'hide'}
				transition={{ bounce: 0, duration: 0.1 }}
				variants={{
					show: { opacity: 1 },
					hide: { opacity: 0 },
				}}
			>
				<div className={css.OverlayCard__content}>{children}</div>
			</motion.div>
		</motion.div>
	);
};
