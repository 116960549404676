import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';

import { track } from '~/src/utils/analytics';
import { useSessionPrivileges } from '~/src/data/sessions';
import { ZoomContext, useZoomProvider } from '~/src/utils/useZoom';
import { usePlayState } from '~/src/data/playState';
import { OverlayCard } from '~/src/lib/OverlayCard';

import { SectionEditor } from './SectionEditor';
import { SlotEditor } from './SlotEditor';
import { ParticipantsEditor } from './ParticipantsEditor';
import { SessionDetailsEditor } from './SessionDetailsEditor';
import { SessionFooter } from './SessionFooter';
import { SessionTimeline } from './SessionTimeline';
import { SessionSection, BlankSection } from './SessionSection';
import { SectionSeparator } from './SectionSeparator';
import { SessionDetails } from './SessionDetails';
import {
	OverlayEditor,
	OverlayEditorHeader,
	OverlayEditorContent,
} from '~/src/lib/OverlayEditor';
import * as css from './Session.css';

const HIDE_SHOW = { hide: { opacity: 0 }, show: { opacity: 1 } };

export const Session = (props) => {
	const zoom = useZoomProvider();
	return (
		<ZoomContext.Provider value={zoom}>
			<SessionInner {...props} />
		</ZoomContext.Provider>
	);
};

const SessionInner = ({ session, page, itemId }) => {
	const history = useHistory();
	const privileges = useSessionPrivileges(session);
	const playState = usePlayState(session);
	const [isSessionDetailsModalOpen, setIsSessionDetailsModalOpen] = useState(false);
	const [screenWidth, setScreenWidth] = useState(window.innerWidth);

	useEffect(() => {
		if (history.location.pathname.includes('agenda')) {
			setIsSessionDetailsModalOpen(true);
		}
	}, [history]);

	window.addEventListener('resize', () => {
		setScreenWidth(window.innerWidth);
	});

	const handleSessionDetailsModal = useCallback(() => {
		setIsSessionDetailsModalOpen((prevState) => !prevState);
	}, []);

	const closeOverlay = () => {
		playState.setSession(session);
		history.push(`/sessions/${session.id}`);
	};
	const addSection = () => {
		track('Add Section');
		session.createSection();
		playState.setSession(session);
	};
	const Editor = getEditor(page);
	return (
		<motion.section
			className={css.Session}
			variants={HIDE_SHOW}
			initial="hide"
			animate="show"
		>
			<SessionTimeline session={session}>
				{session.sections.map((section, index) => (
					<Fragment key={section.id}>
						<SessionSection
							session={session}
							section={section}
							selectedSlotId={page === 'slots' && itemId}
						/>
						{index !== session.sections.length - 1 ? (
							<SectionSeparator
								session={session}
								onNextClick={() => playState.goToSection(index + 1)}
							>
								SECTION SEPARATOR
							</SectionSeparator>
						) : null}
					</Fragment>
				))}
				{privileges.canEdit && <BlankSection session={session} onClick={addSection} />}
			</SessionTimeline>
			<SessionFooter session={session}></SessionFooter>
			<AnimatePresence>
				{Editor ? (
					<OverlayCard onBackgroundClick={closeOverlay}>
						<Editor itemId={itemId} session={session} onDone={closeOverlay} />
					</OverlayCard>
				) : null}
			</AnimatePresence>
			{screenWidth < 715 && <DialogLandscapeMode />}
			{isSessionDetailsModalOpen && (
				<DialogSessionDetails
					handleModalDisplay={handleSessionDetailsModal}
					session={session}
				></DialogSessionDetails>
			)}
		</motion.section>
	);
};

const DialogLandscapeMode = () => {
	return (
		<AnimatePresence>
			<OverlayCard>
				<OverlayEditor>
					<OverlayEditorHeader
						title="Hey"
						itemType="Landscape Warning"
						align="center"
					></OverlayEditorHeader>
					<OverlayEditorContent>
						<h1>
							For a better experience we recommend enjoying the session in landscape mode
						</h1>
					</OverlayEditorContent>
				</OverlayEditor>
			</OverlayCard>
		</AnimatePresence>
	);
};

const DialogSessionDetails = ({ session, handleModalDisplay }) => {
	return (
		<AnimatePresence>
			<OverlayCard style={{ width: '600px' }}>
				<SessionDetails
					handleModal={handleModalDisplay}
					session={session}
				></SessionDetails>
			</OverlayCard>
		</AnimatePresence>
	);
};

const getEditor = (page) => {
	switch (page) {
		case 'sections':
			return SectionEditor;
		case 'slots':
			return SlotEditor;
		case 'participants':
			return ParticipantsEditor;
		case 'details':
			return SessionDetailsEditor;
		default:
			return null;
	}
};
