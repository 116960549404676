import React, { useMemo } from 'react';
import { motion } from 'framer-motion';

import { useSessions } from '~/src/data/sessions';
import { Main } from '~/src/lib/Layout';
import { Link, Button } from '~/src/lib/Buttons';
import { SessionList } from '~/src/lib/SessionList';

import * as css from './ManageSessionsPage.css';
import { useHistory } from 'react-router-dom';

const HIDE_SHOW = { hide: { opacity: 0 }, show: { opacity: 1 } };

export const ManageSessionsPage = () => {
	const history = useHistory();
	const sessions = useSessions();
	if (!sessions.data.length) {
		history.replace('/home');
	}
	const sessionList = useMemo(
		() => sessions.data.filter((session) => !session.isDemo),
		[sessions],
	);
	return (
		<Main className={css.ManageSessionsPage}>
			<motion.div
				className={css.ManageSessionsPage__sessions}
				variants={HIDE_SHOW}
				initial="hide"
				animate="show"
				transition={{
					staggerChildren: 0.1,
				}}
			>
				<Link
					to="/home"
					variant="back"
					className={css.ManageSessionsPage__goBack}
					track={['Go Back', { where: 'Manage Sessions page' }]}
				>
					Back
				</Link>
				<h2 className={css.ManageSessionsPage__title}>Your sessions</h2>
				{!sessions.data || sessions.error ? (
					sessions.error ? (
						<div>
							<div>Error: {sessions.error.message}</div>
							<Button to="/home" track={['Go Home', { where: 'Manage Sessions page' }]}>
								Go home
							</Button>
						</div>
					) : (
						<div>Loading...</div>
					)
				) : (
					<SessionList variant="full" sessions={sessionList} />
				)}
			</motion.div>
		</Main>
	);
};
