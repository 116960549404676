.SessionBuilder {
	flex: 1;
}

.SessionBuilder__header {
	flex: 1;
}

.SessionBuilder__sectionTitle{
	margin: 8px 8px 20px 8px;
}

.SessionBuilder__addButton{
	float: right;
}

.SessionBuilder__loading{
	text-align: center;
}

.Table {
	width: 100%;
	padding-bottom: 10px;
	padding-left: 5px;
	table-layout:fixed;
}

.Table__row {
	height: 40px;
	word-wrap:break-word;
}

.Table__head {
	text-align: center;
}
.Table__head:nth-child(1) {
	text-align: start;
}

.Table__row_data {
	text-align: center;
}

.Table__row_data:nth-child(1) {
	text-align: start;
}

.Table__row_data:nth-child(3) {
	text-align: end;
}

