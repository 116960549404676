import useSWR, { useSWRConfig } from 'swr';

export const USER_TOKEN_KEY = 'user-token';
const USER_CACHE_KEY = 'user-data';

export function setAccessToken(token) {
	localStorage.setItem(USER_TOKEN_KEY, token);
}

export function hasAccessToken() {
	return !!localStorage.getItem(USER_TOKEN_KEY);
}

export function clearAccessToken() {
	return localStorage.removeItem(USER_TOKEN_KEY);
}

export function getCachedUserData() {
	return JSON.parse(localStorage.getItem(USER_CACHE_KEY) || 'null');
}

export function setCachedUserData(data) {
	localStorage.setItem(USER_CACHE_KEY, JSON.stringify(data));
}

export function clearCachedUserData() {
	localStorage.removeItem(USER_CACHE_KEY);
}

async function request(endpoint, options = {}) {
	const accessToken = localStorage.getItem(USER_TOKEN_KEY);
	options.headers = options.headers || {};
	options.mode = 'cors';
	if (typeof options.body === 'object') {
		options.body = JSON.stringify(options.body);
		options.headers['Content-Type'] = 'application/json';
	}
	if (accessToken) {
		options.headers['Authorization'] = `Bearer ${accessToken}`;
	}
	const finalEndpoint = `${process.env.API_ORIGIN}${endpoint}`;
	const response = await fetch(finalEndpoint, options);
	if (response.ok) {
		const data = await response.json();
		// console.info('API Response:', endpoint, response.status, options, data);
		return data;
	}
	console.warn('Error from API', response);
	// Try loading error message from API
	let data;
	try {
		data = await response.json();
	} catch (parseError) {
		console.warn('Unable to parse API response as JSON');
	}
	const message = (data && data.message) || response.statusText;
	const error = new Error(` ${message}`);
	error.code = response.status;
	throw error;
}

export async function get(endpoint) {
	return await request(endpoint);
}
export async function post(endpoint, body = {}) {
	return await request(endpoint, {
		method: 'POST',
		body,
	});
}
export async function put(endpoint, body = {}) {
	return await request(endpoint, {
		method: 'PUT',
		body,
	});
}
export async function del(endpoint) {
	return await request(endpoint, {
		method: 'DELETE',
	});
}

export function useResource(url, options = {}) {
	const { error, data, mutate } = useSWR(url, get, {
		revalidateOnFocus: false,
		shouldRetryOnError: false,
		...options,
	});
	return {
		isLoading: !error && !data,
		error: error || null,
		data,
		mutate,
	};
}

export function useRefresher(url) {
	const { mutate } = useSWRConfig();
	return (newUrl) => mutate(newUrl || url);
}
