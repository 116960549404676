import React, { useState } from 'react';

import { track } from '~/src/utils/analytics';
import { formatDigitalClock } from '~/src/utils/format';
import { parseDigitalClock } from '~/src/utils/parse';
import {
	OverlayEditor,
	OverlayEditorHeader,
	OverlayEditorContent,
} from '~/src/lib/OverlayEditor';
import { TextArea } from '~/src/lib/Form/TextArea';
import { TextInput } from '~/src/lib/Form/TextInput';
import { Label } from '~/src/lib/Form/Label';
import { validateDurationFormat } from '../../utils/format';

import * as css from './SectionEditor.css';
import { OverlayEditorFooter } from '../OverlayEditor';

export const SectionEditor = ({ onDone, session, itemId }) => {
	const section = session.getSection(itemId);
	const [title, setTitle] = useState(section?.title);
	const [description, setDescription] = useState(section?.description);
	const [duration, setDuration] = useState(formatDigitalClock(section?.duration));
	const [error, setError] = useState({});
	const handleDescriptionChange = (event) => {
		setDescription(event.target.value);
	};
	const handleDurationChange = (event) => {
		setError({});
		setDuration(event.target.value);
	};
	const handleTitleChange = (event) => {
		setTitle(event.target.value);
	};
	const handleOnDone = () => {
		if (!validateDurationFormat(duration)) {
			setError({ time: { message: 'Invalid Time' } });
			return;
		}

		if (title !== section?.title) {
			track('Set Section Title');
		}
		if (description !== section?.description) {
			track('Set Section Description');
		}
		if (duration !== section?.duration) {
			track('Set Section Duration');
		}
		session.updateSection(itemId, {
			title,
			description,
			duration: parseDigitalClock(duration),
		});
		onDone();
	};

	const handleKeydown = (event) => {
		if (event.which === 13 && !event.shiftKey) {
			track('Done Editing Section', { how: 'Enter key' });
			event.preventDefault();
			handleOnDone();
		}
	};
	return (
		<OverlayEditor className={css.SectionEditor}>
			<OverlayEditorHeader title="Edit section" itemType="Section"></OverlayEditorHeader>
			<OverlayEditorContent>
				<Label>Title</Label>
				<TextArea onChange={handleTitleChange} onKeyDown={handleKeydown} value={title} />
				<Label>Duration</Label>
				<TextInput
					mask="time"
					onChange={handleDurationChange}
					onKeyDown={handleKeydown}
					error={error.time}
					value={duration}
				/>

				<Label>Description</Label>
				<TextArea
					onChange={handleDescriptionChange}
					onKeyDown={handleKeydown}
					value={description}
				/>
			</OverlayEditorContent>
			<OverlayEditorFooter onDone={handleOnDone} itemType="Section" />
		</OverlayEditor>
	);
};
