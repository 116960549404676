import React, { useState, useRef } from 'react';

import { reportError } from '~/src/utils/errors';
import { ErrorBox } from '~/src/lib/ErrorBox';
import { ActionLink } from '~/src/lib/Buttons';

import * as css from './LineField.css';

export const LineField = ({ onSubmit, placeholder, text, defaultValue = '', track }) => {
	const [error, setError] = useState(null);
	const element = useRef();
	const handleSubmit = async (event) => {
		event.preventDefault();
		const value = element.current.value;
		try {
			setError(null);
			await onSubmit(value);
			// Clear field on successful submit
			if (element.current) {
				element.current.value = '';
			}
		} catch (error) {
			reportError(error);
			setError(error);
		}
	};
	return (
		<form className={css.LineField} onSubmit={handleSubmit}>
			<input
				ref={element}
				placeholder={placeholder}
				defaultValue={defaultValue}
				className={css.LineField__input}
			/>
			<ActionLink
				type="submit"
				onClick={handleSubmit}
				className={css.LineField__submit}
				track={track}
			>
				{text ? text : 'Invite'}
			</ActionLink>
			{error && (
				<div className={css.LineField__error}>
					<ErrorBox error={error} />
				</div>
			)}
		</form>
	);
};
