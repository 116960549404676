import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { AnimateSharedLayout } from 'framer-motion';

import './global.css';

import { HomePage } from '~/src/pages/HomePage';
import { InternalPage } from '~/src/pages/InternalPage';
import { LoginPage } from '~/src/pages/LoginPage';
import { ForgotPasswordPage } from '~/src/pages/ForgotPasswordPage';
import { LogoutPage } from '~/src/pages/LogoutPage';
import { SignupPage } from '~/src/pages/SignupPage';
import { SessionPage } from '~/src/pages/SessionPage';
import { ManageSessionsPage } from '~/src/pages/ManageSessionsPage';
import { UIContext, useUIProvider } from '~/src/utils/useUI';
import { ToastProvider } from 'react-toast-notifications';
import { UsersListPage } from '~/src/pages/UsersListPage';
import { WaitingApprovalPage } from '~/src/pages/WaitingApprovalPage';
import { WaitingRedefineApprovalPage } from '~/src/pages/WaitingRedefineApprovalPage';
import { LandingPage } from '~/src/pages/LandingPage';
import { RedefinePasswordPage } from '~/src/pages/RedefinePasswordPage';
import { BetaSignupPage } from '~/src/pages/BetaSignupPage';
import { BetaSuccessPage } from '~/src/pages/BetaSuccessPage';

export const App = () => {
	const ui = useUIProvider();
	return (
		<ToastProvider>
			<UIContext.Provider value={ui}>
				<AnimateSharedLayout>
					<BrowserRouter>
						<Switch>
							<Route exact path="/login" component={LoginPage} />
							<Route exact path="/password-recovery" component={ForgotPasswordPage} />
							<Route exact path="/redefine-password" component={RedefinePasswordPage} />
							<Route exact path="/logout" component={LogoutPage} />
							<Route exact path="/signup" component={SignupPage} />
							<Route exact path="/waiting" component={WaitingApprovalPage} />
							<Route
								exact
								path="/redefine-waiting"
								component={WaitingRedefineApprovalPage}
							/>
							<Route
								exact
								path="/sessions/:session/:page?/:item?"
								component={SessionPage}
							/>
							<Route exact path="/sessions/:session/agenda" component={SessionPage} />
							<Route exact path="/sessions" component={ManageSessionsPage} />
							<Route exact path="/admin/:page" component={UsersListPage} />
							<Route exact path="/internal" component={InternalPage} />
							<Route exact path="/home" component={HomePage} />
							<Route exact path="/b-signup" component={BetaSignupPage} />
							<Route exact path="/b-signup-success" component={BetaSuccessPage} />
							<Route exact path="/" component={LandingPage} />
							<Redirect to="/" />
						</Switch>
					</BrowserRouter>
				</AnimateSharedLayout>
			</UIContext.Provider>
		</ToastProvider>
	);
};
