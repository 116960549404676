.TimelinePlayhead {
	--playhead-hitarea-width: 20px;
	--playhead-width: 4px;
	position: absolute;
	left: calc(0px - var(--playhead-hitarea-width));
	padding: 0 var(--playhead-hitarea-width);
}
.TimelinePlayhead__bar {
	width: var(--playhead-width);
	height: 100%;
	top: 0;
	background: var(--playhead-color);
	border-radius: calc(var(--playhead-width) / 2);
}
