import React, { Fragment, useCallback, useState } from 'react';

import { Button, IconButton } from '~/src/lib/Buttons';
import {
	OverlayEditor,
	OverlayEditorHeader,
	OverlayEditorContent,
} from '~/src/lib/OverlayEditor';

import { useMemo } from 'react';
import { colors, formatDigitalClock, getInitials } from '../../utils/format';
import Tooltip from '@mui/material/Tooltip';
import classNames from 'classnames';
import { useSessionPrivileges } from '~/src/data/sessions';

import * as css from './SessionDetails.css';
import joinImg from '../../assets/icons/right-curved-arrow.svg';

const AVATAR_TRANSALTE_LEFT = -6;

export const SessionDetails = ({ session, handleModal }) => {
	const { _data } = session;
	const [screenWidth, setScreenWidth] = useState(window.innerWidth);

	window.addEventListener('resize', () => {
		setScreenWidth(window.innerWidth);
	});

	const getSectionParticipantsName = useCallback(
		(section) => {
			return section.slots.map((slot) =>
				session.rows
					.map((participant) => {
						if (slot.rowIndex == participant.index)
							return { name: participant.text, id: participant.id };
					})
					.filter((name) => name),
			);
		},
		[session.rows],
	);
	const sessionSections = useMemo(
		() =>
			session.sections.map((section) => {
				return { ...section, names: getSectionParticipantsName(section) };
			}),
		[getSectionParticipantsName, session.sections],
	);
	const redirectToHostMeeting = useCallback(() => {
		window.open(_data.location, '_blank');
	}, [_data.location]);

	const handleEdit = () => {
		window.location = `https://beta.thisandthat.chat/home/calendar/monthly/${_data.eventId}`;
	};

	const privileges = useSessionPrivileges(session);

	return (
		<Fragment>
			<OverlayEditor className={css.SessionDetails}>
				<OverlayEditorHeader titleStyle={{ fontSize: '16px' }} title={_data.title}>
					<div className={css.SessionDetails__header__cta}>
						{ (!!_data.eventId && privileges.isOwner) && <IconButton icon="edit" onClick={handleEdit} /> }
						<IconButton icon="printer" />
						{screenWidth > 750 && <IconButton onClick={handleModal} icon="remove" />}
					</div>
				</OverlayEditorHeader>
				<OverlayEditorContent>
					<div className={css.SessionDetails__general}>
						{_data.date && (
							<GeneralInfo
								screenWidth={screenWidth}
								title="Date"
								desc={_data.date}
								icon="time"
							/>
						)}
						{!handleLocation(_data.location) && _data.location && (
							<GeneralInfo
								screenWidth={screenWidth}
								title="Location"
								desc={_data.location}
								icon="location"
							/>
						)}
						{/* <GeneralInfo title="Facilitator" desc="Brunno Romero" icon="user" /> */}
						<GeneralInfo
							screenWidth={screenWidth}
							title="Guest"
							participants={_data.rows}
							icon="users"
						/>
						{handleLocation(_data.location) && (
							<GeneralInfo
								hostImg={handleLocation(_data.location)}
								desc={
									<Button
										onClick={redirectToHostMeeting}
										className={classNames(css['SessionDetails__join_btn'], css['btn'])}
										track={['Join Meeting', { where: 'Session Dialog Details' }]}
									>
										<img src={joinImg} />
										Join
									</Button>
								}
							/>
						)}
						<SectionsInfo sections={sessionSections} objective={_data.objective} />
						<div className={css.SessionDetails__general__footer}>
							<Button
								className={classNames(css['SessionDetails__next_btn'], css['btn'])}
								track={[
									'Close Session Dialog Details',
									{ where: 'Session Dialog Details' },
								]}
								onClick={handleModal}
							>
								Next
							</Button>
						</div>
					</div>
				</OverlayEditorContent>
			</OverlayEditor>
		</Fragment>
	);
};

const MeetingHost = ({ icon }) => {
	return (
		<div className={classNames(css.SessionDetails__meetingHost, css[`${icon}`])}></div>
	);
};

const handleLocation = (location) => {
	if (location?.includes('meet.google')) {
		return <MeetingHost icon="google" />;
	}
	if (location?.includes('zoom.us')) {
		return <MeetingHost icon="zoom" />;
	}
	return false;
};

const GeneralInfo = ({ title, hostImg, desc, participants, icon, screenWidth }) => {
	return (
		<div className={css.SessionDetails__general__content}>
			<div className={css.SessionDetails__general__content_title}>
				{icon && <IconButton icon={icon} />}
				{screenWidth > 450 && <span>{title}</span>}
				{hostImg && <span>{hostImg}</span>}
			</div>
			{participants && <GuestsList participants={participants}></GuestsList>}
			{!participants && (
				<div className={css.SessionDetails__general__content_desc}>{desc}</div>
			)}
		</div>
	);
};

const GuestsList = ({ participants }) => {
	return (
		<div className={css.SessionDetails__general__content_desc}>
			{participants.map((participant, index) => {
				return (
					<Fragment key={participant.id}>
						<Avatar
							color={colors[Math.floor(Math.random() * colors.length)]}
							name={participant.text}
							abbreviation={getInitials(participant.text)}
							style={{ marginLeft: index > 0 ? AVATAR_TRANSALTE_LEFT : 0 }}
						></Avatar>
					</Fragment>
				);
			})}
		</div>
	);
};

const SectionsInfo = ({ sections, objective }) => {
	return (
		<div className={css.SessionDetails__agenda}>
			<div className={css.SessionDetails__agenda__container}>
				{objective && (
					<div className={css.SessionDetails__agenda__goal_objective}>
						<h2 className={css.SessionDetails__agenda__objective_title}>
							Meeting Objective
						</h2>
						<p className={css.SessionDetails__agenda__objective_desc}>{objective}</p>
					</div>
				)}

				<h2>Agenda items</h2>

				<div className={css.SessionDetais__agenda__content}>
					{sections &&
						sections.map((section) => {
							return (
								<Fragment key={section.id}>
									<SectionItem section={section}></SectionItem>
								</Fragment>
							);
						})}
				</div>
			</div>
		</div>
	);
};

const SectionItem = ({ section }) => {
	return (
		<div className={css.SessionDetails__agenda__content__section}>
			<div className={css.SessionDetails__agenda__content__section__subject}>
				<h3>{section.title}</h3>
				<p>{section.description}</p>
			</div>
			<div className={css.SessionDetails__agenda__content__section__time_header}>
				<div
					className={css.SessionDetails__agenda__content__section__time_header__duration}
				>
					{durationInMinutes(section.duration)}
				</div>
				<div
					className={css.SessionDetails__agenda__content__section__time_header__guests}
				>
					{section.names &&
						section.names.map((speaker, index) => (
							<Fragment key={`${speaker[0].id}#${index}`}>
								<Tooltip title={speaker[0].name}>
									<div
										style={{
											marginLeft: index > 0 ? AVATAR_TRANSALTE_LEFT : 0,
										}}
									>
										{fistNameChar(speaker[0].name)}
									</div>
								</Tooltip>
							</Fragment>
						))}
				</div>
			</div>
			<div className={css.SessionDetails__agenda__content__section__border}></div>
		</div>
	);
};

const Avatar = ({ abbreviation, name, color, style }) => {
	return (
		<Tooltip title={name}>
			<div style={{ ...style, background: color }} className={css.SessionDetails__avatar}>
				{abbreviation}
			</div>
		</Tooltip>
	);
};

const durationInMinutes = (seconds) => {
	return `${formatDigitalClock(seconds)}`;
};

const fistNameChar = (name) => {
	return String(name).substring(0, 1);
};
