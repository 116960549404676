.ProgressBar {
	flex: 1;
	height: var(--progress-bar-height);
	box-sizing: border-box;
	border: 1px solid #dedede;
	border-radius: 6px;
	overflow: hidden;
}
.ProgressBar__progress {
	width: 100%;
	height: 100%;
	background: #e3e3e3;
}
