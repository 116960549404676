import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames';

import { useSessionPrivileges } from '~/src/data/sessions';
import { useLivePlayState } from '~/src/data/playState';
import { useLayout } from '~/src/utils/useLayout';
import { RoundButton } from '~/src/lib/Buttons';
import { ProgressBar } from '~/src/lib/ProgressBar';
import { DigitalClock } from '~/src/lib/DigitalClock';

import * as css from './SectionHeader.css';

const NARROW_SECTION_THRESHOLD = 3 * 60;

export const SectionHeader = ({ section, session }) => {
	const layout = useLayout();
	const history = useHistory();
	const privileges = useSessionPrivileges(session);
	const playState = useLivePlayState(session);
	const sectionProgress = playState.getSectionProgress(section);
	const sectionTimeRemaining =
		sectionProgress === 0
			? 0
			: sectionProgress === 1
			? section.duration
			: playState.getSectionRelativeTime();
	const isNarrow = section.duration < NARROW_SECTION_THRESHOLD;
	const editSection = () => {
		if (!privileges.canEdit) {
			return;
		}
		history.push(`/sessions/${session.id}/sections/${section.id}`);
	};
	const duplicateSection = () => {
		session.duplicateSection(section);
		playState.setSession(session);
	};
	const moveSectionForward = () => {
		session.moveSectionPosition('forward', section);
		playState.setSession(session);
	};
	const moveSectionBackward = () => {
		session.moveSectionPosition('backward', section);
		playState.setSession(session);
	};
	const deleteSection = () => {
		session.removeSection(section);
		playState.setSession(session);
	};
	return (
		<header
			className={classnames(css.SectionHeader, isNarrow && css['SectionHeader--narrow'])}
			style={layout.header}
		>
			<div className={css.SectionHeader__titleBar}>
				<h2 className={css.SectionHeader__title}>{section.title || 'Unnamed section'}</h2>
				{privileges.canEdit && (
					<Fragment>
						<RoundButton
							icon="edit"
							onClick={editSection}
							track={['Edit Section', { where: 'Section Header edit button' }]}
						/>
						<RoundButton
							icon="trash"
							onClick={deleteSection}
							track={['Delete Section', { where: 'Section Header delete button' }]}
						/>
						<RoundButton
							onClick={duplicateSection}
							icon="duplicate"
							track={['Duplicate Section', { where: 'Section Header duplicate button' }]}
						></RoundButton>
						{session.sections.length > 1 && (
							<Fragment>
								<RoundButton
									onClick={moveSectionBackward}
									icon="arrow-left"
									track={[
										'Move Section Backward',
										{ where: 'Move Section backward button' },
									]}
								></RoundButton>
								<RoundButton
									onClick={moveSectionForward}
									icon="arrow-right"
									track={[
										'Move Section Forward',
										{ where: 'Move Section forward button' },
									]}
								></RoundButton>
							</Fragment>
						)}
					</Fragment>
				)}
			</div>
			<div className={css.SectionHeader__description}>{section.description}</div>
			<div className={css.SectionHeader__clock}>
				<DigitalClock remaining={sectionTimeRemaining} total={section.duration} />
			</div>
			<div className={css.SectionHeader__progress}>
				<ProgressBar progress={sectionProgress} />
			</div>
		</header>
	);
};
