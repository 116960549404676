.SectionHeader {
	display: grid;
	grid-template-areas:
		'title title'
		'description clock'
		'progress progress';
	grid-template-rows: 25px 75px 25px;
	grid-template-columns: 1fr auto;
	line-height: 30px;
	row-gap: 10px;
	box-sizing: border-box;
}
.SectionHeader--narrow {
	grid-template-areas:
		'title'
		'description'
		'clock'
		'progress';
	grid-template-rows: 25px 50px 25px 25px;
	grid-template-columns: auto;
}
.SectionHeader__titleBar {
	grid-area: title;
	display: flex;
	align-items: center;
	font-size: var(--section-title-size);
	cursor: default;
	overflow: hidden;
	grid-gap: 15px;
}
.SectionHeader__title {
	font-size: inherit;
	line-height: 1em;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}
.SectionHeader__description {
	grid-area: description;
	font-size: 16px;
	line-height: 25px;
	overflow: hidden;
	text-overflow: ellipsis;
	position: relative;
}
.SectionHeader__clock {
	grid-area: clock;
	margin-left: 10px;
	font-size: 20px;
	line-height: 25px;
	font-weight: var(--regular);
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;
}
.SectionHeader__progress {
	grid-area: progress;
	display: flex;
	align-items: flex-start;
	--progress-bar-height: 12px;
}

@media (max-height: 700px) {
	.SectionHeader {
		grid-template-areas:
			'title clock'
			'description '
			'progress progress ';
		grid-template-rows: 25px 25px;
		row-gap: 5px;
	}
	.SectionHeader__progress {
		padding-bottom: 20px;
	}
	.SectionHeader__description {
		grid-area: description;
		font-size: 12px;
		line-height: 25px;
		overflow: hidden;
		text-overflow: ellipsis;
		position: relative;
	}
}
