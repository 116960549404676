.LineField {
	position: relative;
	border: 1px solid var(--border-color);
	border-radius: 10px;
	display: flex;
	padding: 0 10px 0 0;
	transition: border-color 100ms ease, box-shadow 100ms ease;
	box-shadow: 0px 0px 0px rgba(127, 127, 127, 0);
}
.LineField:focus-within {
	border-color: var(--field-focus-color);
	box-shadow: 0px 1px 3px 0 rgba(127, 127, 127, 0.2);
}
.LineField__input {
	flex: 1;
	padding: 10px 10px 10px 15px;
	font-family: inherit;
	font-size: 16px;
	line-height: 30px;
	border: none;
	background: transparent;
}
.LineField__input:focus {
	outline: none;
}
.LineField__input::placeholder {
	color: var(--placeholder-color);
}
.LineField__submit {
	width: auto;
	align-self: center;
}
.LineField__error {
	position: absolute;
	top: 100%;
	left: 0;
}
