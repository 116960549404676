import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useUserForAuth } from '~/src/data/user';
import { Main, Content } from '~/src/lib/Layout';
import { Button } from '~/src/lib/Buttons';
import { ErrorBox } from '~/src/lib/ErrorBox';
import { Label } from '~/src/lib/Form/Label';
import { TextInput } from '~/src/lib/Form/TextInput';

import * as css from './AuthPage.css';

export const RedefinePasswordPage = ({ location }) => {
	const { message, redefinePassword, error, user } = useUserForAuth();
	const [isLoading, setIsLoading] = useState(false);
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const history = useHistory();

	const params = new URLSearchParams(location.search);
	const token = params.get('token');
	const isBeta = params.get('beta');

	const handleSubmit = async (event) => {
		//call api
		event.preventDefault();
		setIsLoading(true);
		const isApproved = await redefinePassword({ password, confirmPassword, token });
		setIsLoading(false);
		
		// if (!isApproved) {
		history.push('/home');
		// }
	};

	return (
		<Main className={css.AuthPage} headerProps={{ showLogin: false }}>
			<Content>
				{isLoading ? (
					'Loading...'
				) : message ? (
					<div className={css.AuthPage__message}>{message}</div>
				) : (
					<form onSubmit={handleSubmit}>
						{isBeta ? (
						<div className={css.AuthPage__info}>
							<p>Hey! We've already registered you for the Alpha version.</p>
							<p>Now all you need is a password:</p>
							<br />
						</div>
						) : ''}
						<label className={css.AuthPage__input}>
							<Label>Password</Label>
							<TextInput
								type="password"
								name="password"
								onChange={(event) => setPassword(event.target.value)}
							/>
						</label>
						<label className={css.AuthPage__input}>
							<Label>Confirm Password</Label>
							<TextInput
								type="password"
								name="confirmPassword"
								onChange={(event) => setConfirmPassword(event.target.value)}
							/>
						</label>
						<div className={css.AuthPage__actions}>
							<Button type="submit" track={['Submit Login', { where: 'Login page' }]}>
							{isBeta ? 'Continue' : 'Redefine'}
							</Button>
						</div>
						{error && (
							<div className={css.AuthPage__error}>
								<ErrorBox error={error} />
							</div>
						)}
					</form>
				)}
			</Content>
		</Main>
	);
};
