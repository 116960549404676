import { useState, useEffect, useContext, createContext } from 'react';

import { limit } from '~/src/utils/limit';
import {
	PLUS_KEY,
	MINUS_KEY,
	PAGE_UP,
	PAGE_DOWN,
	HOME_KEY,
	END_KEY,
	UP,
	DOWN,
	LEFT,
	RIGHT,
	SHIFT,
	getMaskedKeyCode,
} from '~/src/utils/keys';

/*
To use this, in a wrapping component:
import { ZoomContext, useZoomProvider }
const zoom = useZoomProvider();
<ZoomContext.Provider value={zoom}>
	...
</ZoomContext.Provider>

In child components:
import { useZoom }
const zoom = useZoom();
// zoom.x, zoom.y
*/

// Initial state
const INITIAL_ZOOM_FACTOR = {
	x: 2,
	y: 100,
};

const MIN_ZOOM_X = 1;
const MAX_ZOOM_X = 10;
const MIN_ZOOM_Y = 10;
const MAX_ZOOM_Y = 1000;

const IGNORE_INPUT_ELEMENTS = new Set(['INPUT', 'TEXTAREA']);

export const ZoomContext = createContext(INITIAL_ZOOM_FACTOR);

export const useZoomProvider = (initial = INITIAL_ZOOM_FACTOR) => {
	const [zoom, setZoom] = useState(initial);
	useEffect(() => {
		const handleKeydown = (event) => {
			if (IGNORE_INPUT_ELEMENTS.has(event.target.nodeName)) {
				return;
			}
			switch (getMaskedKeyCode(event)) {
				case PLUS_KEY:
				case SHIFT + RIGHT:
					return setZoom({ ...zoom, x: limit(zoom.x * 1.25, MIN_ZOOM_X, MAX_ZOOM_X) });
				case MINUS_KEY:
				case SHIFT + LEFT:
					return setZoom({ ...zoom, x: limit(zoom.x * 0.75, MIN_ZOOM_X, MAX_ZOOM_X) });
				case PAGE_UP:
				case SHIFT + UP:
				case SHIFT + MINUS_KEY:
					return setZoom({ ...zoom, y: limit(zoom.y * 0.75, MIN_ZOOM_Y, MAX_ZOOM_Y) });
				case PAGE_DOWN:
				case SHIFT + DOWN:
				case SHIFT + PLUS_KEY:
					return setZoom({ ...zoom, y: limit(zoom.y * 1.25, MIN_ZOOM_Y, MAX_ZOOM_Y) });
				case HOME_KEY:
					return setZoom({ ...INITIAL_ZOOM_FACTOR, previous: zoom });
				case END_KEY:
					if (zoom.previous) {
						return setZoom(zoom.previous);
					}
			}
		};
		window.addEventListener('keydown', handleKeydown, { passive: true });
		return () => window.removeEventListener('keydown', handleKeydown, { passive: true });
	}, [zoom]);
	return zoom;
};

export const useZoom = () => {
	return useContext(ZoomContext);
};
