.HomePage {
	--space-top: 0px;
	--space-bottom: 30px;
	--content-space-top: 0;
}

.HomePage__intro {
	padding: 16.42% 4.84% 11.13% 2.44%;
}

.HomePage hr {
	background: var(--divider-color);
	width: 1px;
	align-self: stretch;
	margin: 0;
	border: none;
}
.HomePage__introTitle,
.HomePage__sessionsTitle {
	color: #1d1166;
	font-family: 'Work Sans';
	font-style: normal;
	font-weight: 700;
	font-size: 38px;
	line-height: 45px;
	width: 100%;
	height: 49px;
}
.HomePage__introTitle {
	margin-bottom: 37px;
}

.HomePage__introText_strong_p {
	font-style: normal;
	font-weight: 700;
}

.HomePage__sessionText {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 700;
	font-size: 17px;
	line-height: 20px;
	height: 53px;
	display: flex;
	align-items: center;
	margin-top: 10px;
}

.HomePage__introText {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	font-size: 19px;
	line-height: 22px;
}

.HomePage__introText p + p {
	margin-top: 20px;
}

.HomePage__introCtaInner {
	display: flex;
	text-align: center;
	align-items: center;
	column-gap: 10px;
}
.HomePage__loginMessage {
	margin-top: 5px;
}
.HomePage__sessions {
	box-sizing: border-box;
	display: flex;
	flex-wrap: wrap;
	max-width: 560px;
	margin: -20px 0px 0px 0px !important;
	padding: 48px 36px 0 0;
}

.HomePage__sessionsCta {
	margin-top: 50px;
	font-weight: 700;
}

.HomePage__introCta {
}

.HomePage__bg {
	position: absolute;
	top: 0;
	right: 0;
	width: 40%;
	height: 100%;
	background-color: #3ec9f9;
	background-image: url('~/src/assets/media/homepage-side-background.svg');
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center center;
}

.HomePage__adminActions {
	margin-bottom: 20px;
}

.HomePage__adminActions a {
	margin: 5px;
}

.HomePage__verticalLine {
	height: 100%;
}

@media (max-width: 900px) {
	.HomePage__bg {
		display: none;
	}

	.HomePage__verticalLine {
		display: none;
	}
}
@media (min-width: 1500px) {
	.HomePage__introText {
		min-width: 30.76vw;
		min-height: 27.71vh;
	}

	.HomePage__introTitle,
	.HomePage__sessionsTitle {
		white-space: nowrap;
	}

	.HomePage__introCta {
		margin: 0 31.51% 0 26.48%;
	}
}
@media (max-width: 1500px) {
	.HomePage__introTitle {
		margin-bottom: 80px;
	}
	.HomePage__introCta {
		margin-top: 10%;
	}
}

@media (min-width: 900px) and (min-height: 850px) {
	.HomePage__intro {
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		width: 33.18vw;
		height: 56.44vh;
		padding-right: 15.08%;
		margin-left: 12.54vw;
		margin-top: 3vh;
	}
}

@media (min-width: 1500px) {
	.HomePage__introCta {
		margin-top: 10%;
	}
	.HomePage__sessionText {
		white-space: nowrap;
	}
}
