.SectionGrid {
	--grid-text-height: 15px;
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}
.SectionGrid__legend {
	position: absolute;
	height: var(--grid-text-height);
	top: 0;
	text-align: center;
	transform: translateX(-50%);
	font-size: 12px;
	font-weight: var(--semibold);
	line-height: 10px;
	color: var(--grid-legend-color);
}
.SectionGrid__line {
	position: absolute;
	height: calc(100% - var(--grid-text-height));
	top: var(--grid-text-height);
	width: 1px;
	background: var(--grid-line-color);
}
