import React from 'react';
import { motion } from 'framer-motion';

import { Main, Content } from '~/src/lib/Layout';

import * as css from './BetaSuccessPage.css';

const HIDE_SHOW = { hide: { opacity: 0 }, show: { opacity: 1 } };

export const BetaSuccessPage = ({ location }) => {
	const params = new URLSearchParams(location.search);
	const isBeta = params.get('beta');

	return (
		<Main className={css.WaitingApproval}>
			<Content>
				<motion.div
					variants={HIDE_SHOW}
					initial="hide"
					animate="show"
					transition={{
						staggerChildren: 0.1,
					}}
				>
					<h1 className={css.WaitingApproval__title}>Congratulations! </h1>
					<div className={css.WaitingApproval__info}>
						<p>You're officially on our early adopter shortlist. In the meantime we've also given you exclusive access to the Alpha version.</p>
                        <p>We want to help you and your business, and would love to work closer by getting your feedback. </p>
						<p>Just go to your email and create your user account, to begin using this&that for your meetings right away.</p>
						<p>Thank you!</p>
					</div>
				</motion.div>
			</Content>
		</Main>
	);
};
