import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { track } from '~/src/utils/analytics';
import {
	OverlayEditor,
	OverlayEditorHeader,
	OverlayEditorContent,
} from '~/src/lib/OverlayEditor';
import { TextArea } from '~/src/lib/Form/TextArea';
import { Checkbox } from '~/src/lib/Form/Checkbox';
import { Label } from '~/src/lib/Form/Label';

import * as css from './SlotEditor.css';
import { OverlayEditorFooter } from '../OverlayEditor';
import { usePlayState } from '../../data/playState';

export const SlotEditor = ({ onDone, session, itemId }) => {
	const history = useHistory();
	const slot = session.getSlot(itemId);
	const playState = usePlayState(session);
	const [text, setText] = useState(slot.text);
	const [spanAll, setSpanAll] = useState(slot.spanAll);

	useEffect(() => {
		return () => {
			playState.setSession(session);
		};
	}, [playState, session]);
	const handleTextChange = (event) => {
		setText(event.target.value);
	};
	const handleSpanAllChange = (event) => {
		setSpanAll(event.target.checked);
	};
	const onDelete = () => {
		session.removeSlot(slot);
		history.push(`/sessions/${session.id}`);
	};
	const handleOnDone = () => {
		if (text !== slot.text) {
			track('Set Slot Text');
		}
		if (spanAll !== slot.spanAll) {
			track('Set Slot Everyone Speaking', { everyoneSpeaking: spanAll });
		}
		session.updateSlot(itemId, { text, spanAll });
		onDone();
	};
	const handleKeydown = (event) => {
		if (event.which === 13 && !event.shiftKey) {
			track('Done Editing Slot', { how: 'Enter key' });
			event.preventDefault();
			handleOnDone();
		}
	};
	return (
		<OverlayEditor className={css.SlotEditor}>
			<OverlayEditorHeader title="Edit slot" itemType="Slot"></OverlayEditorHeader>
			<OverlayEditorContent>
				<Label>Text</Label>
				<TextArea onChange={handleTextChange} onKeyDown={handleKeydown} value={text} />
				<Checkbox onChange={handleSpanAllChange} checked={spanAll}>
					Everyone speaking
				</Checkbox>
			</OverlayEditorContent>
			<OverlayEditorFooter onDelete={onDelete} onDone={handleOnDone} itemType="Slot" />
		</OverlayEditor>
	);
};
