import { useState, useEffect } from 'react';
import { useSWRConfig } from 'swr';
import {
	useResource,
	post,
	setAccessToken,
	hasAccessToken,
	getCachedUserData,
	setCachedUserData,
	clearAccessToken,
	clearCachedUserData,
} from './api';

function clearLocalUserData() {
	clearAccessToken();
	clearCachedUserData();
}

export const useUser = () => {
	// If we have an access token, load the users/me endpoint to get some info
	const userResponse = useResource(hasAccessToken() ? '/users/me' : null, {
		fallbackData: {
			// Load data from the cache to mitigate initial flicker
			user: getCachedUserData(),
		},
	});
	useEffect(() => {
		if (userResponse.data && userResponse.data.user) {
			// Cache the data returned from the API
			setCachedUserData(userResponse.data.user);
		}
	}, [userResponse]);
	return {
		isLoading: userResponse.isLoading,
		error: userResponse.error,
		user: userResponse.data && userResponse.data.user,
	};
};

export const useActiveUsers = () => {
	const { data, isLoading, error, mutate } = useResource('/users/active');

	return { data: data ? data : [], isLoading, error, refresh: () => mutate() };
};

export const usePendingUsers = () => {
	const { data, isLoading, error, mutate } = useResource('/users/pending');
	return { data: data ? data : [], isLoading, error, refresh: () => mutate() };
};

export const useArchivedUsers = () => {
	const { data, isLoading, error, mutate } = useResource('/users/archived');
	return { data: data ? data : [], isLoading, error, refresh: () => mutate() };
};

export const useUserForAuth = () => {
	const [error, setError] = useState(null);
	const [message, setMessage] = useState(null);
	const { mutate } = useSWRConfig('/users/me');
	const { user } = useUser();
	// First step of logging in, submit email
	const submitLogin = async ({ email, password, isBeta }) => {
		if (!email) {
			setError(new Error('Email missing'));
			return false;
		}
		if (!password && !isBeta) {
			setError(new Error('Password missing'));
			return false;
		}

		try {
			const response = await post('/auth/login', { email, password });

			if (response && response.accessToken) {
				window.Intercom('boot', {
					api_base: 'https://api-iam.intercom.io',
					app_id: 'ndo8dmoi',
					name: response.user.name,
					email: response.user.email,
					type: response.user.type,
					utm_source: response.user.company,
					created_at: response.user.created_at,
					meetings: response.numberOfMeetings,
					utm_campaign: response.user.campaign,
				});

				setAccessToken(response.accessToken);
				// Indicate that the /users/me endpoint has changed
				mutate('/users/me');
				return true;
			} else {
				setError(new Error('No access token received from api'));
				return false;
			}
		} catch (error) {
			setError(error);
		}
	};
	const submitSignup = async ({
		email,
		name,
		phone,
		password,
		company,
		companySize,
		isBeta,
		type,
		campaign,
	}) => {
		if (!email) {
			setError(new Error('Email missing'));
			return false;
		}
		if (!name) {
			setError(new Error('Name missing'));
			return false;
		}
		if (!password && !isBeta) {
			setError(new Error('Password missing'));
			return false;
		}

		try {
			const signupResponse = await post('/auth/signup', {
				email,
				password,
				name,
				phone,
				company,
				companySize,
				campaign,
				type,
			});

			window.Intercom('boot', {
				api_base: 'https://api-iam.intercom.io',
				app_id: 'ndo8dmoi',
				name: name,
				email: email,
				phone: phone,
				type: type,
				utm_source: company,
				utm_campaign: campaign,
			});

			return signupResponse;
		} catch (error) {
			setError(error);
		}
	};
	// Second step, if acquiring a signup token
	const submitSignupToken = async ({ name, token }) => {
		if (!name) {
			setError(new Error('Name missing'));
			return false;
		}
		return submitLoginToken({ name, token });
	};
	// Second step, if you have a login token
	const submitLoginToken = async (data) => {
		if (!data.token) {
			setError(new Error('Token missing'));
			return null;
		}
		try {
			// Submit to /auth/login
			const response = await post('/auth/login', data);
			// Store returned access token
			if (response && response.accessToken) {
				setAccessToken(response.accessToken);
				// Indicate that the /users/me endpoint has changed
				mutate('/users/me');
				return true;
			} else {
				setError(new Error('No access token received from api'));
				return false;
			}
		} catch (error) {
			setError(error);
			return false;
		}
	};
	const signoutUser = async () => {
		// TODO: Submit this to the API in case it wants to invalidate tokens
		clearLocalUserData();
		setMessage('Signed out');
		return true;
	};
	const redefinePassword = async ({ password, confirmPassword, token }) => {
		if (!token) {
			setError(new Error('Token missing'));
			return null;
		}
		if (!password) {
			setError(new Error('Password missing'));
			return false;
		}
		if (!confirmPassword) {
			setError(new Error('Confirm password missing'));
			return false;
		}
		if (confirmPassword !== password) {
			setError(new Error('Passwords do not match'));
			return false;
		}

		try {
			const response = await post('/auth/redefine', { password, confirmPassword, token });

			// if (response.approved_at) {
			setAccessToken(response.accessToken);
			// Indicate that the /users/me endpoint has changed
			mutate('/users/me');
			return true;
			// } else {
			// 	return false;
			// }
		} catch (error) {
			setError(error);
		}
	};
	const submitPasswordRecovery = async ({ email, password }) => {
		if (!email) {
			setError(new Error('Email missing'));
			return false;
		}

		try {
			const response = await post('/auth/recovery', { email });
			setMessage(response.message);
			return true;
		} catch (error) {
			setError(error);
		}
	};
	return {
		user,
		error,
		submitSignupToken,
		submitLoginToken,
		submitLogin,
		submitSignup,
		submitPasswordRecovery,
		redefinePassword,
		message,
		signoutUser,
		useActiveUsers,
		usePendingUsers,
	};
};
