.LandingPage__container {
	position: relative;
	max-width: 1100px;
	width: 100%;
	height: 100%;
}

.LandingPage {
	display: flex;
    flex-direction: column;
    align-items: center;
	margin: 0;
	font-family: sans-serif;
	color: #020776;
	height: 100vh;
	overflow: hidden;
	background: linear-gradient(
	  289deg,
	  rgba(205, 185, 255, 1) 0%,
	  rgba(92, 217, 255, 1) 21%,
	  rgba(178, 255, 163, 1) 43%,
	  rgba(212, 255, 187, 1) 70%,
	  rgba(120, 227, 255, 1) 100%
	);
	padding: 0px 120px 0px 120px;
}
.LandingPage__header {
	position:absolute;
	top: 4%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border: 2px solid #1D1166;
	border-radius: 5px;
	width: 100%;
	height: 6.8vh;
}
.LandingPage__header h2{
	font-weight: 700;
	font-size: 18px;
	margin: 0px 2%;
}

.LandingPage__logo {
	color: #1D1166
}

.LandingPage__login {
	cursor:pointer !important;
	z-index: 1 !important;
}

.LandingPage__login span {
	color: rgba(29, 17, 102, 1) !important;
}

.LandingPage__section {
	position:relative;
	height: 100%;
	display: flex;
	color: #1D1166;
}

.LandingPage__left_content {
	max-width: 400px;
	min-width: 38vw;
	padding: 13px;
	align-self: center;
}

.LandingPage__left_content h1{
	font-family: 'Work Sans', sans-serif;
	font-weight: 800;
	font-size: 38px;
	font-style: normal;
    letter-spacing: .01em;
    text-transform: none;
	margin-bottom: 30px;
}

.LandingPage__description {
	width: 82%;
}

.LandingPage__left_content h2 {
	font-size: 24px;
    font-weight: 500;
    text-align:start;
	line-height:27px;
	margin-bottom: 25px;
}

.LandingPage__buttons_get_started{
	background-color: white; /* Green */
	color: #1D1166;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 64px;
	font-size: 18px;
	font-weight: bold;
	min-width: 40%;
	max-width: 40%;
	min-height: 58px;
	max-height: 58px;
	border-radius: 0 !important;
}

.LandingPage__buttons span {
	color: #1D1166;
}

.LandingPage__right_content {
}


.LandingPage__footer {
	min-height: 11vh;
	display: flex;
	align-items: center;
	border-radius: 5px;
	border: 2px solid #1D1166;
	justify-content: space-around;
	background: rgba(255,255,255,0.3);
	position: absolute;
	bottom: 4%;
	width: 100%;
}

.LandingPage__footer h2{
	font-size: 18px;
	font-weight: 800;
	cursor:pointer;
}

.LandingPage__footer a {
	text-decoration: none !important;
}

.LandingPage__dialog {
	position: relative;
	background-color: white;
	max-height: 70vh;
	min-height: 30vh;
	border-radius: 10px;
	padding: 30px;
	text-align: center;
	color: #000;
	overflow: auto;
  }

  .LandingPage__dialog_close {
	position: absolute;
	top: 20px;
	right: 20px;
	font-weight: 800;
	font-size: 18px;
	cursor: pointer;
  }
  .LandingPage__dialog_head {
	margin: 30px 0px 30px 0px;
	font-size: 18px;
  }

  .LandingPage__dialog_content {
	padding: 2px 50px 2px 50px;
	font-size: 18px;
  }

@media only screen and (max-width: 900px) {
	.LandingPage {
		padding: 2px 20px 0px 20px;
	  }

	.LandingPage__description {
		padding-right: 0px;
	}

	.LandingPage__description h2 {
		text-align: center;
	}

	  .LandingPage__left_content {
		width: 100%;
		min-width: 0px;
		max-width: 100%;
		text-align: center;
	  }

	  .LandingPage__right_content {
		display: none;
	  }

	  h1 ,
	  h2 {
		text-align: center;
	  } 

	  .LandingPage__description {
		width: 100%;
	}	

	  .LandingPage__buttons button {
		width: 100%;
	  }

	  .LandingPage__header h2 {
		font-size: 18px;
	  }

	  .LandingPage__footer {
	  display: flex;
	  align-items: center;
	}

	.LandingPage__footer h2 {
		font-size: 14px;
	  }

	  .LandingPage__left_content h1 {
		font-size: 30px;
	  }

	  .LandingPage__left_content h2 {
		font-size: 24px;
		font-weight: lighter;
	  }
	  
	  .LandingPage__buttons_get_started {
		max-width: 100%;
	  }
}
