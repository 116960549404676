.Footer {
	height: var(--footer-height);
	width: 100%;
	box-sizing: border-box;
	align-items: stretch;
	padding: 0 20px;
}
.Footer__sectionTitle {
	font: var(--footer-font);
	margin-bottom: 10px;
}
.Footer__infoBox {
	grid-area: info;
}
.Footer__playbackControl {
	grid-area: playback;
	display: flex;
	align-items: center;
}
.Footer__jumpTo {
	grid-area: jump;
}
.Footer__layoutControl {
	position: fixed;
	bottom: 0;
	right: 0;
}
.Footer__maximizeButton,
.Footer__minimizeButton {
	margin-left: auto;
	width: 30px;
	height: 30px;
	border-radius: 3px;
	background-size: 16px auto;
	background-position: center center;
	background-repeat: no-repeat;
	background-image: url('~/src/assets/icons/more-v.svg');
	text-indent: 999px;
	overflow: hidden;
	transition: background-color 150ms ease;
}
.Footer__maximizeButton {
	background-image: url('~/src/assets/icons/maximize.svg');
}
.Footer__minimizeButton {
	background-image: url('~/src/assets/icons/minimize.svg');
}
.Footer__actions {
}

@media (max-height: 500px) {
	.Footer {
		position: absolute;
		left: 15px;
		z-index: 10;
		box-sizing: border-box;
		align-items: stretch;
	}

	.Footer__playbackControl {
		display: flex;
		align-items: center;
	}
}
@media (min-height: 500px) {
	.Footer {
		position: fixed;
		bottom: 0;
		left: 0;
		display: grid;
		grid-template-columns: 2fr auto 1fr 1fr;
		grid-template-areas: 'info playback jump layout';
	}
	.Footer__playbackControl {
		grid-area: playback;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
}
@media (max-height: 700px) and (min-height: 500px) {
	.Footer {
		bottom: 15px;
	}
}
