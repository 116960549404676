.OverlayEditor {
	display: flex;
	flex-direction: column;
}

.OverlayEditor__header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: sticky;
	top: 0;
	background: var(--background-color);
	z-index: var(--z-overlay-editor);
	padding: 15px var(--pad-x) 5px;
	margin-bottom: 5px;
}
.OverlayEditor__header *:nth-child(n + 2) * {
	margin-inline: 10px;
}

.OverlayEditor__title {
	width: 75%;
	white-space: nowrap;
	flex-wrap: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	text-align: start;
	font: var(--title-medium);
	margin-right: 35px;
}

.OverlayEditor__title_center {
	width: 100%;
	justify-self: center;
	margin-right: 0;
}

.OverlayEditor__actions > * + * {
	margin: 30px;
}
.OverlayEditor__content {
	padding: 0 27px;
	height: 90%;
	flex-grow: 1;
}
.OverlayEditor__footer {
	box-sizing: border-box;
	position: sticky;
	bottom: 0;
	background: var(--background-color);
	padding: 0px 25px 10px 25px;
}

.OverlayEditor__footer__actionBtn {
	width: 100%;
	margin-top: 15px;
}

.OverlayEditor__footer__actionBtn > * {
	margin: 0px 10px 0px 10px;
	float: right;
}
.OverlayEditor__custombtn {
	margin-top: 8px;
	float: left;
}
