import isNil from 'lodash/isNil';

import { useRaf } from '~/src/utils/useRaf';
import { useUpdater, makeUpdatable } from '~/src/utils/useUpdater';
import { isSession } from '~/src/models';

import { SOUNDS } from '~/src/utils/sounds';
import { fileSounds } from '../utils/sounds';

const PLAY_STATES = new Map();
const END_OF_SESSION = Symbol('END_OF_SESSION');

const getPlayState = (session) => {
	if (!isSession(session)) {
		console.info('Session:', session);
		throw new Error(`getPlayState requires session (got ${typeof session})`);
	}
	const storedState = PLAY_STATES.get(session);
	if (storedState) {
		return storedState;
	}
	const newState = new PlayState(session);
	PLAY_STATES.set(session, newState);
	return newState;
};

export const usePlayState = (session) => {
	if (!isSession(session)) {
		console.info('Session:', session);
		throw new Error(`usePlayState requires session (got ${typeof session})`);
	}
	return useUpdater(getPlayState(session));
};

export const useLivePlayState = (session) => {
	const playState = usePlayState(session);
	useRaf(playState.isPlaying, 20);
	return playState;
};

class PlayState {
	constructor(session) {
		makeUpdatable(this);
		if (!isSession(session)) {
			throw new Error(`PlayState must be fed a Session (got ${typeof session})`);
		}
		this.session = session;
		this.sectionsEndTime = this.getSectionsEndTime();
		this.slotsEndTime = this.getSlotsEndTime();
		this.isPlaying = false;
		this._currentTime = 0;
		this.goToBoundary = this.goToBoundary.bind(this);
		this.togglePlay = this.togglePlay.bind(this);
		this.play = this.play.bind(this);
		this.pause = this.pause.bind(this);
		this.frameHandler = this.frameHandler.bind(this);
		this._lastFrameTime = 0;
		this.isCurrentTimeInSecondsChanged = false;
		this.currentTimeInSeconds = 0;
		this.currentTimeInPercentage = 0;
		this.isSoundReadyToPlay = true;
		this.sessionSounds = this.getSessionSounds();
		this.soundsTime = this.getSoundsTimeSeconds();
		requestAnimationFrame(this.frameHandler);
	}

	togglePlay() {
		if (this.isPlaying) {
			this.pause();
		} else {
			this.play();
		}
	}
	async importAudio(sound) {
		return fileSounds(sound);
	}

	async playAudio(sound) {
		const audio = new Audio(await this.importAudio(sound));
		audio.play();
	}
	play() {
		console.log(this.session);
		if (this.isPastEnd) {
			this.currentTime = 0;
		}
		this.isPlaying = true;
		this._triggerChange();
	}
	pause() {
		this.isPlaying = false;
		this._triggerChange();
	}
	getSoundsTimeSeconds() {
		return this.sessionSounds.map((session) => session.percentage);
	}
	getSectionsEndTime() {
		let count = 0;
		return this.session.sections.map((section) => {
			return (count += section.duration);
		});
	}
	getSlotsEndTime() {
		let slotsEndTime = [];
		let countSectionsDuration = 0;
		this.session.sections.map((section, index) => {
			if (this.session.sections[index - 1]) {
				countSectionsDuration += this.session.sections[index - 1].duration;
			}
			let slots = section.slots.map((slot) => {
				return slot.start + countSectionsDuration + slot.duration;
			});
			slotsEndTime.push(slots);
		});
		return [].concat(...slotsEndTime);
	}
	getSlotsStartTime() {
		let slotsEndTime = [];
		let countSectionsDuration = 0;
		this.session.sections.map((section, index) => {
			if (this.session.sections[index - 1]) {
				countSectionsDuration += this.session.sections[index - 1].duration;
			}
			let slots = section.slots.map((slot) => {
				return slot.start + countSectionsDuration;
			});
			slotsEndTime.push(slots);
		});
		return [].concat(...slotsEndTime);
	}

	secondsToPercentage(seconds) {
		return parseFloat(((seconds * 100) / this.session.duration).toFixed(2));
	}

	getSessionSounds() {
		let sessionSounds = [];

		this.slotsEndTime.map((slot) => {
			sessionSounds.push({
				percentage: this.secondsToPercentage(slot - 1),
				sound: SOUNDS.END_SLOT,
			});
		});

		this.sectionsEndTime.map((time, index) => {
			if (this.sectionsEndTime[index + 1]) {
				sessionSounds.push({
					percentage: this.secondsToPercentage(time - 1),
					sound: SOUNDS.END_SECTION,
				});
			}
		});

		sessionSounds = sessionSounds.sort((a, b) => a.percentage - b.percentage);

		sessionSounds.push({
			percentage: this.secondsToPercentage(this.session.duration - 0.5),
			sound: SOUNDS.END_SESSION,
		});

		sessionSounds.map((session, index) => {
			if (
				(session.percentage == sessionSounds[index + 1]?.percentage &&
					session.sound == sessionSounds[index + 1]?.sound) ||
				(session.percentage == sessionSounds[index + 1]?.percentage &&
					session.sound == SOUNDS.END_SLOT &&
					sessionSounds[index + 1]?.sound == SOUNDS.END_SECTION)
			) {
				sessionSounds.splice(index, 1);
			}
		});
		return sessionSounds;
	}
	getSoundToBePlayedByPercentageIndex(percentage) {
		const item = this.sessionSounds.find((item) => item.percentage === percentage);
		return item.sound;
	}
	handleDisplaySound(currentFrameHandlerTimeInSeconds) {
		if (
			this.soundsTime.includes(
				this.secondsToPercentage(currentFrameHandlerTimeInSeconds),
			) &&
			this.isPlaying &&
			this.isSoundReadyToPlay
		) {
			this.playAudio(
				this.getSoundToBePlayedByPercentageIndex(
					this.secondsToPercentage(currentFrameHandlerTimeInSeconds),
				),
			);
			this.isSoundReadyToPlay = false;
		}
	}
	actionsPerSecond(currentFrameHandlerTimeInSeconds) {
		if (this.currentTimeInSeconds != currentFrameHandlerTimeInSeconds) {
			this.isCurrentTimeInSecondsChanged = true;
			this.currentTimeInSeconds = currentFrameHandlerTimeInSeconds;
		}
	}
	actionsPerPercentage(currentFrameHandlerTimeInSeconds) {
		if (
			this.currentTimeInPercentage !=
			this.secondsToPercentage(currentFrameHandlerTimeInSeconds)
		) {
			this.isSoundReadyToPlay = true;
			this.currentTimeInPercentage = this.secondsToPercentage(
				currentFrameHandlerTimeInSeconds,
			);
		}
	}
	frameHandler() {
		let currentFrameHandlerTimeInSeconds = Math.floor(this._currentTime / 1000);
		this.actionsPerSecond(currentFrameHandlerTimeInSeconds);
		this.actionsPerPercentage(currentFrameHandlerTimeInSeconds);
		const delta = Date.now() - this._lastFrameTime;
		this._lastFrameTime = Date.now();
		if (this.isPlaying) {
			const sessionDuration = this.session.duration * 1000;
			const newTime = this._currentTime + delta;
			const wouldBePastEnd = newTime > sessionDuration;
			if (this.isCurrentTimeInSecondsChanged) {
				if (this.sectionsEndTime.includes(Math.floor(this._currentTime / 1000))) {
					this.pause();
					this.isCurrentTimeInSecondsChanged = false;
				}
			}
			if (wouldBePastEnd) {
				this._currentTime = sessionDuration;
				this.pause();
			} else {
				this._currentTime = newTime;
			}
			if (!this.session.isMuted) {
				this.handleDisplaySound(currentFrameHandlerTimeInSeconds);
			}
		}
		requestAnimationFrame(this.frameHandler);
	}
	get currentTime() {
		return this._currentTime / 1000;
	}
	set currentTime(timeInSeconds) {
		this._currentTime = timeInSeconds * 1000;
		this._triggerChange();
	}
	get isPastEnd() {
		const sessionDuration = this.session.duration * 1000;
		return this._currentTime >= sessionDuration;
	}
	loadSoundSystem() {
		this.sectionsEndTime = this.getSectionsEndTime();
		this.slotsEndTime = this.getSlotsEndTime();
		this.sessionSounds = this.getSessionSounds();
		this.soundsTime = this.getSoundsTimeSeconds();
	}
	setSession(session) {
		this.session = session;
		this.loadSoundSystem();
		this._triggerChange();
	}
	goToBoundary(direction) {
		this.isSoundReadyToPlay = true;
		const boundary = this.findBoundary(direction, this.activeSectionIndex);
		this.currentTime = boundary;
		this._triggerChange();
	}
	findBoundary(direction, sectionIndex) {
		// Find the current section
		const section = this.session.sections[sectionIndex];
		if (!section) {
			console.warn('findBoundary: No section with index', sectionIndex);
			return null;
		}
		const getSlotStarts = direction > 0 ? getFutureSlotStarts : getPastSlotStarts;
		const slotStarts = getSlotStarts(section.slots, this.getSectionRelativeTime(section));
		if (slotStarts.length) {
			const sectionStartTime = this.getSectionStartTime(
				this.session.sections[sectionIndex],
			);
			// Return first item, with start time made absolute
			return sectionStartTime + slotStarts[0];
		}
		return this.findBoundary(direction, sectionIndex + direction);
	}
	goToSection(index) {
		const targetSection = this.session.sections[index];
		if (!targetSection) {
			// TODO: Report bug
			throw new Error(`No section with index ${index}`);
		}
		const time = this.getSectionStartTime(targetSection);
		if (!isNil(time)) {
			this.currentTime = time;
		}
	}
	get activeSectionIndex() {
		const activeSectionIndex = getCurrentSectionIndex(this.session, this.currentTime);
		if (activeSectionIndex === END_OF_SESSION) {
			return END_OF_SESSION;
		}
		return activeSectionIndex;
	}
	get activeSection() {
		return this.session.sections[this.activeSectionIndex] || null;
	}
	getSectionRelativeTime(section = this.activeSection) {
		return this.currentTime - this.getSectionStartTime(section);
	}
	getSectionStartTime(targetSection) {
		if (!targetSection) {
			return null;
		}
		let tally = 0;
		for (let section of this.session.sections) {
			if (section === targetSection) {
				return tally;
			}
			tally += section.duration;
		}
		console.warn('TODO: getSectionStartTime ran to end');
		return tally;
	}
	getSectionProgress(section) {
		if (this.activeSectionIndex === END_OF_SESSION) {
			return 1;
		}
		const indexOfSection = this.session.getIndexOfSection(section);
		// If the section is in the past, completed
		if (indexOfSection < this.activeSectionIndex) {
			return 1;
		}
		// If the section is in the future, no progress
		if (indexOfSection > this.activeSectionIndex) {
			return 0;
		}
		return this.getSectionRelativeTime(section) / section.duration;
	}
	getSlotProgress(slot) {
		return (this.getSectionRelativeTime(slot.section) - slot.start) / slot.duration;
	}
	isActiveSection(section) {
		return this.activeSection && this.activeSection.id === section.id;
	}
}

function getFutureSlotStarts(slots, sectionRelativeTime) {
	// Get all slots with start > sectionRelativeTime
	return (
		slots
			.filter((slot) => slot.start > sectionRelativeTime)
			// Get start time of each
			.map((slot) => slot.start)
			// Sort, ascending
			.sort((a, b) => a - b)
	);
}

function getPastSlotStarts(slots, sectionRelativeTime) {
	// Get all slots with start < sectionRelativeTime
	return (
		slots
			// When playing we progress past the last edge before a click
			// has time to happen, and so we cannot go back. -5s Skew allows
			// to look for the previous boundary before the very previous.
			.filter((slot) => slot.start < sectionRelativeTime - 5)
			// Get start time of each
			.map((slot) => slot.start)
			// Sort, descending
			.sort((a, b) => b - a)
	);
}

const getCurrentSectionIndex = (session, time) => {
	let tally = 0;
	for (let index = 0; index < session.sections.length; index++) {
		tally += session.sections[index].duration;
		if (tally > time) {
			return index;
		}
	}
	return END_OF_SESSION;
};
