import React from 'react';
import range from 'lodash/range';

import { useLayout } from '~/src/utils/useLayout';
import { useZoom } from '~/src/utils/useZoom';
import { formatDigitalClock } from '~/src/utils/format';

import * as css from './SectionGrid.css';

const GRID_LINE_RESOLUTION_SECONDS = 30;
const GRID_LEGEND_RESOLUTION_SECONDS = 60;

export const SectionGrid = ({ section }) => {
	const zoom = useZoom();
	const layout = useLayout();
	const legendCount = Math.floor(section.duration / GRID_LEGEND_RESOLUTION_SECONDS + 1);
	return (
		<div className={css.SectionGrid}>
			{range(0, legendCount).map((index) => (
				<div
					key={index}
					style={layout.getGridLegendStyle(index, GRID_LEGEND_RESOLUTION_SECONDS)}
					className={css.SectionGrid__legend}
				>
					{formatDigitalClock(index * GRID_LEGEND_RESOLUTION_SECONDS)}
				</div>
			))}
			{range(0, section.duration / GRID_LINE_RESOLUTION_SECONDS + 1).map((index) => (
				<div
					key={index}
					style={{
						opacity: index % 2 ? 0.4 : 1,
						left: index * zoom.x * GRID_LINE_RESOLUTION_SECONDS,
					}}
					className={css.SectionGrid__line}
				></div>
			))}
		</div>
	);
};
