import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useUserForAuth } from '~/src/data/user';
import { Main, Content } from '~/src/lib/Layout';
import { Link, Button } from '~/src/lib/Buttons';
import { ErrorBox } from '~/src/lib/ErrorBox';
import { Label } from '~/src/lib/Form/Label';
import { TextInput } from '~/src/lib/Form/TextInput';

import * as css from './AuthPage.css';

export const LoginPage = ({ location }) => {
	const params = new URLSearchParams(location.search);
	const token = params.get('token');
	if (token) {
		return <Step2 token={token} />;
	}
	return <Step1 />;
};

// Step 1, submit email
const Step1 = () => {
	const { message, submitLogin, error, user } = useUserForAuth();
	const [isLoading, setIsLoading] = useState(false);
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const history = useHistory();

	// Step 1, user submits email
	const handleSubmit = async (event) => {
		event.preventDefault();
		setIsLoading(true);
		const isLoggingSuccessful = await submitLogin({ email, password });

		if (isLoggingSuccessful) {
			history.push('/home');
		}

		setIsLoading(false);
	};

	if (error && error.message.includes('User not approved')) {
		history.push('/waiting?login=true');
	}
	return (
		<Main className={css.AuthPage} headerProps={{ showLogin: false }}>
			<Content>
				{isLoading ? (
					'Loading...'
				) : message ? (
					<div className={css.AuthPage__message}>{message}</div>
				) : (
					<form onSubmit={handleSubmit}>
						<label className={css.AuthPage__input}>
							<Label>Email</Label>
							<TextInput
								type="email"
								name="email"
								onChange={(event) => setEmail(event.target.value)}
							/>
						</label>
						<label className={css.AuthPage__input}>
							<Label>Password</Label>
							<TextInput
								type="password"
								name="password"
								onChange={(event) => setPassword(event.target.value)}
							/>
						</label>
						<p className={css.AuthPage__forgotpassword}>
							<Link
								to="/password-recovery"
								track={['Go Signup', { where: 'Login page' }]}
							>
								Forgot password?
							</Link>
						</p>
						<div className={css.AuthPage__actions}>
							<Button type="submit" track={['Submit Login', { where: 'Login page' }]}>
								Login
							</Button>
							<p className={css.AuthPage__swapMessage}>
								or{' '}
								<Link to="/signup" track={['Go Signup', { where: 'Login page' }]}>
									sign up
								</Link>
							</p>
						</div>
						{error && (
							<div className={css.AuthPage__error}>
								<ErrorBox error={error} />
							</div>
						)}
					</form>
				)}
			</Content>
		</Main>
	);
};

// Step 2, user lands on page with token in url, submit it to the API
const Step2 = ({ token }) => {
	const { submitLoginToken, error } = useUserForAuth();
	const [isLoading, setIsLoading] = useState(false);
	const history = useHistory();
	// Step 2, visited url has a login token
	useEffect(() => {
		if (token && !isLoading) {
			setIsLoading(true);
			submitLoginToken({ token }).then((loggedIn) => {
				if (loggedIn) {
					history.push('/home');
				}
				setIsLoading(false);
			});
		}
	}, [token, history, submitLoginToken, isLoading]);
	return (
		<Main className={css.AuthPage} headerProps={{ showLogin: false }}>
			<Content>
				{isLoading ? 'Logging you in...' : null}
				{error && (
					<div className={css.AuthPage__error}>
						<ErrorBox error={error} />
					</div>
				)}
			</Content>
		</Main>
	);
};
