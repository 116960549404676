import React, { Fragment } from 'react';

import {
	useLayout,
	useLayoutSizeProvider,
	LayoutSizeContext,
} from '~/src/utils/useLayout';

import { RowLegends } from './RowLegends';

import * as css from './SessionTimeline.css';

export const SessionTimeline = (props) => {
	const [ref, size] = useLayoutSizeProvider();
	return (
		<LayoutSizeContext.Provider value={size}>
			<div ref={ref} className={css.Timeline} tabIndex="-1">
				<SessionTimelineInner {...props} />
			</div>
		</LayoutSizeContext.Provider>
	);
};

export const SessionTimelineInner = ({ session, children }) => {
	const layout = useLayout();
	return (
		<Fragment>
			<div className={css.Timeline__sidebar}>
				<div className={css.Timeline__sidebarHeader} style={layout.header}></div>
				<RowLegends session={session} />
			</div>
			<div className={css.Timeline__sections}>{children}</div>
		</Fragment>
	);
};
