import React from 'react';

import * as css from './Checkbox.css';

export const Checkbox = ({ children, ...props }) => {
	return (
		<label className={css.Checkbox}>
			<input type="checkbox" className={css.Checkbox__input} {...props} />
			<span className={css.Checkbox__label}>{children}</span>
		</label>
	);
};
