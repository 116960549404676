.RowLegends__rows {
	flex: 1;
	position: relative;
}
.RowLegends__row,
.RowLegends__bottomRow {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding-right: 30px;
	width: 100%;
	box-sizing: border-box;
	font-weight: var(--semibold);
	font-size: 20px;
	line-height: 25px;
}

@media (max-height: 700px) {
	.RowLegends__row {
		font-size: 16px;
		line-height: 20px;
	}
}
@media (max-height: 500px) {
	.RowLegends__row {
		font-size: 14px;
		line-height: 18px;
	}
}

.RowLegends__rowRemove {
	position: absolute;
	right: -10px;
	opacity: 0;
}
.RowLegends__row:hover .RowLegends__rowRemove {
	opacity: 1;
}

.RowLegends__bottomRow {
	padding-right: 30px;
}
.RowLegends__addRow {
	cursor: pointer;
}
