import React, { Fragment, useEffect } from 'react';
import { motion } from 'framer-motion';

import { Main, Loader } from '~/src/lib/Layout';
import { Link } from '~/src/lib/Buttons';

import { ActiveUsersList } from '../lib/ActiveUsersList';
import { PendingUsersList } from '../lib/PendingUsersList';
import { useActiveUsers, usePendingUsers } from '../data/user';

import * as css from './UsersListPage.css';

const HIDE_SHOW = { hide: { opacity: 0 }, show: { opacity: 1 } };

export const UsersListPage = ({ match }) => {
	const page = match.params.page;
	const { data, isLoading, mutate } =
		page === 'active' ? useActiveUsers() : usePendingUsers();

	if (isLoading) {
		<Loader>
			<div>Loading...</div>
		</Loader>;
	}
	return (
		<Main className={css.UsersListPage}>
			<motion.div
				className={css.UsersListPage__sessions}
				variants={HIDE_SHOW}
				initial="hide"
				animate="show"
				transition={{
					staggerChildren: 0.1,
				}}
			>
				<Link
					to="/home"
					variant="back"
					className={css.UsersListPage__goBack}
					track={['Go Back', { where: 'Manage Sessions page' }]}
				>
					Back
				</Link>
				{page == 'active' && (
					<Fragment>
						<h2 className={css.UsersListPage__title}>Active users</h2>
						<ActiveUsersList users={data.users} variant="full" />
					</Fragment>
				)}
				{page == 'pending' && (
					<Fragment>
						<h2 className={css.UsersListPage__title}>Pending users</h2>
						<PendingUsersList users={data.users} variant="full" />
					</Fragment>
				)}
			</motion.div>
		</Main>
	);
};
