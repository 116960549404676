.UsersListPage {
	--content-space-top: 5vh;
}
.UsersListPage__sessions {
	max-width: 535px;
	flex: 1;
}
.UsersListPage__goBack {
	margin-bottom: 5px;
}
.UsersListPage__title {
	font: var(--title-large);
	margin-bottom: 60px;
}
