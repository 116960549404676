.SessionClock {
	cursor: default;
	margin-top: 10px;
	padding-top: 10px;
}

.ParticipantClock {
	display: grid;
	grid-template-rows: auto 1fr;
	grid-template-columns: auto;
	align-items: center;
	grid-row-gap: 15px;
}
.ParticipantClock__state {
	font: var(--label-small);
	text-align: center;
}
.ParticipantClock__time {
	font: var(--participant-clock-font);
	display: flex;
	justify-content: center;
}

.OwnerClock {
	display: grid;
	grid-template-rows: auto auto;
	/* grid-template-columns: 30px 40px 30px; */
	grid-template-columns: auto auto auto;
	align-items: center;
	grid-column-gap: 15px;
	grid-row-gap: 15px;
}

.OwnerClock__button {
	border-radius: 50%;
	background-position: center center;
	background-repeat: no-repeat;
	text-indent: 999px;
	overflow: hidden;
	background-color: var(--off-white);
	position: relative;
	background-size: 20px auto;
}
.OwnerClock__button:hover {
}
.OwnerClock__button:after {
	content: '';
	display: inline-block;
}

.OwnerClock__button--play,
.OwnerClock__button--pause {
	grid-row: 1;
	grid-column: 2;
	width: 40px;
	height: 40px;
	border: 2px solid var(--black);
}
.OwnerClock__button--play {
	background-image: url('~/src/assets/icons/play.svg');
	background-position: 10px center;
}
.OwnerClock__button--pause {
	background-image: url('~/src/assets/icons/pause.svg');
}
.OwnerClock__button--previous,
.OwnerClock__button--next {
	width: 30px;
	height: 30px;
}
.OwnerClock__button--previous {
	grid-row: 1;
	grid-column: 1;
	background-image: url('~/src/assets/icons/previous.svg');
}
.OwnerClock__button--next {
	grid-row: 1;
	grid-column: 3;
	background-image: url('~/src/assets/icons/next.svg');
}

.OwnerClock__time {
	grid-row: 2;
	grid-column: 1 / 4;
	font: var(--clock-font);
	display: flex;
	justify-content: center;
}
