import { useState, useEffect } from 'react';

// Pass in an interval and the handler will only
// be updated on every nth frame, for example
// pass in 6 to render on every sixth frame.
export const useRaf = (isPlaying, interval = 1) => {
	const [count, setCount] = useState(0);
	useEffect(() => {
		if (!isPlaying) {
			return;
		}
		let isRunning = true;
		let frame = 0;
		const onFrame = () => {
			if (isRunning && isPlaying) {
				requestAnimationFrame(onFrame);
				frame++;
				if (frame % interval === 0) {
					setCount((count) => count + 1);
				}
			}
		};
		requestAnimationFrame(onFrame);
		return () => {
			isRunning = false;
		};
	}, [isPlaying, interval]);
	return count;
};
