import React from 'react';
import { Link } from '~/src/lib/Buttons';

import * as css from './ErrorBox.css';

export const ErrorBox = ({ error }) => {
	return (
		<div className={css.ErrorBox}>
			{error.message ? (
				<div className={css.ErrorBox__message}>{error.message} </div>
			) : null}
			{error.code === 401 ? (
				<div className={css.ErrorBox__actions}>
					<Link to="/logout" variant="forward-white">
						Sign out
					</Link>
				</div>
			) : null}
		</div>
	);
};
