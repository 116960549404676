.Slot {
	--handle-width: 10px;
	--handle-space: 10px;
	border-radius: var(--slot-radius);
	box-sizing: border-box;
	display: block;
	align-items: stretch;
	pointer-events: all;
	border: 1px solid transparent;
}

.Slot__duration {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	color: var(--black);
	font-weight: var(--semibold);
	font-size: 16px;
	line-height: 20px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	pointer-events: none;
	user-select: none;
	text-shadow: 0 0.5px 0.25px rgba(0, 0, 0, 0.2);
}

.Slot__text {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	color: var(--black);
	font-weight: var(--regular);
	font-size: 14px;
	line-height: 15px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	pointer-events: none;
	user-select: none;
	text-shadow: 0 0.5px 0.25px rgba(0, 0, 0, 0.2);
}
.Slot__countdown {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	color: var(--darkgray);
	font-weight: var(--bold);
	font-size: 20px;
	line-height: 20px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	pointer-events: none;
	user-select: none;
}

.Slot__handle {
	opacity: 0;
	transition: opacity 150ms ease;
	position: relative;
	user-select: none;
	position: absolute;
	top: 0;
	height: 100%;
}
.Slot:hover .Slot__handle {
	opacity: 1;
}
.Slot__handle--middle {
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	font: var(--semibold) 15px/1em var(--sans);
	text-align: center;
	cursor: move;
	grid-area: middle;
}
.Slot__handle--middle:hover {
	text-decoration: none;
}
.Slot__handle--start,
.Slot__handle--end {
	cursor: ew-resize;
	border-radius: var(--slot-radius);
}
.Slot__handle--start {
}
.Slot__handle--end {
}
.Slot__handle--start:before,
.Slot__handle--end:before {
	content: '';
	display: block;
	position: absolute;
	margin-top: var(--handle-space);
	margin-bottom: var(--handle-space);
	width: var(--handle-width);
	height: calc(100% - var(--handle-space) * 2);
	border-radius: calc(var(--handle-width) / 2);
	background: var(--white);
	border: 0.5px solid var(--midgray);
}
.Slot__handle--start:before {
	left: 10px;
}
.Slot__handle--end:before {
	right: 10px;
}
.Slot__progressWrap {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	border-radius: var(--slot-radius);
}
.Slot__progress {
	width: 100%;
	height: 100%;
}

.Slot__background {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	pointer-events: none;
	border-radius: var(--slot-radius);
	overflow: hidden;
	opacity: 0.2;
}
.Slot__backgroundStripe {
	position: absolute;
	width: 100%;
}
