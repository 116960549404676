import React, { useState } from 'react';
import { motion } from 'framer-motion';

import { useLayout } from '~/src/utils/useLayout';
import { getColorFromIndex } from '~/src/utils/color';
import { quantize } from '~/src/utils/quantize';
import { limit } from '~/src/utils/limit';

import * as css from './TimelineHoverSlot.css';

const QUANTIZE_WIDTH = 30;
const HOVER_OFFSET = QUANTIZE_WIDTH;

export const TimelineHoverSlot = ({ onClick, section, session }) => {
	const layout = useLayout();
	const [hover, setHover] = useState(null);
	const slotWidthInTime = quantize(
		Math.min(session.getLastSlotDuration(), section.duration / 2),
		QUANTIZE_WIDTH,
	);
	// Offset by hover offset so the block is placed with
	// some bulk under the cursor instead of at the edge
	const time = hover ? hover.time - HOVER_OFFSET : null;
	// Limit the time to fit within boundaries of section
	const limitedTime = limit(time, 0, section.duration - slotWidthInTime);
	const xPos = hover ? quantize(layout.getX(limitedTime), QUANTIZE_WIDTH) : 0;
	const yPos = hover ? layout.getRowY(hover.rowIndex) : 0;
	const style = {
		position: 'absolute',
		transform: `translate(${xPos}px, ${yPos}px)`,
		height: layout.getRowHeight(),
		width: layout.getX(slotWidthInTime),
		boxSizing: 'border-box',
		backgroundColor: hover ? getColorFromIndex(hover.rowIndex).normal : null,
		opacity: hover ? 0.3 : 0,
	};
	const updateHover = (event) => {
		const bounds = event.currentTarget.getBoundingClientRect();
		const x = Math.max(0, event.pageX - bounds.x - window.scrollX);
		const y = Math.max(0, event.pageY - bounds.y - window.scrollY);
		const rowIndex = layout.getRowIndexFromY(y);
		if (rowIndex === null) {
			setHover(null);
		} else {
			setHover({
				rowIndex: Math.min(rowIndex, session.rowCount - 1),
				time: quantize(layout.getTimeFromX(x), QUANTIZE_WIDTH),
			});
		}
	};
	const handlePointerMove = (event) => {
		updateHover(event);
	};
	const handleHoverStart = (event) => {
		updateHover(event);
	};
	const handleHoverEnd = () => {
		setHover(null);
	};
	const handleHoverSlotClick = () => {
		if (hover) {
			onClick(time, hover.rowIndex, slotWidthInTime);
		}
	};
	return (
		<div
			className={css.TimelineHoverSlot}
			onPointerMove={handlePointerMove}
			onPointerEnter={handleHoverStart}
			onPointerLeave={handleHoverEnd}
			onClick={handleHoverSlotClick}
		>
			<motion.div
				className={css.TimelineHoverSlot__slot}
				style={style}
				transition={{ type: 'tween', duration: 0.2, ease: 'easeOut' }}
			/>
		</div>
	);
};
