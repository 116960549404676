import React from 'react';
import { motion } from 'framer-motion';

import { track } from '~/src/utils/analytics';
import { useLayout } from '~/src/utils/useLayout';
import { ActionLink, RoundButton } from '~/src/lib/Buttons';
import { InlineEdit } from '~/src/lib/InlineEdit';
import { useSessionPrivileges } from '~/src/data/sessions';

import * as css from './RowLegends.css';

export const RowLegends = ({ session }) => {
	const privileges = useSessionPrivileges(session);
	const layout = useLayout();
	return (
		<div className={css.RowLegends__rows}>
			{session.rows.map((row, index) => (
				<motion.div
					key={row.id}
					className={css.RowLegends__row}
					animate={layout.getRowStyle(index)}
					transition={{ bounce: 0 }}
					initial={false}
				>
					<InlineEdit
						privileges={privileges}
						initialValue={row.text}
						onSubmit={(text) =>
							track('Edit Speaker text') && session.updateRow(row.id, { text })
						}
					/>
					{privileges.canEdit && session.rows.length > 1 && (
						<div className={css.RowLegends__rowRemove}>
							<RoundButton
								icon="remove"
								onClick={() => session.removeRow(row)}
								track={['Remove speaker']}
							/>
						</div>
					)}
				</motion.div>
			))}
			<div className={css.RowLegends__bottomRow} style={layout.getBottomRowStyle()}>
				{privileges.canEdit && (
					<ActionLink onClick={() => session.createRow()} track={['Add Speaker']}>
						Add speaker +
					</ActionLink>
				)}
			</div>
		</div>
	);
};
