import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useUser } from '~/src/data/user';
import { useSession, useSessionPrivileges } from '~/src/data/sessions';
import { useUI } from '~/src/utils/useUI';
import { track } from '~/src/utils/analytics';
import { InlineEdit } from '~/src/lib/InlineEdit';
import { Link, Button, RoundButton } from '~/src/lib/Buttons';
import * as css from './AppHeader.css';

export const AppHeader = ({ showLogin = true }) => {
	const ui = useUI();
	const session = useSession();
	const history = useHistory();
	const privileges = useSessionPrivileges(session);
	const { user } = useUser();
	const [screenWidth, setScreenWidth] = useState(window.innerWidth);
	const logoutBtnStyle = {
		background: '#1D1166',
		color: 'white',
		fontWeight: '400',
	};

	useEffect(() => {
		window.addEventListener('resize', () => {
			setScreenWidth(window.innerWidth);
		});
	}, [setScreenWidth]);

	const redirectToCalendar = useCallback(() => {
		if (history.location.pathname.includes('sessions/') && user) {
			window.location.href = 'https://beta.thisandthat.chat/home/calendar/daily';
		} else {
			history.push('/home');
		}
	}, [history, user]);

	if (!ui.appHeaderVisible) {
		return null;
	}

	return (
		<header className={css.AppHeader}>
			{session && user && (
				<div className={css.start}>
					<Fragment>
						<RoundButton
							icon="arrow-left"
							className={css.backHomeBtn}
							onClick={redirectToCalendar}
							track={['Maximize View']}
						/>
					</Fragment>
				</div>
			)}
			<Link
				variant="unstyled"
				className={css.logo}
				track={['Go Home', { where: 'App Header' }]}
				onClick={redirectToCalendar}
			>
				this&that
			</Link>
			{session && (
				<div className={css.middle}>
					<h1 className={css.sessionTitle}>
						<InlineEdit
							privileges={privileges}
							initialValue={session.title || 'Unnamed session'}
							inlineTextLength={inlineTextLength(screenWidth)}
							onSubmit={(title) =>
								track('Edit Session Title') && session.update({ title })
							}
						/>
					</h1>
					{privileges.canEdit && (
						<Fragment>
							<RoundButton
								icon="edit"
								to={`/sessions/${session.id}/details`}
								track={['Edit Details', { where: 'App Header' }]}
							/>
							<RoundButton
								icon="people"
								to={`/sessions/${session.id}/participants`}
								track={['Edit Participants', { where: 'App Header' }]}
							/>
						</Fragment>
					)}
				</div>
			)}
			<Fragment>
				{showLogin && (
					<div className={css.profile}>
						{!history.location.pathname.includes('sessions/') ? (
							<Fragment>
								{user ? (
									<Button
										style={logoutBtnStyle}
										to="/logout"
										track={['Log out', { where: 'App Header' }]}
									>
										Log out
									</Button>
								) : (
									<Button to="/login" track={['Log in', { where: 'App Header' }]}>
										Log in
									</Button>
								)}
							</Fragment>
						) : (
							session?.id &&
							user && (
								<Button
									style={logoutBtnStyle}
									to={`/sessions/${session.id}/participants`}
									track={['Edit Participants', { where: 'App Header' }]}
								>
									Share
								</Button>
							)
						)}
					</div>
				)}
			</Fragment>
		</header>
	);
};

const inlineTextLength = (width) => {
	return (width * 4) / 100;
};
