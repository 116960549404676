import React from 'react';

import * as css from './ProgressBar.css';

export const ProgressBar = ({ progress = 0 }) => (
	<div className={css.ProgressBar}>
		<div
			style={{ transform: `translateX(${progress * 100}%)` }}
			className={css.ProgressBar__progress}
		/>
	</div>
);
