import React from 'react';

import { Main } from '~/src/lib/Layout';
import { Button } from '~/src/lib/Buttons';

const resetStorage = () => {
	localStorage.clear();
	alert('The storage has been reset, reloading the page...');
	window.location = '/home';
};

export const InternalPage = () => {
	return (
		<Main>
			<Button onClick={resetStorage} variant="danger">
				Reset storage
			</Button>
		</Main>
	);
};
