.InlineEdit {
	font: inherit;
	margin: 0px 15px;
}
.InlineEdit:focus-within {
	background: var(--gray);
	border-radius: 5px;
	padding: 0.15em 0.25em;
	outline: none;
}
.InlineEdit__field:focus {
	outline: none;
}

@media (prefers-color-scheme: dark) {
	.InlineEdit:focus-within {
		background: var(--darkgray);
	}
}
