import React, { Fragment } from 'react';
import ReactInputMask from 'react-input-mask';

import * as css from './TextInput.css';

export const TextInput = React.forwardRef((props, ref) => {
	let { mask, error } = props;
	return mask ? (
		<Fragment>
			{error && <small className={css.error}>{error.message}</small>}
			<ReactInputMask
				className={css.TextInput}
				{...props}
				mask={format(mask).mask}
				formatChars={format(mask).formatChars}
				ref={ref}
			/>
		</Fragment>
	) : (
		<input className={css.TextInput} {...props} ref={ref} />
	);
});

const format = (mask) => {
	switch (mask) {
		case 'time':
			return {
				mask: '12:12',
				formatChars: {
					1: '[0-5]',
					2: '[0-9]',
				},
			};
		default:
			return;
	}
};
