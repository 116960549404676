.ParticipantsEditor {
	min-height: 50vh;
}
.ParticipantsEditor__header {
}

.ParticipantsEditor__sectionTitle {
	font: var(--title-small);
	margin-bottom: 20px;
	margin-top: 20px;
}
.ParticipantsEditor__header {
}

.Participant {
	display: grid;
	grid-template-columns: auto 1fr auto;
	grid-template-rows: 1fr 1fr;
	grid-template-areas:
		'thumb title actions'
		'thumb email actions';
	align-items: center;
	grid-column-gap: 10px;
}
.Participant + .Participant {
	margin-top: 20px;
}
.Participant__thumbnail {
	grid-area: thumb;
	width: 45px;
	height: 45px;
	box-sizing: border-box;
	background: var(--off-white);
	border: 1px solid var(--border-color);
	border-radius: 50%;
	overflow: hidden;
	grid-area: thumb;
}
.Participant__title {
	grid-area: title;
	font: var(--label-medium);
	display: flex;
	grid-column-gap: 5px;
}
.Participant__name {
	font: var(--label-medium);
}
.Participant__email {
	grid-area: email;
	font: var(--label-small);
}
.Participant__isOwner {
	height: 20px;
	line-height: 20px;
	display: inline-block;
	padding: 0 8px;
	margin: -1px 0 -1px;
	cursor: default;
	border-radius: 9px;
	font-size: 13px;
	letter-spacing: 0.02em;
	vertical-align: bottom;
	font-weight: var(--semibold);
	border: 1px solid var(--border-color);
}
.Participant__actions {
	grid-area: actions;
}
.Participant__actions > * + * {
	margin-left: 10px;
}
