const PRESETS = [
	{
		normal: '#FBF3B0',
		darker: '#F9EF95',
		transparent: 'rgb(251, 243, 176, 0)',
	},
	{
		normal: '#FFB7B2',
		darker: '#FFA099',
		transparent: 'rgb(255, 183, 178, 0)',
	},
	{
		normal: '#FFDAC1',
		darker: '#FFC8A3',
		transparent: 'rgb(255, 218, 193, 0)',
	},
	{
		normal: '#E2F0CB',
		darker: '#D5E9B4',
		transparent: 'rgb(226, 240, 203, 0)',
	},
	{
		normal: '#B5EAD7',
		darker: '#A0E4CB',
		transparent: 'rgb(181, 234, 215, 0)',
	},
];

const ALL = {
	stroke: '#D9D9D9',
	normal: '#FFFFFF',
	darker: '#F4F4F4',
	transparent: 'rgb(252, 252, 252, 0)',
};

export const getColorFromIndex = (index) => {
	if (index === 'all') {
		return ALL;
	}
	if (!Number.isFinite(index) || index < 0) {
		// TODO: This happens sometimes, and I don't know why
		return PRESETS[0];
	}
	return PRESETS[index % PRESETS.length];
};
