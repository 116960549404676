import React from 'react';

import { IconButton } from '~/src/lib/Buttons';
import { useUI } from '~/src/utils/useUI';

import { InfoBox } from './InfoBox';
import { SessionClock } from './SessionClock';
import { JumpTo } from './JumpTo';

import * as css from './SessionFooter.css';

export const SessionFooter = ({ session }) => {
	const ui = useUI();
	return (
		<footer className={css.Footer}>
			<div className={css.Footer__infoBox}>
				<InfoBox session={session} />
			</div>
			<div className={css.Footer__playbackControl}>
				<SessionClock session={session} />
			</div>
			<div className={css.Footer__jumpTo}>
				<JumpTo session={session} />
			</div>
			<div className={css.Footer__layoutControl}>
				{ui.appHeaderVisible ? (
					<IconButton
						className={css.Footer__maximizeButton}
						onClick={ui.toggleHeader}
						track={['Maximize View']}
					/>
				) : (
					<IconButton
						className={css.Footer__minimizeButton}
						onClick={ui.toggleHeader}
						track={['Minimize View']}
					/>
				)}
			</div>
		</footer>
	);
};
