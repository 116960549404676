.DigitalClock {
	white-space: nowrap;
}
.DigitalClock__remaining:after {
	content: '/';
	display: inline-block;
	margin: 0 0.25em;
}
.DigitalClock__total {
}
.DigitalClock__remaining::selection,
.DigitalClock__total::selection {
	background: transparent;
}
