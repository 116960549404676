import React from 'react';
import classnames from 'classnames';
import { motion } from 'framer-motion';

import { ActionLink, Link } from '~/src/lib/Buttons';
import { put } from '../data/api';

import { useToasts } from 'react-toast-notifications';

import * as css from './ActiveUsersList.css';
import { useActiveUsers } from '../data/user';

const TOAST_DURATION = 3000;

export const ActiveUsersList = ({ users, variant }) => {
	const { refresh } = useActiveUsers();
	const { addToast } = useToasts();

	const handleArchive = async (user) => {
		put(`/users/${user.id}/archive`)
			.then(() => {
				refresh();
				addToast(`${user.name} was successfully archived.`, {
					appearance: 'success',
					autoDismiss: true,
					autoDismissTimeout: TOAST_DURATION,
				});
			})
			.catch((error) =>
				addToast(error.message, {
					appearance: 'error',
					autoDismiss: true,
					autoDismissTimeout: TOAST_DURATION,
				}),
			);
	};
	const handleMoveToWaitingApproval = async (user) => {
		put(`/users/${user.id}/pending`)
			.then(() => {
				refresh();
				addToast(`Successfully moved.`, {
					appearance: 'success',
					autoDismiss: true,
					autoDismissTimeout: TOAST_DURATION,
				});
			})
			.catch((error) =>
				addToast(error.message, {
					appearance: 'error',
					autoDismiss: true,
					autoDismissTimeout: TOAST_DURATION,
				}),
			);
	};
	const handleSetAdmin = async (user) => {
		put(`/users/${user.id}/set-admin`)
			.then(() => {
				refresh();
				addToast(`Successfully set as admin`, {
					appearance: 'success',
					autoDismiss: true,
					autoDismissTimeout: TOAST_DURATION,
				});
			})
			.catch((error) =>
				addToast(error.message, {
					appearance: 'error',
					autoDismiss: true,
					autoDismissTimeout: TOAST_DURATION,
				}),
			);
	};
	const handleRevokeAdmin = async (user) => {
		put(`/users/${user.id}/revoke-admin`)
			.then(() => {
				refresh();
				addToast(`Successfully revoked.`, {
					appearance: 'success',
					autoDismiss: true,
					autoDismissTimeout: TOAST_DURATION,
				});
			})
			.catch((error) =>
				addToast(error.message, {
					appearance: 'error',
					autoDismiss: true,
					autoDismissTimeout: TOAST_DURATION,
				}),
			);
	};

	return (
		<div className={classnames(css.ActiveUsersList, css[`ActiveUsersList--${variant}`])}>
			{(users && users.length) &&
				users.map((user) => (
					<ActiveUsersListItem
						key={user.id}
						user={user}
						handleArchive={handleArchive}
						handleMoveToWaitingApproval={handleMoveToWaitingApproval}
						handleSetAdmin={handleSetAdmin}
						handleRevokeAdmin={handleRevokeAdmin}
						variant={variant}
					/>
				))}
			{!users && <h4>No active users</h4>}
		</div>
	);
};

export const ActiveUsersListItem = ({
	user,
	handleArchive,
	handleMoveToWaitingApproval,
	handleSetAdmin,
	handleRevokeAdmin,
	variant = 'preview',
}) => {
	const isFull = variant === 'full';
	const LinkComponent = isFull ? 'div' : Link;
	return (
		<motion.article
			className={classnames(
				css.ActiveUsersListItem,
				css[`ActiveUsersListItem--${variant}`],
			)}
		>
			<LinkComponent
				className={css.ActiveUsersListItem__link}
				track={isFull ? null : ['View Active User', { where: 'Active User List' }]}
			>
				<h3 className={css.ActiveUsersListItem__name}>{user.name}</h3>
				<p className={css.ActiveUsersListItem__email}>{user.email}</p>
				<Actions
					user={user}
					handleArchive={handleArchive}
					handleMoveToWaitingApproval={handleMoveToWaitingApproval}
					handleSetAdmin={handleSetAdmin}
					handleRevokeAdmin={handleRevokeAdmin}
				>
					Actions
				</Actions>
			</LinkComponent>
		</motion.article>
	);
};

const Actions = ({
	user,
	handleArchive,
	handleMoveToWaitingApproval,
	handleSetAdmin,
	handleRevokeAdmin,
}) => {
	const handleArchiveClick = () => {
		handleArchive(user);
	};
	const handleMoveToWaitingApprovalClick = () => {
		handleMoveToWaitingApproval(user);
	};
	const handleSetAdminClick = () => {
		handleSetAdmin(user);
	};
	const handleRevokeAdminClick = () => {
		handleRevokeAdmin(user);
	};
	return (
		<div className={css.ActiveUsersListItem__actions}>
			<ActionLink
				className={css.ActiveUsersListItem__actionItem}
				onClick={handleMoveToWaitingApprovalClick}
				track={['Move to waiting approval', { where: 'Full Active List' }]}
			>
				Move to waiting approval
			</ActionLink>
			<ActionLink
				className={css.ActiveUsersListItem__actionItem}
				onClick={handleArchiveClick}
				track={['Archive user', { where: 'Full Active List' }]}
			>
				Archive
			</ActionLink>
			{user.role !== 'ADMIN' && (
				<ActionLink
					className={css.ActiveUsersListItem__actionItem}
					onClick={handleSetAdminClick}
					track={['Set User admin', { where: 'Full Active List' }]}
				>
					Set admin
				</ActionLink>
			)}
			{user.role === 'ADMIN' && (
				<ActionLink
					className={css.ActiveUsersListItem__actionItem}
					onClick={handleRevokeAdminClick}
					track={['Revoke User admin', { where: 'Full Active List' }]}
				>
					Revoke admin
				</ActionLink>
			)}
		</div>
	);
};
