import mixpanel from 'mixpanel-browser';

const canInit = !!process.env.MIXPANEL_TOKEN;
export function init() {
	if (!canInit) {
		console.warn('Cannot initialize mixpanel, token not available');
		return;
	}
	mixpanel.init(process.env.MIXPANEL_TOKEN);
	mixpanel.track('Startup');
}

export function track(...args) {
	if (!canInit) {
		console.info('Track', ...args);
	} else {
		mixpanel.track(...args);
	}
	// Returning true to allow && chaining
	return true;
}
