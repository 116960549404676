import React, { Fragment, useCallback, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { OverlayCard } from '~/src/lib/OverlayCard';
import { Button, Link } from '~/src/lib/Buttons';
import { Player } from '@lottiefiles/react-lottie-player';
import { Redirect } from 'react-router-dom';
import { useUser } from '~/src/data/user';
import { RoundButton } from '../lib/Buttons';

import * as css from './LandingPage.css';

const HIDE_SHOW = { hide: { opacity: 0 }, show: { opacity: 1 } };
const PRIVACY_POLICY =
	'https://unequaled-guava-f9f.notion.site/Privacy-Policy-384e11cc8351429286b327e32a20ea41';

export const LandingPage = () => {
	const { user } = useUser();

	if (user) {
		return <Redirect to="/home" />;
	}
	return <LandingPageContent />;
};

const LandingPageContent = () => {
	const [openTerms, setOpenTerms] = useState(false);

	const handleClickOpenTerm = useCallback(() => {
		setOpenTerms((prevState) => !prevState);
	}, []);

	return (
		<Fragment>
			<div className={css.LandingPage}>
				<div className={css.LandingPage__container}>
					<motion.div
						className={css.LandingPage__header}
						variants={HIDE_SHOW}
						initial="hide"
						animate="show"
						transition={{
							staggerChildren: 0.1,
						}}
					>
						<motion.h2 className={css.LandingPage__logo}>this&that</motion.h2>
						<motion.h2 className={css.LandingPage__login}>
							<Link variant="unstyled" to="/login">
								Log in
							</Link>
						</motion.h2>
					</motion.div>

					<motion.div
						variants={HIDE_SHOW}
						initial="hide"
						animate="show"
						transition={{
							staggerChildren: 0.1,
						}}
						className={css.LandingPage__section}
					>
						<div className={css.LandingPage__left_content}>
							<h1> Welcome to our alpha tool.</h1>
							<div className={css.LandingPage__description}>
								<h2>
									<strong>this&that</strong> is a Conversation Laboratory <br />
									dedicated to building tools that
									<br />
									power <strong>equitable and inclusive</strong>
									<br /> meetings and conversations.
								</h2>
							</div>
							<div className={css.LandingPage__buttons}>
								<Button
									to="/signup"
									shape="square"
									className={css.LandingPage__buttons_get_started}
								>
									Get started
								</Button>
							</div>
						</div>
						<div className={css.LandingPage__right_content}>
							<Player
								src="https://assets3.lottiefiles.com/packages/lf20_wsxi9vjd.json"
								background="transparent"
								speed="0.2"
								style={{
									width: '80%',
									height: '90%',
									position: 'absolute',
									top: '10px',
									left: '40%',
								}}
								loop
								autoplay
							></Player>
						</div>
					</motion.div>

					<motion.div
						initial="hide"
						animate="show"
						transition={{
							staggerChildren: 0.1,
						}}
						className={css.LandingPage__footer}
						variants={HIDE_SHOW}
					>
						<motion.h2>
							<a href={PRIVACY_POLICY} target="_blank" rel="noreferrer">
								Privacy Policy
							</a>
						</motion.h2>
						<motion.h2 onClick={handleClickOpenTerm}>Terms and Conditions</motion.h2>
					</motion.div>
				</div>
			</div>
			{openTerms ? (
				<LandingPageDialog
					title="Hey!"
					description="
					We're working on publishing our Terms & Conditions. In the meantime,
					please check out our Privacy Policy."
					handleClickOpen={handleClickOpenTerm}
				/>
			) : null}
		</Fragment>
	);
};

const LandingPageDialog = ({ title, description, handleClickOpen }) => {
	return (
		<AnimatePresence>
			<OverlayCard onBackgroundClick={handleClickOpen}>
				<motion.div className={css.LandingPage__dialog}>
					<div onClick={handleClickOpen}></div>
					<RoundButton
						onClick={handleClickOpen}
						icon="remove"
						className={css.LandingPage__dialog_close}
					></RoundButton>
					<div className={css.LandingPage__dialog_head}>{title && <h1>{title}</h1>}</div>
					<div className={css.LandingPage__dialog_content}>
						{description && <p>{description}</p>}
					</div>
				</motion.div>
			</OverlayCard>
		</AnimatePresence>
	);
};
