import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useUserForAuth } from '~/src/data/user';
import { Main, Content } from '~/src/lib/Layout';
import { Link, Button } from '~/src/lib/Buttons';
import { ErrorBox } from '~/src/lib/ErrorBox';
import { Label } from '~/src/lib/Form/Label';
import { TextInput } from '~/src/lib/Form/TextInput';

import * as css from './AuthPage.css';

export const ForgotPasswordPage = ({ location }) => {
	return <Step1 />;
};

// Step 1, submit email
const Step1 = () => {
	const { message, submitPasswordRecovery, error, user } = useUserForAuth();
	const [isLoading, setIsLoading] = useState(false);
	const [email, setEmail] = useState('');
	const history = useHistory();

	// Step 1, user submits email
	const handleSubmit = async (event) => {
		event.preventDefault();
		setIsLoading(true);
		await submitPasswordRecovery({ email });
		setIsLoading(false);
	};

	return (
		<Main className={css.AuthPage} headerProps={{ showLogin: false }}>
			<Content>
				{user ? (
					<div className={css.AuthPage__info}>
						<p>Signed in as {user.email}</p>
						<Button to="/home" track={['Go Home', { where: 'Login page' }]}>
							Home
						</Button>
					</div>
				) : isLoading ? (
					'Loading...'
				) : message ? (
					<div className={css.AuthPage__message}>{message}</div>
				) : (
					<form onSubmit={handleSubmit}>
						<label className={css.AuthPage__input}>
							<Label>Email</Label>
							<TextInput
								type="email"
								name="email"
								onChange={(event) => setEmail(event.target.value)}
							/>
						</label>
						<div className={css.AuthPage__actions}>
							<Button type="submit" track={['Submit Login', { where: 'Login page' }]}>
								Next
							</Button>
						</div>
						{error && (
							<div className={css.AuthPage__error}>
								<ErrorBox error={error} />
							</div>
						)}
					</form>
				)}
			</Content>
		</Main>
	);
};
