import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { post } from '~/src/data/api';
import { SessionContext, useSessionLoader } from '~/src/data/sessions';
import { useUser } from '~/src/data/user';
import { Button } from '~/src/lib/Buttons';
import { Loader, Main } from '~/src/lib/Layout';
import { Session } from '~/src/lib/Session';

import { USER_TOKEN_KEY } from '../data/api';
import * as css from './SessionPage.css';

export const SessionPage = ({ match }) => {
	const sessionId = match.params.session;
	const page = match.params.page;
	const itemId = match.params.item;

	if (window.location.search) {
		const token = window.location.search.split('=')[1];
		localStorage.setItem(USER_TOKEN_KEY, token);
	}

	const { user, isLoading, error } = useUser();
	if (error) {
		return (
			<Loader>
				<div>{error.message}</div>
				<Button to="/home" track={['Go Home', { where: 'Session Error page' }]}>
					Home
				</Button>
			</Loader>
		);
	}
	if (isLoading) {
		return (
			<Loader>
				<div>Loading...</div>
			</Loader>
		);
	}
	if (page === 'invited') {
		if (user) {
			return (
				<Loader showHeader>
					<div>You have been invited to join this meeting</div>
					<Button
						to={`/sessions/${sessionId}`}
						track={['Join Session', { where: 'Session Invite page' }]}
					>
						Join meeting
					</Button>
				</Loader>
			);
		} else {
			return (
				<Loader showHeader>
					<div>You have been invited to join this meeting</div>
					<Button to="/signup" track={['Go Signup', { where: 'Session Invite page' }]}>
						Signup
					</Button>
				</Loader>
			);
		}
	}
	if (page === 'public') {
		return (
			<Loader showHeader>
				<div>You have been invited to join this public meeting</div>
				<Button
					to={`/sessions/${sessionId}`}
					track={['Join Session', { where: 'Public Session page' }]}
				>
					Join meeting
				</Button>
			</Loader>
		);
	}
	if (sessionId === 'new') {
		if (user) {
			return <NewSessionPage />;
		} else {
			return (
				<Loader showHeader>
					<div>You must sign up to create a meeting</div>
					<Button to="/signup" track={['Go Signup', { where: 'New Session page' }]}>
						Signup
					</Button>
				</Loader>
			);
		}
	}
	return <ExistingSessionPage sessionId={sessionId} page={page} itemId={itemId} />;
};

const NewSessionPage = () => {
	const history = useHistory();
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);
	useEffect(() => {
		setIsLoading(true);
		post('/sessions')
			.then((data) => {
				setIsLoading(false);
				history.replace(`/sessions/${data.newSessionId}`);
			})
			.catch(setError);
		// TODO: Post to API to create a new session
	}, [history]);
	if (error) {
		return error.message;
	}
	if (isLoading) {
		return 'Creating new meeting...';
	}
	return null;
};

const ExistingSessionPage = ({ sessionId, page, itemId }) => {
	const { isLoading, session, error } = useSessionLoader({ id: sessionId });
	if (error) {
		if (error.code === 401) {
			return (
				<Loader showHeader>
					<div>You do not have access to this meeting</div>
					<Button to="/home" track={['Go Home', { where: 'Session Error page' }]}>
						Home
					</Button>
				</Loader>
			);
		}
		return (
			<Loader>
				<div>
					{error.message} {error.code ? `(${error.code})` : ''}
				</div>
				<Button to="/home" track={['Go Home', { where: 'Meeting Error page' }]}>
					Home
				</Button>
			</Loader>
		);
	}
	if (isLoading) {
		return (
			<Loader>
				<div>Loading...</div>
			</Loader>
		);
	}
	return (
		<SessionContext.Provider value={session}>
			<Main className={css.SessionPage}>
				<Session session={session} page={page} itemId={itemId} />
			</Main>
		</SessionContext.Provider>
	);
};
