.Checkbox {
	display: flex;
	align-items: center;
}
.Checkbox__input {
	width: 20px;
	height: 20px;
}
.Checkbox__label {
	margin-left: 10px;
}
