export const formatDigitalClock = (total, divider = ':') => {
	return `${pad(totalAsMinutes(total), '0')}${divider}${pad(totalAsSeconds(total), '0')}`;
};

const pad = (number, symbol) => `${number < 10 ? symbol : ''}${number}`;

export const totalAsSeconds = (total) => {
	return Math.floor(total % 60);
};
export const totalAsMinutes = (total) => {
	return Math.floor(total / 60);
};

export const formatSeconds = (total) => {
	return Math.round(total % 60);
};
export const formatMinutes = (total) => {
	return Math.round(total / 60);
};

export const durationToMinutes = (duration) => {
	// TODO: Output halves?
	return Math.round(duration / 60);
};

export const validateDurationFormat = (duration) => {
	return new RegExp(/^[0-5]?\d:[0-5]\d$/).test(duration);
};

export const getInitials = (name) => {
	let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');

	let initials = [...name.matchAll(rgx)] || [];

	return ((initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')).toUpperCase();
};

export const colors = ['#4E37A6', '#00ACD3', '#A0CF1A', '#FA97F6'];
