.Timeline {
	display: flex;
	flex: 1;
	outline: none;
	height: 100%;
	overflow-y: hidden;
	overflow-x: auto;
	margin-bottom: var(--footer-height);
}

.Timeline__sidebar {
	position: sticky;
	left: 0;
	flex: 0 0 var(--session-sidebar-width);
	z-index: 10;
	background: var(--background-color);
	display: flex;
	flex-direction: column;
}
.Timeline__sidebarHeader {
}

.Timeline__sections {
	display: flex;
	position: relative;
	padding-right: 100px;
}
