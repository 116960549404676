.WaitingApproval {
	--content-space-top: 15vh;
	text-align: center;
}

.WaitingApproval__title {
	font: var(--title-medium);
	margin-bottom: 60px;
}

.WaitingApproval__info {
	margin-bottom: 60px;
}

.WaitingApproval__title {
    font-family: 'Work Sans', sans-serif;
    font-weight: 700;
    color: #020776;
}

.WaitingApproval__info p {
    font-family: 'Work Sans', sans-serif;
    font-weight: 700;
    color: #020776;
}

.WaitingApproval__link{}