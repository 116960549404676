.Section {
	position: relative;
	display: flex;
	flex-direction: column;
	flex: 0 0 auto;
	position: relative;
	padding-left: 30px;
	padding-right: 30px;
}

.Section__main {
	position: relative;
}

.Section__slots {
	width: 100%;
	height: 100%;
	pointer-events: none;
}
.Section__header {
}
.Section__title {
}

.BlankSection {
	display: flex;
	flex-direction: column;
	flex: 0 0 auto;
	color: var(--action-link-color);
	display: flex;
	align-items: stretch;
	margin: auto 50px;
	max-height: 60%;
}
.BlankSection__main {
	box-shadow: var(--shadow-clickable);
	border: 1px solid var(--border-color);
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}
.BlankSection__addIcon {
	width: 54px;
	height: 54px;
	background-size: 54px auto;
	background-repeat: no-repeat;
	background-position: center center;
	background-image: url('~/src/assets/icons/large-plus.svg');
}
.BlankSection__prompt {
	font-size: 20px;
	margin-top: 30px;
	font-weight: var(--semibold);
}
