.TextInput {
	position: relative;
	--line-height: 1.6em;
	background: transparent;
	font-size: inherit;
	line-height: inherit;
	resize: none;
	font-family: inherit;
	font-size: inherit;
	line-height: var(--line-height);
	flex: 1;
	padding: 0;
	box-sizing: border-box;
	white-space: pre-line;
	display: block;
	tab-size: 4;
	width: 100%;
	padding: 15px;
	border: 1px solid var(--border-color);
	border-radius: 5px;
}
.TextInput:not(:last-child) {
	margin-bottom: 30px;
}
.TextInput:focus {
	outline: none;
}

.error {
	color: red;
	margin-left: 10px;
}
